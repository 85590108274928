'use strict'

import React from 'react'
import colors from '../../lib/colors'
import FSText from '../../components/FSText'
import TextInput from '../../components/TextInput'
import View from '../../components/View'
import humanize from 'humanize-string'

export default class MultiEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {values: Object.assign({}, props.value)}
  }

  stylesForField (field) {
    var styles = {
      flex: 0
    }
    if (field.height !== undefined) styles.height = field.height
    if (field.flex !== undefined) styles.flex = field.flex
    return styles
  }

  handleChange (field,e) {
    var value = e.target.value
    var newState = Object.assign({},this.state.values)
    newState[field.attribute] = value
    this.setState({values: newState})

    this.props.onChange(newState, true)
  }

  renderField = (field, index) => {
    switch(field.type) {
      case 'string':
        return [
          (
            <View style={[styles.textInputField, this.stylesForField(field)]} key={`field-${index}`}>
              <TextInput
                ref="input"
                autoCapitalize={this.props.autoCapitalize || 'sentences'}
                placeholder={field.placeholder}
                autoCorrect={field.autoCorrect || true}
                multiline={field.multiline || false}
                autoFocus={index===0}
                style={styles.textInput}
                value={this.state.values[field.attribute]}
                onChange={(e) => this.handleChange(field,e)}
              />
            </View>
          ),
          this.renderValidationInfo(field)
        ]
        break;

      default:
        throw new Error(`MultiEditor: unknown input type ${field.type}`)
    }
  };

  renderValidationInfo = (field) => {
    if (this.props.errors) {
      var attr = field.attribute
      var fieldErrors = this.props.errors[attr] || []
      return fieldErrors.map((error,i) => (
        <FSText key={`error-${attr}-${i}`} style={styles.validationError}>
          {`${humanize(attr)} ${error}`}
        </FSText>
      ))
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.container}>
          { this.props.fields.map(this.renderField) }
        </View>
      </View>
    )
  }
}

var styles = {
  container: {
    flex: 1,
    flexDirection: 'column', display: 'flex',
    marginTop: 20,
  },
  textInputField: {
    borderBottomWidth: 1,
    borderBottomColor: colors.lightMediumGray,
    paddingLeft: 15,
    paddingRight: 15,
  },
  textInput: {
    flex: 1,
    fontSize: 16,
  },
  validationError: {
    padding: '5px 15px',
    backgroundColor: colors.red,
    color: colors.white,
    marginBottom: 1,
  }
}
