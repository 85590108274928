'use strict'

import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux'
import colors from '../../../lib/colors'

import Color from 'color'

import FSText from '../../../components/FSText'
import FSTouchable from '../../../components/FSTouchable'
import FSIcon from '../../../components/FSIcon'

class NextButton extends React.Component {

  componentDidMount() {
  }

  render() {
    var text = this.props.text || 'NEXT';
    var active = this.props.active || false;

    return (
      <FSTouchable
        ref='nextButton'
        onPress={this.props.onPress} style={Object.assign({},
        styles.button,
        active ? styles.buttonActive : {},
        active && this.props.profileColorDark ? {backgroundColor: this.props.profileColorDark} : {},
        this.props.style ? this.props.style : {}
      )}>
        <FSText family='condensed' style={active ? styles.textActive : styles.text}>{text}</FSText>
      </FSTouchable>
    )
  }

}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.signup, {});
}

export default connect(mapStateToProps)(NextButton)

var styles = {
  button: {
    alignItems: 'center',
    padding: 20,
    backgroundColor: colors.darkMediumAndSortaBlueishGray,
    boxSizing: 'border-box',
    width: '100%',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  buttonActive: {
    backgroundColor: colors.darkMediumAndSortaBlueishGray,
  },
  text: {
    color: colors.lightMediumGray,
    fontSize: 16,
    fontWeight: '500',
  },
  textActive: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '500',
  },
}
