import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'
import View from './View'

class FSScrollView extends Component {

  componentDidMount() {
  }

  render() {
    return (
      <View
          className={"FSScrollView " + this.props.className}
          style={[styles.container, this.props.contentContainerStyle, this.props.style]}
          >
        {this.props.children}
      </View>
    );
  }
}

export default FSScrollView;

var styles = {
  container: {
    position: 'relative',
  }
}
