'use strict'

// Given a list of elements 'list', this will move anything
// in a second array 'toFront' to the beginning
export default function moveElementsToFront( toFront, list, includeMissing ) {

  // Not a very efficient method for this, but not expected to
  // be performed on particularly large lists:
  var first = toFront
  if( ! includeMissing ) first = first.filter(x => (list.indexOf(x) !== -1) )
  var last = list.filter(x => (toFront.indexOf(x) === -1) )

  return first.concat(last)
}
