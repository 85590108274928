'use strict'

import React, { Component, PropTypes } from "react";
import FS from '../../components/FS';
import UserAvatar from '../../components/UserAvatar'
import DebouncedTextInput from '../../components/DebouncedTextInput'
import colors from '../../lib/colors';
import { truncate } from '../../lib/utils/text';
import View from '../../components/View'
import TextInput from '../../components/View'

import trim from 'trim';
import {
  editAttribute,
  cancelEditingAttribute,
  doneEditingAttribute,
  updateAttribute,
} from '../actions';

const BLANK_REGEX = /^\s*$/

class BasicPostDetails extends React.Component {
  get hasTitle () {
    return !!this.props.post.title &&
      this.props.post.title.length > 0 &&
      !BLANK_REGEX.test(this.props.post.title)
  }

  editAttr = (attr) => {
    this.props.dispatch(editAttribute(attr));
  };

  render() {
    var user = dataStore.get('user', this.props.currentUserId);
    return (
      <View style={styles.contentContainer} className="BasicPostDetails">
        <View style={styles.titleDescWrapWrap}>
          <UserAvatar user={user} size={40} style={styles.avatar}/>

          <View style={styles.titleDescWrap}>
            <FS.Touchable onPress={() => this.editAttr('title')}>
              <FS.Text style={Object.assign({}, styles.title, ! this.hasTitle && styles.titlePlaceholder)}>
                {this.hasTitle ? this.props.post.title : 'Type a headline'}
              </FS.Text>
            </FS.Touchable>

            {this.props.post.description || (this.props.post.title && this.props.post.title.length > 0) ? (
              <FS.Touchable onPress={() => this.editAttr('description')}>
                <FS.Text style={styles.description}>
                  {this.props.post.description ?
                    truncate(this.props.post.description, 150) :
                    '+ Add more details'}
                </FS.Text>
              </FS.Touchable>
            ) : (<View/>)}

          </View>

          {!this.props.post.link_url && (
            <FS.Touchable
              onPress={() => this.editAttr('link_url')}
              style={styles.linkIconWrap}>
                <FS.Icon
                  name="link"
                  size={16}
                  style={styles.linkIcon}>
                </FS.Icon>
            </FS.Touchable>
          )}
        </View>
      </View>
    )
  }
}

export default BasicPostDetails

var styles = {
  container: {
    flex: 0,
  },
  contentContainer: {
    alignItems: 'stretch',
    backgroundColor: colors.white,
    padding: 18,
    borderBottomWidth: 1,
    borderColor: colors.lightGray,
  },
  avatar: {
    marginRight: 10,
  },
  titleDescWrapWrap: {
    flex: 1,
    display: "flex", flexDirection: "row",
  },
  titleDescWrap: {
    flex: 1,
    display: "flex", flexDirection: "column",
    justifyContent: 'center',
    textAlign: 'left',
  },
  title: {
    fontSize: 14,
    color: colors.darkGray,
    fontWeight: '900',
    paddingLeft: 5, paddingRight: 5,
    marginTop: 0,
    marginBottom: 10,
  },
  titlePlaceholder: {
    color: colors.gray,
    marginTop: 10,
  },
  description: {
    fontSize: 12,
    fontWeight: '300',
    color: colors.gray,
    paddingLeft: 5, paddingRight: 5,
  },
  linkIconWrap: {
    backgroundColor: colors.lightGray,
    borderRadius: 20,
    width: 40,
    height: 40,
    lineHeight: '40px',
  },
  linkIcon: {
    color: colors.gray,
    lineHeight: '39px',
  },
}
