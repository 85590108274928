'use strict'

import React, { Component, PropTypes } from "react"
import colors from '../../../lib/colors'
import dataStore from '../../../lib/dataStore'

import View from '../../../components/View'
import FS from '../../../components/FS'
import Loading from '../../../components/Loading'

export default class UploadProgressModal extends React.Component {
  static defaultProps = {
    uploading: false,
    submitting: false,
    progress: 0,
    progressBarColor: '#f00',
    progressBarHeight: 8,
    progressBarBorderRadius: 4,
    maxProgress: 0.9,
    showSpinner: false,
  };

  get progress () {
    return this.props.maxProgress * this.props.progress
  }

  renderIndicator () {
    if (!this.props.uploading) {
      return ( <Loading size="small"/> )
    } else {
      return (
        <div style={{borderRadius: this.props.progressBarBorderRadius, height: this.props.progressBarHeight, width: '80%'}}>
          <div
            progress={this.progress}
            style={{width: this.progress+'%', backgroundColor: this.props.progressBarColor, height: this.props.progressBarHeight}}
          />
        </div>
      )
    }
  }

  get containerStyle () {
    return {
      padding: 20,
      justifyContent: 'center',
      alignItems: 'center',
      width: this.width,
    }
  }

  renderPopupSuccess () {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    return (
      <View style={Object.assign({}, styles.modal, this.props.styles)}>
        <View style={Object.assign({}, styles.contentContainer, this.containerStyle)}>
          <FS.Text style={styles.success}>Submitted Successfully!</FS.Text>
          <a style={Object.assign({}, styles.openButton, {backgroundColor: colors.forUser(currentUser)})} href={this.props.postUrl} target="_blank" onClick={window.close}>Open My Post</a>
          <a style={styles.cancelButton} href={this.props.postUrl} onClick={window.close}>Close Popup</a>
        </View>
      </View>
    )
  }

  render () {
    if (this.props.submittedSuccessfully && this.props.isPopup) {
      return this.renderPopupSuccess()
    }
    return (
      <View style={Object.assign({}, styles.modal, this.props.styles)}>
        <View style={Object.assign({}, styles.contentContainer, this.containerStyle)}>
          <FS.Text style={styles.heading}>{this.props.uploading ? 'Uploading image...' : 'Saving post...'}</FS.Text>
          <View style={{height: 35, justifyContent: 'center', alignItems: 'center'}}>
            {this.renderIndicator()}
          </View>
        </View>
      </View>
    )
  }
}

var styles = {
  contentContainer: {
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderRadius: 10,
    display: "flex", flexDirection: "column",
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: "flex", flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    marginTop: 5,
    marginBottom: 10,
    fontSize: 16,
  },
  success: {
    marginTop: 5,
    marginBottom: 10,
    fontSize: 18,
  },
  button: {
  },
  openButton: {
    margin: 10,
    padding: '12px 30px',
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: colors.gray,
    color: colors.white,
  },
  cancelButton: {
    textDecoration: 'underline',
    padding: 10,
    margin: '0 0 -10px',
    fontSize: 12,
  },
}
