import moment from 'moment';

export function timeSince(when) {
  return moment(when).fromNow();
}

/*
 * Give back a very short, relative timestamp.  Desired Logic:
 *
 * 0-1 min - "just now"
 * 1-59 min - "XXm"
 * 1hour - 24 hours - "XXh"
 * 1 day - 6.9 days - "Xd"
 * 7 days+ weeks - "XXw"
 *
 **/
export function shortTimeSince(when = new Date()) {
  var now = new Date();
  if (typeof(when) === 'string') {
    when = new Date(when);
  }
  var secondsSince = (now.getTime() - when.getTime()) / 1000;

  if (secondsSince < 60) {
    return 'Just Now';
  } else if (secondsSince < 60 * 60) {
    return Math.floor(secondsSince / 60) + 'm';
  } else if (secondsSince < 60 * 60 * 24) {
    return Math.floor(secondsSince / 60 / 60) + 'h';
  } else if (secondsSince < 60 * 60 * 24 * 7) {
    return Math.floor(secondsSince / 60 / 60 / 24) + 'd';
  } else if (secondsSince < 60 * 60 * 24 * 7 * 4 * 2) {
    return Math.floor(secondsSince / 60 / 60 / 24 / 7) + 'w';
  } else if (secondsSince < 60 * 60 * 24 * 7 * 4 * 12) {
    return Math.floor(secondsSince / 60 / 60 / 24 / 7 / 4) + 'mo';
  } else {
    return Math.floor(secondsSince / 60 / 60 / 24 / 7 / 4 / 12) + 'yr';
  }

}
