import { defaultReducerMapping } from '../../lib/redux';

import deepFreeze from 'deep-freeze'

var {
  ONBOARDING_SWITCH_VIEW,
  ONBOARDING_UPDATE_STEP_INDEX,

  ONBOARDING_VIEW_KEY_SPLASHSCREEN,
  ONBOARDING_VIEW_KEY_TOUR,
  ONBOARDING_VIEW_KEY_SIGNIN,
  ONBOARDING_VIEW_KEY_SIGNUP,
  ONBOARDING_HIDE_AUTH,
  ONBOARDING_SHOW_AUTH,
} = require('../actions/base');

var {
  ONBOARDING_SOCIAL_AUTH_INSTAGRAM_GET_TOKEN,
  ONBOARDING_SOCIAL_AUTH_INSTAGRAM_COMPLETE,
  ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FAILED,
  ONBOARDING_SOCIAL_AUTH_FOURSQUARE_GET_TOKEN,
  ONBOARDING_SOCIAL_AUTH_FOURSQUARE_COMPLETE,
  ONBOARDING_SOCIAL_AUTH_CHECK_OAUTH_CREDENTIALS,
  ONBOARDING_SOCIAL_AUTH_RECEIVE_OAUTH_ERROR,
} = require('../actions/socialAuth');

var initialState = deepFreeze({
  currentView: ONBOARDING_VIEW_KEY_SPLASHSCREEN,
  //currentView: ONBOARDING_VIEW_KEY_SIGNUP,
  stepIndex: 1,
  isLoading: false,
  buddyRef: null,
});

//Just return the new/change parts of the state
var map = {};

map[ONBOARDING_SWITCH_VIEW] = (state, action) => {
  return {
    currentView: action.toView,
    buddyRef: action.buddyRef,
  }
}

map[ONBOARDING_SOCIAL_AUTH_CHECK_OAUTH_CREDENTIALS] =
map[ONBOARDING_SOCIAL_AUTH_FOURSQUARE_GET_TOKEN] =
map[ONBOARDING_SOCIAL_AUTH_INSTAGRAM_GET_TOKEN] = (state, action) => {
  return {
    isLoading: true,
  }
}

map[ONBOARDING_SOCIAL_AUTH_RECEIVE_OAUTH_ERROR] = (state, action) => {
  return {
    isLoading: false,
    currentView: ONBOARDING_VIEW_KEY_SIGNUP,
  }
}

map[ONBOARDING_SOCIAL_AUTH_FOURSQUARE_COMPLETE] =
map[ONBOARDING_SOCIAL_AUTH_INSTAGRAM_COMPLETE] = (state, action) => {
  return {
    isLoading: false,
  }
}

map[ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FAILED] =
map[ONBOARDING_HIDE_AUTH] = (state, action) => {
  return {
    isLoading: false
  }
}

map[ONBOARDING_SHOW_AUTH] = (state, action) => {
  return {
    buddyRef: action.buddyRef,
  }
}

export default defaultReducerMapping(initialState, map);
