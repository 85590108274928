import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import colors from '../../lib/colors'
import Heading from '../../components/Heading';
import LargeAuthButton from '../../components/LargeAuthButton';
import FS from '../../components/FS';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import HeaderArea from './HeaderArea';

import {
  switchView,
  ONBOARDING_VIEW_KEY_SIGNIN,
  ONBOARDING_VIEW_KEY_SIGNUP,
  ONBOARDING_VIEW_KEY_FORGOT_PASSWORD,
  ONBOARDING_VIEW_KEY_SPLASHSCREEN,
} from '../actions/base';

import {
  authenticateWithFacebook,
  authenticateWithFoursquare,
  authenticateWithInstagram,
} from '../actions/socialAuth';

class Start extends Component {

  componentDidMount() {
    if (this.props.initialView === 'SignIn') {
      this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SIGNIN, this.props.buddyRef));
    }
  }

  doSocialSignUp = (service) => {
    if (service === 'instagram') {
      this.props.dispatch(authenticateWithInstagram());
    } else if (service === 'foursquare') {
      this.props.dispatch(authenticateWithFoursquare());
    } else if (service === 'facebook') {
      this.props.dispatch(authenticateWithFacebook());
    }
  };

  handleSignUpEmail() {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SIGNUP));
  }

  handleSignIn() {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SIGNIN));
  }

  renderSocialIcon(service) {
    var color = colors[service];

    return (
      <FS.Touchable
        onPress={() => this.doSocialSignUp(service)}
        style={Object.assign({}, styles.socialButton, {backgroundColor: color})}>

        <FS.Icon
          name={service}
          size={20}
          color={colors.white}
        />

      </FS.Touchable>
    )
  }

  renderSplash() {
    return (
      <div className="FoodstandAuth-Start FoodstandAuth-contents" style={styles.container}>
        {<HeaderArea className={'inner-header'} />}
        <Heading text="Sign Up"/>

        <div style={styles.socialAuth}>
          {this.renderSocialIcon('foursquare')}
          {this.renderSocialIcon('facebook')}
          {this.renderSocialIcon('instagram')}
        </div>

        <LargeAuthButton className="signup" onClick={this.handleSignUpEmail.bind(this)} style={{backgroundColor: colors.red}}>
          Sign Up With Email
        </LargeAuthButton>

        <div className="FoodstandAuth-separator"/>

        <a onClick={() => this.handleSignIn()} style={styles.signIn}>
          I already have an account
        </a>

      </div>
    );
  }

  renderContent() {
    var currentView = this.props.base.currentView;
    if (currentView === ONBOARDING_VIEW_KEY_SIGNIN) {
      return (<SignIn/>);
    } else if (currentView === ONBOARDING_VIEW_KEY_SIGNUP) {
      return (<SignUp/>);
    } else if (currentView === ONBOARDING_VIEW_KEY_FORGOT_PASSWORD) {
      return (<ForgotPassword/>);
    } else if (currentView === ONBOARDING_VIEW_KEY_SPLASHSCREEN) {
      return this.renderSplash();
    }
  }

  render() {
    return this.renderContent();
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state, { });
}
export default connect(mapStateToProps)(Start);

var styles = {
  container: {
  },
  signUpButton: {
    backgroundColor: colors.red,
  },
  signIn: {
    display: 'block',
    padding: 13,
    borderWidth: 0,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightMediumGray,
    textAlign: 'center',
    color: colors.gray,
    margin: '30px -70px -40px',
  },
  socialAuth: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  socialButton: {
    flex: 1,
    alignItems: 'center',
    padding: 10,
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 20,
  },
};
