export default {
  "post_types": [
  {
    "id": 1,
    "name": "Info",
    "plural_name": "Info"
  },
  {
    "id": 2,
    "name": "Tip",
    "plural_name": "Tips"
  },
  {
    "id": 3,
    "name": "Question",
    "plural_name": "Questions"
  },
  {
    "id": 4,
    "name": "Event",
    "plural_name": "Events"
  },
  {
    "id": 5,
    "name": "Offer",
    "plural_name": "Offers"
  },
  {
    "id": 6,
    "name": "Just for Fun",
    "plural_name": "Just for Fun"
  },
  {
    "id": 7,
    "name": "Recipe",
    "plural_name": "Recipes"
  }
  ],
  "user_level_tags": [
  {
    "id": 15,
    "name": "Vegetarian"
  },
  {
    "id": 16,
    "name": "Vegan"
  },
  {
    "id": 32,
    "name": "Conflicted omnivore"
  },
  {
    "id": 23,
    "name": "Halal"
  },
  {
    "id": 24,
    "name": "Kosher"
  },
  {
    "id": 25,
    "name": "Paleo"
  },
  {
    "id": 17,
    "name": "Low-carb"
  },
  {
    "id": 18,
    "name": "Dairy-free"
  },
  {
    "id": 33,
    "name": "Whole 30"
  },
  {
    "id": 19,
    "name": "Lactose-free"
  },
  {
    "id": 20,
    "name": "Gluten-free"
  },
  {
    "id": 21,
    "name": "Sugar-free"
  },
  {
    "id": 22,
    "name": "Nut-free"
  },
  {
    "id": 26,
    "name": "Low-sodium"
  },
  {
    "id": 27,
    "name": "Pescatarian"
  },
  {
    "id": 28,
    "name": "Soy-free"
  },
  {
    "id": 29,
    "name": "Raw foods"
  },
  {
    "id": 30,
    "name": "Diabetic"
  }
  ],
  "profile_types": [
  {
    "id": 1,
    "name": "I am a Food-Lover",
    "label": "Food-Lover",
    "string_id": "food_lover",
    "is_filter": true,
    "description": "Enjoy good food? Want to ﬁnd and share ideas? This is you.",
    "placeholder_text": "Share what food and cuisine you love, dietary preferences, favorite meals to cook, etc."
  },
  {
    "id": 5,
    "name": "I am a Food Writer",
    "label": "Food Writer",
    "string_id": "food_writer",
    "is_filter": true,
    "description": "Blog on healthy recipes? Write about food issues? This is you.",
    "placeholder_text": "What do you write about? What food inspires you? Where can people read your work?"
  },
  {
    "id": 6,
    "name": "I represent an organization",
    "label": "Organization",
    "string_id": "organization",
    "is_filter": true,
    "description": "Acting for an organization that cares about good food?",
    "placeholder_text": "Describe your organization. What do you do? What do you care about? What sets you apart?"
  },
  {
    "id": 7,
    "name": "I represent a restaurant",
    "label": "Restaurant",
    "string_id": "restaurant",
    "is_filter": true,
    "description": "Represent a restaurant or cafe where great meals are a passion?",
    "placeholder_text": "Share your story and history: where you are, what you serve, where you source ingredients."
  },
  {
    "id": 8,
    "name": "I represent a farm",
    "label": "Farm",
    "string_id": "farm",
    "is_filter": true,
    "description": "Share life on your farm and connect with customers.",
    "placeholder_text": "Describe your farm: what you grow/raise, your farming practices and size, where you are, etc."
  },
  {
    "id": 9,
    "name": "I represent a market",
    "label": "Market",
    "string_id": "market",
    "is_filter": true,
    "description": "Represent a farmers’ market, specialty shop or other food store?",
    "placeholder_text": "What do you sell? Where do you source your ingredients? What makes you special?"
  },
  {
    "id": 10,
    "name": "I represent a publication",
    "label": "Publication",
    "string_id": "publication",
    "is_filter": true,
    "description": "Cover food topics in your publication? Join our community.",
    "placeholder_text": "What does your publication cover? What’s the connection to food? Who are your contributors?"
  },
  {
    "id": 4,
    "name": "I am an Artisan",
    "label": "Artisan",
    "string_id": "artisan",
    "is_filter": true,
    "description": "Make and sell your own food products? Choose this option.",
    "placeholder_text": "What do you make, where’s it sold and what makes it special?"
  },
  {
    "id": 2,
    "name": "I am a Chef",
    "label": "Chef",
    "string_id": "chef",
    "is_filter": true,
    "description": "Love cooking so much you do it for a living? You belong here.",
    "placeholder_text": "Where do you cook? What ingredients inspire you?"
  },
  {
    "id": 11,
    "name": "I am a Dietitian or Nutritionist",
    "label": "Nutrition Expert",
    "string_id": "nutritionist",
    "is_filter": true,
    "description": "Are you trained or certified to give people advice on nutrition and eating habits?",
    "placeholder_text": "What is your speciality? What type of advice can Foodstanders expect from you?"
  },
  {
    "id": 3,
    "name": "I am a Farmer",
    "label": "Farmer",
    "string_id": "farmer",
    "is_filter": true,
    "description": "Spend your days growing and raising what we eat? Welcome!",
    "placeholder_text": "Let people know what you grow/raise, your farming practices, where your farm is, where people can find your food, etc."
  }
  ],
}
