'use strict'

import React, { Component, PropTypes } from "react"
import FSText from '../../components/FSText'
import FSScrollView from '../../components/FSScrollView'
import FSTouchable from '../../components/FSTouchable'
import Loading from '../../components/Loading'
import colors from '../../lib/colors'
import dataStore from '../../lib/dataStore'
import View from '../../components/View'

const constants = {
  itemHeight: 60,
}

export default class IngredientList extends React.Component {
  onSelect = (ingredient) => {
    if( this.props.onSelect ) {
      this.props.onSelect(ingredient)
    }
  };

  renderIngredient = ( ingredient ) => (
    <FSTouchable
      style={styles.ingredient}
      key={ingredient.id}
      onPress={() => this.onSelect(ingredient)}>
      <FSText style={styles.ingredientText}>{ ingredient.name }</FSText>
    </FSTouchable>
  );


  get ingredients() {
    return dataStore.getMany( 'ingredient', this.props.visibleIngredientIds || [] )
  }

  get hasExactMatch() {
    let lowerQuery = (this.props.query||'').toLowerCase()
    return this.ingredients.some((i) => i.name.toLowerCase() === lowerQuery )
  }

  renderSpinner() {
    if( ! this.props.loading ) return

    return (
      <Loading style={styles.loading}/>
    )
  }
  renderCreateButton() {
    if( this.hasExactMatch || this.props.loading || !this.props.query || (this.props.query && this.props.query.length < 1) ) return

    if( this.props.visibleIngredientIds.length ) {
      return (
        <View style={styles.newIngredientInline}>
          <FSText style={styles.notFoundInline}>Don't see your ingredient?</FSText>
          <FSTouchable style={styles.createButtonInline} onPress={this.props.onCreate}>
            <FSText style={styles.createButtonInlineText}>Add it!</FSText>
          </FSTouchable>
        </View>
      )
    } else {
      return (
        <View style={styles.newIngredient}>
          <FSText style={styles.notFound}>No results found. Do you wish to create the ingredient "{this.props.query}"?</FSText>
          <FSTouchable style={styles.createButton} onPress={this.props.onCreate}>
            <FSText style={styles.createButtonText}>Add it!</FSText>
          </FSTouchable>
        </View>
      )
    }
  }

  render() {
    return (
      <FSScrollView
        contentContainerStyle={styles.container}
      >
        { this.ingredients.map(this.renderIngredient) }
        { this.renderCreateButton() }
        { this.renderSpinner() }
      </FSScrollView>
    )
  }
}

var styles = {
  container: {
    display: "flex", flexDirection: "column",
    alignItems: 'stretch',
  },
  ingredient: {
    paddingLeft: 20, paddingRight: 20,
    paddingTop: 10, paddingBottom: 10,
  },
  ingredientText: {
    fontSize: 24,
    color: colors.darkGray,
    fontWeight: '300',
  },
  newIngredient: {
    paddingLeft: 20, paddingRight: 20,
    paddingTop: 10, paddingBottom: 10,
    display: "flex", flexDirection: "column",
    alignItems: 'stretch',
  },
  notFound: {
    color: colors.gray,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: '700',
    textAlign: 'center',
    flex: 1
  },
  createButton: {
    backgroundColor: colors.gray,
    borderRadius: 3,
    flex: 1,
    paddingTop: 10, paddingBottom: 10,
  },
  createButtonText: {
    textAlign: 'center',
    color: colors.white,
    fontWeight: '700',
    fontSize: 16,
  },
  newIngredientInline: {
    paddingLeft: 20, paddingRight: 20,
    paddingTop: 10, paddingBottom: 10,
    display: "flex", flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  notFoundInline: {
    fontWeight: '200',
    color: colors.mediumGray,
    fontSize: 16,
    marginRight: 10,
  },
  createButtonInline: {
    backgroundColor: colors.gray,
    borderRadius: 5,
    paddingTop: 2, paddingBottom: 2,
    paddingLeft: 10, paddingRight: 10,
  },
  createButtonInlineText: {
    fontWeight: '200',
    color: colors.white,
    fontSize: 16,
  },
  loading: {
    paddingTop: 15, paddingBottom: 15,
    alignSelf: 'flex-start',
    marginLeft: 15,
  }
}
