import { combineReducers } from 'redux';
import tour from './tour';
import base from './base';
import signin from './signin';
import signup from './signup';

export default combineReducers({
  base: base,
  tour: tour,
  signin: signin,
  signup: signup,
});
