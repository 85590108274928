'use strict'

import { connect } from 'react-redux'
import React, { Component, PropTypes } from "react"
import FSText from '../../components/FSText'
import FSIcon from '../../components/FSIcon'
import FSTouchable from '../../components/FSTouchable'
import TagSelector from '../../components/TagSelector';
import colors from '../../lib/colors'
import FadeOutView from '../../components/FadeOutView'
import View from '../../components/View'

const constants = {
  itemHeight: 60,
  timeUntilFade: 3000,
}

var {
  removeIngredient,
  focusIngredient,
  toggleIngredientLevelTag,
  viewIngredientLevelTagDetails
} = require('../actions/ingredients')

class IngredientEditor extends React.Component {

  constructor(props) {
    super(props)
    this.currentUser = dataStore.get('user',props.currentUserId)
    this.state = {
      showJustAdded: false,
    }
  }

  componentDidMount() {
    var addedAt = this.props.ingredientsAddedAt[this.props.ingredient.id] || 0
    var timeSinceAdd = Date.now() - addedAt
    if( timeSinceAdd < constants.timeUntilFade ) {
      this.showJustAdded( timeSinceAdd )
    }
  }

  componentWillUnmount() {
    if( this.timeout ) clearTimeout(this.timeout)
  }

  showJustAdded( timeSinceAdded ) {
    this.setState({showJustAdded: true})
    this.timeout = setTimeout(() => {
      this.hideJustAdded()
      this.timeout = undefined
    }, constants.timeUntilFade - timeSinceAdded)
  }

  hideJustAdded = () => this.setState({showJustAdded: false});

  onToggleIngredientLevelTag = (ingredientId, ingredientLevelTagId) => {
    this.props.dispatch( toggleIngredientLevelTag(ingredientId, ingredientLevelTagId) )
  };

  removeIngredient = (ingredientId) => {
    this.props.dispatch(removeIngredient(ingredientId))
  };

  renderAdded() {
    if( ! this.state.showJustAdded ) return
    return (
      <FadeOutView delay={constants.timeUntilFade - 1000} style={{position: 'absolute', top: 0, right: 0}}>
        <View style={Object.assign({}, styles.justAddedWrapper, {backgroundColor: colors.forUser(this.currentUser)})}>
          <FSIcon name="checkmark" style={styles.justAddedCheckmark}/>
          <FSText style={styles.justAddedText}>Added</FSText>
        </View>
      </FadeOutView>
    )
  }

  handleLongPress = (ingredientLevelTagId) => {
    this.props.dispatch( viewIngredientLevelTagDetails( ingredientLevelTagId ) )
  };

  renderTagSelect() {
    if( this.props.ingredient.ingredient_level_tag_ids.length === 0 ) {
      return (
        <FSText style={styles.thanks}>Thanks for adding your ingredient to Foodstand!</FSText>
      )
    } else {
      return (
        <TagSelector
          style={styles.tagContainer}
          nameProp="title"
          selectedOptionIds={this.props.ingredientLevelTagIds[this.props.ingredient.id]}
          options={dataStore.getMany('ingredient_level_tag', this.props.ingredient.ingredient_level_tag_ids)}
          visibleTagIds={this.props.ingredient.ingredient_level_tag_ids}
          onPress={(ingredientLevelTag) => this.onToggleIngredientLevelTag(this.props.ingredient.id, ingredientLevelTag.id)}
          onLongPress={(ingredientLevelTag) => this.handleLongPress(ingredientLevelTag.id)}
          inactiveColor={colors.white}
          activeColor={this.currentUser.color}
          inactiveTextColor={colors.darkGray}
          inactiveStyle={{borderWidth: 1, borderColor: colors.lightMediumGray, height: 30}}
          activeStyle={{borderColor: this.currentUser.color}}
          activeTextColor={colors.white}
        ></TagSelector>
      )
    }
  }

  render() {
    return (
      <View style={Object.assign({}, styles.slide, {width: '100%'})} key={this.props.ingredient.id}>
        <View style={styles.ingredientNameWrapper}>

          <FSText style={styles.ingredientName} numberOfLines={1}>{ this.props.ingredient.name }</FSText>

          <FSTouchable style={ styles.removeButton } onPress={() => this.removeIngredient(this.props.ingredient.id)} >
            <FSIcon name="x-rounded-corner" style={ styles.removeButtonIcon }/>
            { this.renderAdded() }
          </FSTouchable>

        </View>

        { this.renderTagSelect() }

      </View>
    )
  }

}

var styles = {
  ingredientNameWrapper: {
    display: "flex", flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 15, paddingRight: 15,
    paddingTop: 15,
  },
  ingredientName: {
    fontSize: 26,
    color: colors.darkGray,
    fontWeight: '300',
    flex: 1,
  },
  removeButton: {
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    flex: 0,
  },
  removeButtonIcon: {
    fontSize: 14,
    color: colors.darkGray,
    marginTop: 3,
  },
  slide: {
    position: 'relative',
    flex: 1,
    justifyContent: 'flex-start',
    display: "flex", flexDirection: "column",
    alignItems: 'stretch',
  },
  ingredients: {
    flex: 1,
  },
  tagContainer: {
    paddingLeft: 15, paddingRight: 15,
  },
  justAddedWrapper: {
    display: "flex", flexDirection: "row",
    paddingLeft: 10, paddingRight: 10,
    paddingTop: 3, paddingBottom: 3,
    borderRadius: 3,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 4,
  },
  justAddedText: {
    color: colors.white,
  },
  justAddedCheckmark: {
    color: colors.white,
    marginRight: 2,
  },
  thanks: {
    marginLeft: 15, marginRight: 15,
    marginTop: 10, marginBottom: 10,
    fontSize: 16,
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.ingredients, {
    currentUserId: state.app.currentUserId,
  })
}

export default connect(mapStateToProps)(IngredientEditor)
