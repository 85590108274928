import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class FSButton extends Component {

  componentDidMount() {
  }

  render() {
    return (
      <a onClick={this.props.onPress} style={this.props.style}>
        {this.props.children}
      </a>
    );
  }
}

export default FSButton;

var styles = {
  heading: {
  }
}
