'use strict'

import React, { Component, PropTypes } from "react";
import FSImage from '../../components/FSImage';
import ModalHeader from '../../components/ModalHeader';
import FSView from '../../components/FSView';
import LoadingOverlay from '../../components/LoadingOverlay'
import colors from '../../lib/colors'
import View from '../../components/View'

export default class PostEditModal extends FSView {

  get loading() {
    return false
  }

  get currentUser() {
    return dataStore.get('user',this.props.currentUserId)
  }

  renderModalHeader () {
    if( this.headerHidden === true ) return;
    return (
      <ModalHeader
        title={this.props.title}
        leftText={this.props.leftButtonText || "CANCEL"}
        onPressLeft={this.props.onCancel}
        rightText={this.props.rightButtonText || "DONE"}
        onPressRight={() => this.props.onDone(this.value)}
      />
    )
  }

  render() {
    return (
      <View style={Object.assign({}, styles.expand, styles.container)}>

        {this.renderForm()}

        <LoadingOverlay isLoading={this.loading}/>
      </View>
    )
  }
}

var styles = {
  expand: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  keyboardSpacer: {
    alignSelf: 'flex-end',
    backgroundColor: colors.white,
  },
  bgOverlay: {
    backgroundColor: 'rgba(255,255,255,0.75)',
  },
  container: {
    backgroundColor: colors.white,
  },
}
