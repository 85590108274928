export const AVATAR_SIZE = 105;

export const EDITABLE_USER_ATTRIBUTE_TYPES = {
  'bio': {
    type: 'text',
    title: 'Add Bio',
    placeholder: "Describe yourself...",
    maxLength: 1000,
  },
  'website': {
    type: 'url',
    title: 'Add Website',
  },
  'profile_type_id': {
    type: 'profile-type',
    title: 'Profile Type',
  },
  'password': {
    type: 'password',
    title: 'Password',
  },
  'avatar': {
    type: 'avatar',
    title: 'Profile Photo',
  },
  'home_location': {
    type: 'location',
    title: 'Home Location',
  },
  'user_level_tag_ids': {
    type: 'user-level-tag-ids',
    title: 'Edit Interests',
  },
  'profile_is_indexable': {
    type: 'boolean',
    title: 'Searchability',
  },
  'full_name': {
    type: 'multi',
    title: 'Full name',
    fields: [{
      type: 'string',
      attribute: 'first_name',
      placeholder: 'First name',
      autoCorrect: false,
      autoCapitalize: 'words',
      height: 50,
    }, {
      type: 'string',
      attribute: 'last_name',
      placeholder: 'Last name',
      autoCorrect: false,
      autoCapitalize: 'words',
      height: 50,
    }]
  }
}
