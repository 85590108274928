'use strict'

import dataStore from '../lib/dataStore'

class ProfileType {
}

// Return this as a list since it's not inconceivable that the order
// could matter at some point in the future:
ProfileType.all = function() {
  var types = dataStore.getAll('profile_type')
  var results = []
  for(let key in types) {
    if( types.hasOwnProperty(key) ) {
      results.push(types[key])
    }
  }
  return results;
}

export default ProfileType;
