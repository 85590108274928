'use strict'

import React, { Component, PropTypes } from "react";
import colors from '../../lib/colors'

var c = {
  fontSize: 14,
  fontWeight: '700',
}

var styles = {
  container: {
    display: "flex", flexDirection: "column",
    marginTop: 10, marginBottom: 10,
  },
  tagGroup: {
    display: "flex", flexDirection: "row",
    flexWrap: 'nowrap',
  },
  tagGroupWrap: {
    flexWrap: 'wrap',
  },
  tag: {
    flex: 1,
    borderRadius: 2,
    height: 44,
    lineHeight: '43px',
    whiteSpace: 'nowrap',
  },
  tagWrap: {
    flex: 0,
  },
  tagLast: {
    marginRight: 0,
  },
  tagText: {
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
  },
}

export default styles
