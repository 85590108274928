'use strict'

import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux'
import colors from '../../../lib/colors'

import FSTouchable from '../../../components/FSTouchable'
import FSIcon from '../../../components/FSIcon'

import {
  switchView,
  ONBOARDING_VIEW_KEY_SPLASHSCREEN,
} from '../../actions/base'

class NavBar extends Component {

  constructor(props) {
    super(props);
  }

  renderDot(index) {
    var selectedIndex = this.props.index;
    return (
      <View
        key={index}
        style={Object.assign({}, styles.dot, selectedIndex === index ? styles.selectedDot : null)}/>
    )
  }

  renderDots() {
    var dots = [];
    for(var i = 1 ; i <= 4 ; i++) {
      dots.push(this.renderDot(i));
    }

    return (
      <div style={styles.dots}>
        {dots}
      </div>
    )
  }

  prev() {
    if (this.props.index > 1) {
      this.props.navigator.pop();
    } else {
      this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SPLASHSCREEN));
    }
  };

  render() {
    return (
      <div style={[
        styles.container,
        this.props.index > 1 ? {backgroundColor: colors.red} : null
      ]}>

        <FSTouchable onPress={this.prev}>
          <FSIcon name="left-arrow" size={18} style={styles.prev}/>
        </FSTouchable>

        {this.renderDots()}

        <div style={styles.spacer}/>

      </div>
    )
  }

}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.signup, {});
}

export default connect(mapStateToProps)(NavBar);

var styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    padding: '10px 15px',
    alignItems: 'center',
    backgroundColor: colors.darkMediumAndSortaBlueishGray,
    borderWidth: 0.5, // no idea why this is needed
    borderColor: 'transparent',
  },
  dots: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dot: {
    borderColor: colors.white,
    borderWidth: 1,
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 5, marginRight: 5,
  },
  selectedDot: {
    backgroundColor: colors.white,
  },
  prev: {
    display: 'inline-block',
    padding: 10,
    margin: -10,
    color: colors.white,
  },
  spacer: {
    width: 32,
    height: 18,
  }
}
