import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors';

class LargeAuthButton extends Component {

  render() {
    return (
      <a onClick={this.props.onClick} className={"FoodstandAuth-LargeAuthButton " + this.props.className} style={this.props.style}>
        <span style={styles.text}>
          {this.props.children}
        </span>
      </a>
    );
  }
}

LargeAuthButton.defaultProps = {
  className: "",
  style: {},
};

export default LargeAuthButton;

var styles = {
  button: {
    margin: '0 auto',
    textAlign: 'center',
    border: 0,
    borderRadius: 20,
    padding: '10px 35px',
  },
  text: {
    color: colors.white,
  },
  test: {

  }
}
