'use strict'

import React, { Component, PropTypes } from "react";
import { connect } from 'react-redux';
import FSText from '../../components/FSText'
import FSTouchable from '../../components/FSTouchable'
import FSIcon from '../../components/FSIcon'
import FadeInOutView from '../../components/FadeInOutView'
import colors from '../../lib/colors'
import c from '../constants'
import View from '../../components/View'

import {
  fetchFeaturedHashTag,
  addFeaturedHashTagText
} from '../actions'

class FeaturedHashTag extends React.Component {

  componentWillMount() {
  }

  handleButtonPress = () => {
    var tag = this.props.featuredHashTag;
    this.props.dispatch(addFeaturedHashTagText(tag.tag));
  };

  renderCTA(tag) {
    var user = dataStore.get('user', this.props.currentUserId);

    var descriptionColor = tag.description_color || colors.gray;
    var textSize = tag.description_font_size || 14;
    var textStyle = {color: descriptionColor, fontSize: textSize};

    var buttonColor = tag.button_color || colors.forUser(user);
    var buttonTextColor = tag.button_text_color || colors.white;

    var desc = this.props.post.description;
    var visible = !tag.tag || !desc || desc.toLowerCase().indexOf(tag.tag.toLowerCase()) === -1;
    return (
      <FadeInOutView visible={visible} style={Object.assign({}, styles.container, this.props.style)}>
        <FSText style={Object.assign({}, styles.description, textStyle)}>{tag.description}</FSText>
        {tag.tag && tag.button_text && (
          <FSTouchable style={Object.assign({}, styles.button, {backgroundColor: buttonColor})} onPress={this.handleButtonPress}>
            <FSText style={Object.assign({}, styles.buttonText, {color: buttonTextColor})}>{tag.button_text}</FSText>
          </FSTouchable>
        )}
      </FadeInOutView>
    )
  }

  render() {
    var tag = this.props.featuredHashTag;
    if (tag) {
      return this.renderCTA(tag);
    }

    return (<View/>);
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.base, {
    currentUserId: state.app.currentUserId,
  })
}

export default connect(mapStateToProps)(FeaturedHashTag);

var styles = {
  container: {
    display: "flex", flexDirection: "row",
    paddingTop: c.fieldSpacing / 2, paddingBottom: c.fieldSpacing / 2,
    marginLeft: c.gutterWidth, marginRight: c.gutterWidth,
    marginTop: c.fieldSpacing,
    padding: 15,
    backgroundColor: colors.veryLightGray,
  },
  description: {
    flex: 1,
    fontSize: 12,
    textAlign: 'left',
  },
  button: {
    justifyContent: 'center',
    backgroundColor: colors.gray,
    alignSelf: 'flex-start',
    padding: 8,
    borderRadius: 5,
    marginLeft: 8,
  },
  buttonText: {
    color: colors.white,
  },
}
