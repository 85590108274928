'use strict'

import React from 'react'
import colors from '../lib/colors'

var {
  StyleSheet
} = React;

export default {
  container: {
    backgroundColor: colors.lightGray,
  },
  spacer: {
    height: 40,
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray,
  },
  attribute: {
    height: 50,
    paddingLeft: 15,
    paddingRight: 15,
    flexDirection: 'row', display: 'flex',
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray,
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
  },
  attributeIcon: {
    width: 30,
    flexDirection: 'row', display: 'flex',
    justifyContent: 'center',
    margin: 10,
    marginLeft: 0,
  },
  attributeText: {
    flex: 1,
  },
  arrowIcon: {
    paddingLeft: 10,
  },
  linkedAccountsIcon: {
    width: 30,
    height: 30,
    margin: 10,
    marginLeft: 0,
  },
  linkedFB: {
    position: 'absolute',
    top: 2,
    left: 2,
  },
  linkedIG: {
    position: 'absolute',
    top: 2,
    right: 1,
  },
  linkedFS: {
    position: 'absolute',
    bottom: 0,
    left: 11,
  },
}
