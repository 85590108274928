
export function defaultReducerMapping(initialState, actionsMap) {
  //Combine the result of the reduceFn with the existing state and return
  return (state = initialState, action) => {
    const reduceFn = actionsMap[action.type]
    if (!reduceFn) return state

    return Object.assign({}, state, reduceFn(state, action))
  }
}
