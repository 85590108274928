/**
* Reusable UserAvatar component.  Supports specifying:
* size = total width/height including border & padding
* borderWidth
* padding
**/

import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

import FSImage from './FSImage'
import FSIcon from './FSIcon'

export default class UserAvatar extends React.Component {

  iconForUser = (user) => {
    if( !user || !user.profile_type ) return 'profiletype-generic'
    return "profiletype-" + user.profile_type.string_id.replace(/_/g, '');
  };

  static defaultProps = {
    crown: false,
    crownScale: 0.5,
    borderWidth: 0,
    padding: 0,
  };

  renderCrown(color) {

    var size = this.props.size * this.props.crownScale

    var sty = {
      top: -size * 0.7,
      left: 0 - (this.props.padding / 2) - (this.props.borderWidth / 2),
      textAlign: 'center',
      width: this.props.size, // Not a mistake: full width of the avatar, for centering
    }

    return (
      <FSIcon name="crown" size={size} color={color} style={Object.assign({}, styles.crown,sty)}/>
    )
  }

  render() {
    var user = this.props.user;
    var w = this.props.size;

    var userColor = (user && user.profile_type) ? colors.user[user.profile_type.string_id] : colors.lightMediumGray;

    var avatarStyles = {
      //overflow: 'hidden', // Not necessary, I think.
      width: w,
      height: w,
      borderWidth: this.props.borderWidth,
      borderColor: userColor,
      borderRadius: w / 2,
    };

    var imageWidth = w - (2 * this.props.borderWidth);

    var imageStyles = {
      width: imageWidth,
      height: imageWidth,
      borderRadius: imageWidth / 2,
      borderWidth: this.props.padding,
      overflow: 'hidden',
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
    };

    if (user && user.images && user.images.length > 0 && user.images[0]) {
      return (
        <div style={Object.assign({}, avatarStyles, this.props.style)}>
          { this.props.crownIfTopUser && this.props.user.is_currently_top_user && this.renderCrown(userColor) }
          <FSImage
            source={{ uri: user.images[0]}}
            style={imageStyles}
            imgixAttrs={{w: imageWidth, h: imageWidth}}
          />
        </div>
      )
    } else {
      var iconStyles = Object.assign({}, imageStyles, {
        backgroundColor: userColor,
        borderWidth: 0,
      })
      return (
        <div style={Object.assign({}, avatarStyles, {backgroundColor: userColor}, this.props.style)}>
          <FSIcon
            allowFontScaling={false}
            name={this.iconForUser(user)}
            color={colors.white}
            size={imageWidth}
            style={iconStyles}
          />
        </div>
      )
    }
  }
}

var styles = {
  crown: {
    position: 'absolute',
    justifyContent: 'center',
  },
}
