"use strict"

import colors from "../lib/colors"
import config from "../config"

var UserProperties = {
  home_location_display: {
    get: function () {
      var addr = ""
      if (this.home_city) {
        addr = `${this.home_city}, ${this.home_state_code} ${this.postal_code}`
      }
      if (this.home_neighborhood) {
        addr = `${this.home_neighborhood}, ` + addr
      }
      return addr
    },
    enumerable: false,
  },
  url: {
    get: function () {
      return `${config.env.FS_WEB_HOST}/${encodeURI(this.username.toLowerCase())}`
    },
    enumerable: false,
  },
  color: {
    get: function () {
      return colors.forUser(this)
    },
  },
  fullName: {
    get: function () {
      if (!this.first_name && !this.last_name) return
      return [this.first_name, this.last_name].join(" ")
    },
  },
  homeTown: {
    get: function () {
      if (!this.home_city || !this.home_state_code) return
      return [this.home_city, this.home_state_code].join(", ")
    },
  },
}

export default UserProperties
