// For reference, a new post is expected to come back in the form:
//
//  "post"=>{
//    "pending_photo_filename"=>"f6c92b73-614f-75e6-a799-fa2dfb713561.jpg",
//    "description"=>"test caption!",
//    "share_on_twitter"=>false,
//    "share_on_facebook"=>false,
//    "post_ingredients_attributes"=>[
//      {
//        "id"=>nil,
//        "ingredient_id"=>"1773",
//        "tag_ids"=>[
//          "59",
//          "66"
//        ]
//      },
//      {
//        "id"=>nil,
//        "ingredient_id"=>"210",
//        "tag_ids"=>[
//          "49",
//          "54"
//        ]
//      }
//    ],
//    "location_id"=>"11",
//    "is_private"=>false,
//    "recipe_link"=>"http://google.com",
//    "user_level_tag_ids"=>[
//      "14",
//      "35",
//      "32",
//      "40"
//    ]
//  }


import React from 'react';

import extractQueryStringParam from '../../lib/utils/extractQueryStringParam'
import uploadImageFileToS3 from '../../lib/utils/uploadImageFileToS3';
//import { switchTab } from 'app/actions/app';
//import { markAllFeedsStale, resetFeed, setActiveFeed, fetchPage, clearAllFeeds, setEnsureNewPost } from 'app/actions/feed';
//import { resetFilters, clearQuery } from 'app/actions/search'
//import { sharePostOnTwitter, sharePostOnFacebook, sharePostOnInstagram } from 'app/lib/socialSharing';
//import { sharePost } from 'app/actions/sharing';

export const NEW_POST_BOOTSTRAP_COMPLETE = 'NEW_POST_BOOTSTRAP_COMPLETE';
export const NEW_POST_POST_EDIT_POST = 'NEW_POST_POST_EDIT_POST';
export const NEW_POST_POST_EDIT_POST_FAILED = 'NEW_POST_POST_EDIT_POST_FAILED';
export const NEW_POST_POST_EDIT_POST_READY = 'NEW_POST_POST_EDIT_POST_READY';

export const NEW_POST_OPEN_CAMERA = 'NEW_POST_OPEN_CAMERA';
export const NEW_POST_RECEIVE_CAMERA_RESPONSE = 'NEW_POST_RECEIVE_CAMERA_RESPONSE';
export const NEW_POST_CLOSE_CAMERA = 'NEW_POST_CLOSE_CAMERA';
export const NEW_POST_ACTIVATE = 'NEW_POST_ACTIVATE';
export const NEW_POST_CANCEL = 'NEW_POST_CANCEL';

export const NEW_POST_UPLOADING_IMAGE = 'NEW_POST_UPLOADING_IMAGE';
export const NEW_POST_UPLOADING_IMAGE_URL = 'NEW_POST_UPLOADING_IMAGE_URL';
export const NEW_POST_UPLOADING_IMAGE_COMPLETE = 'NEW_POST_UPLOADING_IMAGE_COMPLETE';
export const NEW_POST_UPLOADING_IMAGE_FAILED = 'NEW_POST_UPLOADING_IMAGE_FAILED';
export const NEW_POST_UPLOADING_IMAGE_CANCELED = 'NEW_POST_UPLOADING_IMAGE_CANCELED';
export const NEW_POST_SET_IMAGE_METADATA = 'NEW_POST_SET_IMAGE_METADATA';

export const NEW_POST_SUBMIT_POST_ON_UPLOAD_COMPLETE = 'NEW_POST_SUBMIT_POST_ON_UPLOAD_COMPLETE';

export const NEW_POST_SUBMIT_POST = 'NEW_POST_SUBMIT_POST';
export const NEW_POST_SUBMIT_POST_COMPLETE = 'NEW_POST_SUBMIT_POST_COMPLETE';
export const NEW_POST_SUBMIT_POST_FAILED = 'NEW_POST_SUBMIT_POST_FAILED';
export const NEW_POST_SUBMIT_POST_DISMISS_ERROR = 'NEW_POST_SUBMIT_POST_DISMISS_ERROR';

export const NEW_POST_CHECK_INITIAL_VALUES_FROM_URL = 'NEW_POST_CHECK_INITIAL_VALUES_FROM_URL';

export const NEW_POST_UPDATE_POST = 'NEW_POST_UPDATE_POST';
export const NEW_POST_UPDATE_POST_COMPLETE = 'NEW_POST_UPDATE_POST_COMPLETE';
export const NEW_POST_UPDATE_POST_FAILED = 'NEW_POST_UPDATE_POST_FAILED';

export const NEW_POST_CLEAR_PHOTO = 'NEW_POST_CLEAR_PHOTO';
export const NEW_POST_EDIT_PHOTO = 'NEW_POST_EDIT_PHOTO';
export const NEW_POST_UPDATE_PHOTO = 'NEW_POST_UPDATE_PHOTO';
export const NEW_POST_SIGN_UP_PHOTO_FILE_SELECTED = 'NEW_POST_SIGN_UP_PHOTO_FILE_SELECTED';
export const NEW_POST_EDIT_ATTRIBUTE = 'NEW_POST_EDIT_ATTRIBUTE';
export const NEW_POST_CANCEL_EDITING_ATTRIBUTE = 'NEW_POST_CANCEL_EDITING_ATTRIBUTE';
export const NEW_POST_DONE_EDITING_ATTRIBUTE = 'NEW_POST_DONE_EDITING_ATTRIBUTE';
export const NEW_POST_TOGGLE_SOCIAL_SHARE = 'NEW_POST_TOGGLE_SOCIAL_SHARE';
export const NEW_POST_TOGGLE_POST_TYPE = 'NEW_POST_TOGGLE_POST_TYPE';
export const NEW_POST_REMOVE_ATTRIBUTE = 'NEW_POST_REMOVE_ATTRIBUTE';
export const NEW_POST_CLEAR_EXISTING_LINK_POSTS = 'NEW_POST_CLEAR_EXISTING_LINK_POSTS'

export const NEW_POST_HANDLE_LINK_URL_CHANGE = 'NEW_POST_HANDLE_LINK_URL_CHANGE';
export const NEW_POST_HANDLE_LINK_URL_CHANGE_COMPLETE = 'NEW_POST_HANDLE_LINK_URL_CHANGE_COMPLETE';
export const NEW_POST_HANDLE_LINK_URL_CHANGE_FAILED = 'NEW_POST_HANDLE_LINK_URL_CHANGE_FAILED';
export const NEW_POST_DOWNLOAD_SCRAPED_IMAGE = 'NEW_POST_DOWNLOAD_SCRAPED_IMAGE';
export const NEW_POST_DOWNLOAD_SCRAPED_IMAGE_COMPLETE = 'NEW_POST_DOWNLOAD_SCRAPED_IMAGE_COMPLETE';

export const NEW_POST_UPDATE_ATTRIBUTE = 'NEW_POST_UPDATE_ATTRIBUTE';

export const NEW_POST_GET_FEATURED_HASH_TAG = 'NEW_POST_GET_FEATURED_HASH_TAG';
export const NEW_POST_GET_FEATURED_HASH_TAG_FAILED = 'NEW_POST_GET_FEATURED_HASH_TAG_FAILED';
export const NEW_POST_GET_FEATURED_HASH_TAG_COMPLETE = 'NEW_POST_GET_FEATURED_HASH_TAG_COMPLETE';
export const NEW_POST_ADD_FEATURED_HASH_TAG_TEXT = 'NEW_POST_ADD_FEATURED_HASH_TAG_TEXT';

export const NEW_POST_SETUP_CURRENT_USER = 'NEW_POST_SETUP_CURRENT_USER';

//var FileOperations = require('NativeModules').FileOperations;
//var ImageOperations = require('NativeModules').ImageOperations;

var {
  InteractionManager,
} = React;

export function setImageMetadata(meta) {
  return ({
    type: NEW_POST_SET_IMAGE_METADATA,
    imageMetadata: meta
  })
}

export function bootstrapComplete() {
  return ({
    type: NEW_POST_BOOTSTRAP_COMPLETE,
  })
}

export const currentlyEditingAttrToParamsMap = {
  ingredients: 'post_ingredients_attributes',
  location_id: 'location_id',
  challenge_ids: 'challenge_ids',
  link_url: 'link_url',
  image_url: 'image_url',
  title: 'title',
  description: 'description',
  offer: 'offer',
  //tags: 'user_level_tag_ids',
  start_date: 'start_date',
  start_time: 'start_time',
  end_date: 'end_date',
  end_time: 'end_time',
}

export function setupCurrentUser() {
  //Current user info is provided by rails app via "ENV" var
  return dispatch => {
    dispatch({
      type: NEW_POST_SETUP_CURRENT_USER
    });
  }
}

//var Camera = require('NativeModules').DBCameraReact;
export function openCamera(tintColor) {
alert('implement camera!');
/*
  return dispatch => {
    dispatch({
      type: NEW_POST_OPEN_CAMERA,
      receivedAt: Date.now()
    });

    Camera.show({
      tintColor: tintColor,
    }, (imageUrl, imageMetadata) => {
      if (imageUrl) {
        dispatch({
          type: NEW_POST_RECEIVE_CAMERA_RESPONSE,
          imageUrl: imageUrl,
          imageMetadata: imageMetadata,
          receivedAt: Date.now()
        });
      } else {
        dispatch({
          type: NEW_POST_CLOSE_CAMERA,
          receivedAt: Date.now()
        });
      }
    })
  }
*/
}

export function dismissError() {
  return { type: NEW_POST_SUBMIT_POST_DISMISS_ERROR }
}

export function activatePost() {
  return {
    type: NEW_POST_ACTIVATE,
    receivedAt: Date.now()
  }
}

export function cancelPost() {
  return (dispatch, getState) => {
    dispatch({
      type: NEW_POST_CANCEL,
      receivedAt: Date.now()
    })

    var dialog = $('.foodstand-new-post-dialog');
    if (dialog && dialog.length > 0) {
      dialog.hide();
    } else {
      window.close();
    }
  }
}

export function clearExistingLinkPosts() {
  return {
    type: NEW_POST_CLEAR_EXISTING_LINK_POSTS,
  }
}


export function clearPhoto() {
  return {
    type: NEW_POST_CLEAR_PHOTO,
  }
}

export function updatePhoto( path ) {
  return {
    type: NEW_POST_UPDATE_PHOTO,
    path: path,
  }
}

export function updateTitle( text ) {
  return {
    type: NEW_POST_UPDATE_TITLE,
    title: text,
  }
}

export function updateDescription( text ) {
  return {
    type: NEW_POST_UPDATE_DESCRIPTION,
    description: text,
  }
}

export function removeAttribute( attr ) {
  return {
    type: NEW_POST_REMOVE_ATTRIBUTE,
    attr: attr,
    receivedAt: Date.now()
  }
}

export function editAttribute( attr, post = null ) {
  return {
    type: NEW_POST_EDIT_ATTRIBUTE,
    attr: attr,
    post: post,
    receivedAt: Date.now()
  }
}

export function cancelEditingAttribute( attr ) {
  return {
    type: NEW_POST_CANCEL_EDITING_ATTRIBUTE,
    attr: attr,
    receivedAt: Date.now()
  }
}

export function updateAttribute( attr, value ) {
  return {
    type: NEW_POST_UPDATE_ATTRIBUTE,
    attr: attr,
    value: value,
  }
}

//For /submit on web.  See if url contains a url to scrape on load.
export function checkInitialValuesFromUrl() {
  return (dispatch, getState) => {
    dispatch({
      type: NEW_POST_CHECK_INITIAL_VALUES_FROM_URL,
    });

    var fields = ['title', 'description', 'link_url'];
    fields.forEach(field => {
      var val = extractQueryStringParam(location.href, field)
      if (val && val.length > 0) {
        dispatch(doneEditingAttribute(field, decodeURIComponent(val)))
      }
    })
  }
}

export function doneEditingAttribute( attr, val ) {
  return (dispatch, getState) => {
    if( val === undefined ) {
      return cancelEditingAttribute(attr)
    } else {
      dispatch({
        type: NEW_POST_DONE_EDITING_ATTRIBUTE,
        attr: attr,
        value: val,
        receivedAt: Date.now()
      });

      if (attr === 'link_url' && val && val.length > 0) {
        dispatch(handleLinkUrlChange());
      }
    }
  }
}

export function handleLinkUrlChange( attr, val ) {
  return (dispatch, getState) => {
    var state = getState().base;
    var linkUrl = state.post.link_url;
    dispatch({
      type: NEW_POST_HANDLE_LINK_URL_CHANGE,
      API_CALL: {
        url: '/url_scraper',
        dataType: 'json',
        method: 'GET',
        data: {url: linkUrl},
        getDataPayloads: (json) => {
          return [json.recent_posts];
        },
        success: (json, response) => {
          dispatch({
            type: NEW_POST_HANDLE_LINK_URL_CHANGE_COMPLETE,
            urlInfo: json,
          })

          //Download and process any scraped image
          if (!state.post.image_url && json.photos && json.photos.length > 0) {
/*
            var photoUrl = json.photos[0];
            dispatch({
              type: NEW_POST_DOWNLOAD_SCRAPED_IMAGE,
              photoUrl: photoUrl,
            })

            alert('implement FileOperations workaround');
            FileOperations.downloadFile(photoUrl, (fileUrl => {
              ImageOperations.getImageDimensions(fileUrl, (resp) => {
                dispatch({
                  type: NEW_POST_DOWNLOAD_SCRAPED_IMAGE_COMPLETE,
                  fileUrl: fileUrl,
                  width: resp.width,
                  height: resp.height,
                })
              });
            }));
*/
          }
        },
        error: (err) => {
          dispatch({type: NEW_POST_HANDLE_LINK_URL_CHANGE_FAILED})
        }
      }
    })

  }
}

export function togglePostType(id) {
  return {
    type: NEW_POST_TOGGLE_POST_TYPE,
    typeId: id,
    receivedAt: Date.now()
  }
}


export function toggleSocialShare(network) {
  return {
    type: NEW_POST_TOGGLE_SOCIAL_SHARE,
    network: network,
    receivedAt: Date.now()
  }
}

function serializePost(post) {
  return {
    post: {
      title:                       post.title,
      description:                 post.description,
      offer:                       post.offer,
      post_ingredients_attributes: post.post_ingredients_attributes,
      location_id:                 post.location_id,
      is_private:                  post.is_private,
      user_level_tag_ids:          post.user_level_tag_ids,
      profile_type_ids:            post.profile_type_ids,
      post_type_ids:               post.post_type_ids,
      challenge_ids:               post.challenge_ids,
      link_url:                    post.link_url,
      start_date:                  post.start_date,
      start_time:                  post.start_time,
      end_date:                    post.end_date,
      end_time:                    post.end_time,
      photo:                       post.photo,
    }
  }
}

export function uploadImage(post, onBegin, onProgress) {
  return (dispatch, getState) => {
    dispatch({type: NEW_POST_UPLOADING_IMAGE})

    var pendingFileUpload = getState().base.pendingFileUpload
    if (post.image_url && !pendingFileUpload) {
      dispatch({
        type: NEW_POST_UPLOADING_IMAGE_URL,
        url: post.image_url,
        API_CALL: {
          url: '/posts/upload_photo',
          method: "POST",
          data: {
            url: post.image_url,
            path: "pending/",
          },
          success: (json, response) => {
            dispatch({
              type: NEW_POST_UPLOADING_IMAGE_COMPLETE,
              pendingPhotoFilename: json.filename,
            });

            var state = getState().base
            if (state.submitOnUploadComplete) {
              dispatch(savePost(post))
            }
          },
          error: (statusCode, error) => {
            dispatch({
              type: NEW_POST_UPLOADING_IMAGE_FAILED,
              message: "Failed to upload the image. Please check your connection and try again later.",
            });
          }
        }
      })
    } else {
      var uploadRequest = uploadImageFileToS3(pendingFileUpload, 'pending/').then((fileName, destinationPath) => {
        dispatch({
          type: NEW_POST_UPLOADING_IMAGE_COMPLETE,
          pendingPhotoFilename: fileName,
        })

        var state = getState().base
        if (state.submitOnUploadComplete) {
          dispatch(savePost(post))
        }
      }, (err) => {
        dispatch({
          type: NEW_POST_UPLOADING_IMAGE_FAILED,
          message: "Failed to upload the image. Please check your connection and try again later.",
        })
      })
    }

    // This exists because 'uploadRequest' contains a cancel method that needs to make it back up to the component
    onBegin && onBegin(uploadRequest)
  }
}

export function photoFileSelected(file) {
  return {
    type: NEW_POST_SIGN_UP_PHOTO_FILE_SELECTED,
    file: file,
  }
}

export function cancelUpload () {
  return {
    type: NEW_POST_UPLOADING_IMAGE_CANCELED
  }
}

export function savePost(post) {
  var serializedPost = serializePost(post)
  return (dispatch, getState) => {
    var state = getState().base
    if (state.pendingPhotoFilename && state.pendingPhotoFilename.length > 0) {
      serializedPost.post.pending_photo_filename = state.pendingPhotoFilename
    }
    dispatch({
      type: post.id ? NEW_POST_UPDATE_POST : NEW_POST_SUBMIT_POST,
      API_CALL: {
        url: post.id ? `/posts/${post.id}` : '/posts',
        dataType: 'json',
        method: post.id ? 'PUT' : 'POST',
        data: serializedPost,
        success: (json, response) => {
          console.log(json);
          if (!post.id) {
            /*
            dispatch(clearQuery())
            dispatch(resetFilters())
            dispatch(setActiveFeed('new'))
            dispatch(clearAllFeeds())
            dispatch(setEnsureNewPost('new', json.post.id))
            dispatch(fetchPage('new', true))

            setTimeout(() => {
              dispatch(switchTab('feed'))
            }, 200); //Cleans up some rendering mishaps [#352]
            */
          }

          var postUrl = '/posts/' + json.post.id
          var isPopup = extractQueryStringParam(location.href, 'popup')
          dispatch({
            type: post.id ? NEW_POST_UPDATE_POST_COMPLETE : NEW_POST_SUBMIT_POST_COMPLETE,
            newPost: json.post,
            isPopup: isPopup ? true : false,
            postUrl: postUrl,
          })

          if (!isPopup && !state.editing) {
            location.href = postUrl
          }

          if (state.editing) {
            location.reload()
          }

          //_doSocialSharing(dispatch, json.post, state.shareVia)
        },
        error: (err) => {
          dispatch({type: post.id ? NEW_POST_UPDATE_POST_FAILED : NEW_POST_SUBMIT_POST_FAILED})
        }
      }
    })
  }
}

export function uploadImageAndSavePost(post, onBegin, onProgress, onComplete) {
  return (dispatch, getState) => {
    var state = getState().base

    if (!state.uploading) {
      dispatch(savePost(post))
    } else {
      if (state.uploadingFailed) {
        dispatch(uploadImage(post, onBegin, onProgress, onComplete))
      }
      dispatch({
        type: NEW_POST_SUBMIT_POST_ON_UPLOAD_COMPLETE
      })
    }
  }
}

export function editPost(postId) {
  return dispatch => {
    dispatch({
      type: NEW_POST_POST_EDIT_POST,
      postId: postId,
      API_CALL: {
        url: '/posts/'+postId,
        success: json => {
          dispatch({
            type: NEW_POST_POST_EDIT_POST_READY,
            postId: postId,
          })
        },
        error: (statusCode, response) => {
          dispatch({
            type: NEW_POST_POST_EDIT_POST_FAILED,
          })
        }
      }
    })
  }
}


export function fetchFeaturedHashTag() {
  return dispatch => {
    dispatch({
      type: NEW_POST_GET_FEATURED_HASH_TAG,
      API_CALL: {
        url: '/featured_hash_tags',
        success: json => {
          dispatch({
            type: NEW_POST_GET_FEATURED_HASH_TAG_COMPLETE,
            tag: json.featured_hash_tags && json.featured_hash_tags.length > 0 ? json.featured_hash_tags[0] : null
          })
        },
        error: (statusCode, response) => {
          dispatch({
            type: NEW_POST_GET_FEATURED_HASH_TAG_FAILED,
          })
        }
      }
    })
  }
}

export function addFeaturedHashTagText(tag) {
  return {
    type: NEW_POST_ADD_FEATURED_HASH_TAG_TEXT,
    tag: tag,
  }
}

/*
function _doSocialSharing(dispatch, post, services) {
  var promise = Promise.resolve();
  var actions = [];
  if (services.twitter) {
    actions.push(() => { return new Promise((resolve, reject) => {
      dispatch(sharePost('twitter'));
      sharePostOnTwitter(post, true).then(resolve, resolve);
    })});
  }
  if (services.facebook) {
    actions.push(() => { return new Promise((resolve, reject) => {
      dispatch(sharePost('facebook'));
      sharePostOnFacebook(post, true).then(resolve, resolve);
    })});
  }
  if (services.instagram) {
    actions.push(() => { return new Promise((resolve, reject) => {
      dispatch(sharePost('instagram'));
      sharePostOnInstagram(post, true).then(resolve, resolve);
    })});
  }

  //Needed to let new post modal close or there are issues presenting sharing dialogs
  InteractionManager.runAfterInteractions(() => {
    //Dynamic promise chaining adapted from here.  Makes my head hurt a bit.  Notice that we resolve always above.
    //https://github.com/DanielHuisman/node-promise-chain/blob/master/index.js

    // I won't mess with it now, but I'm pretty sure this does the same by chaining the promises in one line:
    // actions.reduce((p, a) => p.then(a, a), Promise.resolve())

    for(var i = 0; i < actions.length; i++) {
      promise = promise.then(actions[i], actions[i]);
    }
  })
  return promise
}
*/
