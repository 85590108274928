'use strict'

import React from 'react';
import FSText from '../FSText';
import TextInput from '../TextInput';
import View from '../View';
import colors from '../../lib/colors';

export default class UrlEditor extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || '',
    }
  }

  handleChangeEvent = (val) => {
    this.setState({
      value: val,
    });
    this.props.onChange(val);
  };

  renderValidationInfo = () => {
    if (this.props.errors) {
      return this.props.errors.map(e => { return (
        <FSText key={e} style={styles.validationError}>
          {e}
        </FSText>
      )})
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <TextInput
          keyboardType='url'
          value={this.state.value}
          autoCapitalize={'none'}
          placeholder={this.props.placeholder || 'http://'}
          autoCorrect={false}
          returnKeyType={'done'}
          onSubmitEditing={(text) => this.props.onDone(text)}
          onChangeText={this.handleChangeEvent}
          multiline={false}
          autoFocus={true}
          maxLength={this.props.maxLength || 2083 }
          style={styles.input}/>

        {this.renderValidationInfo()}
      </View>
    )
  }
}

var styles = {
  container: {
    flex: 1,
    display: 'flex',
  },
  input: {
    flex: 1,
    height: 50,
    paddingLeft: 15, paddingRight: 15,
    fontSize: 16,
    borderWidth: 1,
    borderColor: colors.lightMediumGray,
  },
  validationError: {
    paddingTop: 5, paddingBottom: 5,
    paddingLeft: 15, paddingRight: 15,
    backgroundColor: colors.red,
    color: colors.white,
    marginBottom: 1,
  }
}
