'use strict';

import deepAssign from 'deep-assign'
import { defaultReducerMapping } from '../../lib/redux';
import dataStore from '../../lib/dataStore'
import initialState from './initialState'

var {
  NEW_POST_SETUP_CURRENT_USER,
} = require('../actions/');


var map = {}

map[NEW_POST_SETUP_CURRENT_USER] = (state, action) => {
  var user = window.FS_ENV.CURRENT_USER;
  dataStore.set('user', user.id, user);
  return { currentUserId: user.id }
}

export default defaultReducerMapping(initialState.app, map);
