import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import colors from '../../lib/colors';
import FS from '../../components/FS';

import {
  ONBOARDING_VIEW_KEY_SIGNUP,
  ONBOARDING_VIEW_KEY_SIGNIN,
} from '../actions/base'

import {
  previousScreen,
} from '../actions/signup'

class HeaderArea extends Component {

  renderSignUpHeader() {
    var dots = [];
    for(var i = 1 ; i <= 3 ; i++) {
      dots.push({idx: i, active: (this.props.signup.stepIndex === i) ? true : false})
    }

    return (
      <div>
        <div className="dots-wrap">
          <FS.Touchable onPress={() => this.props.dispatch(previousScreen())} className="back-button" style={{display: 'inline-block'}}>
            <FS.Icon name="left-arrow" size={12} />
          </FS.Touchable>

          {dots.map(d => (
            <div key={d.idx} className={"dot " + (d.active ? "dot-active" : "")}/>
          ))}
        </div>

        <div className="FoodstandAuth-heading-wrap">
          <div className="FoodstandAuth-heading">
            {this.props.signup.heading}
          </div>

          {this.props.signup.subheading ? (
            <div className="FoodstandAuth-subheading">
              {this.props.signup.subheading}
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  renderContents() {
    if (this.props.base.currentView == ONBOARDING_VIEW_KEY_SIGNUP) {
      return this.renderSignUpHeader();
    } else if (this.props.base.currentView == ONBOARDING_VIEW_KEY_SIGNIN) {
      return (
        <div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="FoodstandAuth-logo"/>
          <FS.Text className="FoodstandAuth-intro-msg">
            Join today to stay up on all things good food!
          </FS.Text>
        </div>
      )
    }
  }

  render() {
    return (
      <div className={"FoodstandAuth-main-header " + this.props.className}>
        {this.renderContents()}
      </div>
    )
  }

}

function mapStateToProps(state) {
  return Object.assign({}, state, { });
}
export default connect(mapStateToProps)(HeaderArea);
