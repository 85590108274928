'use strict'

import React, { Component, PropTypes } from "react"
import FSText from '../../components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from '../../components/FSIcon'
import { truncate } from '../../lib/utils/text'
import colors from '../../lib/colors'
import c from '../constants'
import View from '../../components/View'

import {
  removeAttribute,
  editAttribute,
} from '../actions'

export default class FormLabelOffer extends React.Component {

  removeAttribute = () => {
    this.props.dispatch(removeAttribute('offer'));
  };

  editAttribute = () => {
    this.props.dispatch(editAttribute('offer'));
  };

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)

    return (
      <FormLabelWithIcon
        name="offer"
        color={colorForCurrentUser}
        placeholder="What's the Offer?"
        value={this.props.post.offer}
        onPress={this.editAttribute}
        onRemove={this.removeAttribute}
      ></FormLabelWithIcon>
    )
  }
}

