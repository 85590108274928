'use strict';

import deepFreeze from 'deep-freeze'

var initialState = deepFreeze({
  app: {
    currentUserId: null,
  },
  base: {
    active: false,
    editing: false,
    initializing: true,
    currentlyEditingAttr: null, //'title',
    imageMetadata: {},
    linkLocationMetadata: {},
    uploading: false,
    uploadingFailed: false,
    pendingPhotoFilename: null,
    pendingFileUpload: null,
    submitOnUploadComplete: false,
    submitting: false,
    failed: false,
    featuredHashTagInitialied: null,
    featuredHashTag: null,
    uploadAttemptCount: 0,
    validatingRemotely: false,

    processingRemoteImage: false,

    errorMessage: null,
    errorMessageDismissed: true,

    submittedSuccessfully: false,
    isPopup: false,
    postUrl: null,

    existingLinkPostIds: [],
    post: {
      challenge_ids: [],
      user_level_tag_ids: [],
      profile_type_ids: [],
      post_type_ids: [],
      image_url: null,
      image_url_changed: false,
      link_url: null,
      title: '',
      description: '',
      offer: '',
      post_ingredients_attributes: [],
      start_date: null,
      end_date: null,
    },

    shareVia: {
      facebook: false,
      instagram: false,
      twitter: false
    }
  },

  location: {
    geocodeQueryLoading: false,
    geocodeQueryFailed: false,
    geocodeQuery: null,

    locationQueryLoading: false,
    locationQueryFailed: false,
    locationQuery: null,

    photoLocation: null,
    selectedLocationType: 'photo',
    geocodeFocused: false,

    selectedGeocodeName: null, // Just a placeholder until something meaningful shows up
    selectedGeocodeLatitude: null,
    selectedGeocodeLongitude: null,

    persistingLocationInProgress: false,
    persistingLocationFailed: false,

    selectedLocationId: null,
  },

  ingredients: {
    query: null,
    reviewing: false,
    selectedIngredientIds: [],
    visibleIngredientIds: [],
    focusedIngredientId: null,
    focusedIngredientLevelTagId: null,
    ingredientLevelTagTipVisible: false,
    ingredientLevelTagIds: {},
    newIngredientIds: [],
    ingredientsAddedAt: {},
  },

  tags: {
    loading: true,
    query: '',
    allTagIds: [],
    visibleChallengeIds: [],
    visibleProfileTypeIds: [],
    visibleUserLevelTagIds: [],
    selectedTagIds: [],
  },
})

export default initialState
