'use strict'

import React, { Component, PropTypes } from "react";
import View from './View'

export default class FadeOutView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <View
        style={Object.assign({}, {opacity: '0'}, this.props.style)}>
        {this.props.children}
      </View>
    )
  }
}
