'use strict'

import React, { Component, PropTypes } from "react"
import FSText from '../../components/FSText'
import FSTouchable from '../../components/FSTouchable'
import colors from '../../lib/colors'
import View from '../../components/View'

export default class GeocodeResult extends React.Component {

  render() {
    return (
      <View>
        <FSTouchable onPress={() => this.props.onSelect(this.props.data)} style={styles.result}>
          <FSText style={styles.resultText}>{this.props.data.display_name}</FSText>
        </FSTouchable>
        <View style={styles.hr}/>
      </View>
    )
  }

}

var styles = {
  result: {
    padding: 15,
  },
  hr: {
    height: 1,
    borderTopColor: colors.lightGray,
    borderTopWidth: 1,
  },
  resultText: {
    color: colors.darkGray,
  }
}
