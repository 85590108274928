'use strict'

import React from 'react';
import FS from '../../components/FS';
import Loading from '../../components/Loading'
import View from '../../components/View'
import DebouncedTextInput from '../../components/DebouncedTextInput';
import { connect } from 'react-redux'
import colors from '../../lib/colors';

var {
  autocompleteGeocode
} = require('../../actions/autocomplete')

class LocationEditor extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      query: this.props.value || '',
      error: false,
      fetching: false,
      results: [],
      selectedResult: null,
    }
  }

  componentWillMount(props) {
    this.handleQueryChange(this.props.value);
  }

  handleResultsSelect = (result) => {
    this.props.onChange(result);
    this.props.onDone(result);
  };

  handleQueryChange = (query) => {
    this.setState({
      query: query,
      fetching: false,
    });

    if (query && query.length > 0 && query != ' ') {
      this.setState({
        results: [],
        fetching: true,
      })
      this.props.dispatch(autocompleteGeocode(query, null, null, 'locationValueEditor', (results) => {
        this.setState({
          fetching: false,
          results: results,
        })
      }, () => {
        this.setState({
          fetching: false,
          error: true,
        })
      }));
    } else {
      this.setState({
        fetching: false,
        results: [],
      });
    }
  };

  renderResults = () => {
    return (
      <FS.ScrollView style={styles.resultsWrap}>
        {this.state.results.map((result, idx) => (
          <View key={result.display_name}>
            <FS.Touchable onPress={() => this.handleResultsSelect(result)} style={styles.result}>
              <FS.Text style={styles.resultText}>{result.display_name}</FS.Text>
            </FS.Touchable>
            <View style={styles.hr}/>
          </View>
        ))}
      </FS.ScrollView>
    )
  };

  render() {
    return (
      <View style={styles.container}>
        <DebouncedTextInput
          debounce={500}
          keyboardType='numbers-and-punctuation'
          initialValue={this.props.value}
          autoCapitalize={'none'}
          placeholder={this.props.placeholder || 'Search by city, zipcode or neighborhood'}
          autoCorrect={false}
          returnKeyType={'done'}
          onChangeTextDebounced={this.handleQueryChange}
          multiline={false}
          autoFocus={true}
          maxLength={this.props.maxLength || 40 }
          style={styles.input}/>

        {this.state.fetching ? (
          <View style={styles.loadingWrap}>
            <Loading/>
          </View>
        ) : null}
        {(this.state.query && this.state.query.length > 0 && this.state.results.length == 0 && !this.state.fetching) ? (
          <View style={styles.loadingWrap}>
            <FS.Text style={styles.noResults}>No matches, please try a different city, state or zipcode</FS.Text>
          </View>
        ) : null}
        {this.renderResults()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state, {
  })
}

export default connect(mapStateToProps)(LocationEditor);

var styles = {
  container: {
    flex: 1,
  },
  input: {
    height: 50,
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    borderWidth: 1,
    borderColor: colors.lightMediumGray,
  },
  resultsWrap: {
    flex: 1,
  },
  noResults: {
    paddingHorizontal: 25,
    textAlign: 'center',
  },
  validationError: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    backgroundColor: colors.red,
    color: colors.white,
    marginBottom: 1,
  },
  result: {
    padding: 15,
  },
  loadingWrap: {
    paddingVertical: 12,
  },
  hr: {
    height: 1,
    borderTopColor: colors.lightGray,
    borderTopWidth: 1,
  },
  resultText: {
    color: colors.darkGray,
  },
}
