'use strict'

import React, { Component, PropTypes } from 'react';
import moment from 'moment';

import DatePicker from '../DatePicker';
import View from '../View';
import FSScrollView from '../FSScrollView';
import FS from '../FS';
import { formatDate, roundedDateTime } from '../../lib/utils/date'

import colors from '../../lib/colors'

export default class DateTimeEditor extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      precision: props.precision || 'date',
      value: props.value || (roundedDateTime(new Date(), 15)),
      timezone: moment.tz.guess(),
    }
  }

  componentDidMount () {
    this.handleChangeEvent(this.state.value);
  }

  setPrecision = (p) => {
    this.setState({
      precision: p,
    });
    setTimeout(() => {
      this.handleChangeEvent(this.state.value || (new Date()));
    });
  };

  handleChangeEvent = (val) => {
    this.setState({
      value: val,
    })

    if (this.props.onChange) {
      this.props.onChange({
        'value': val,
        'precision': this.state.precision
      });
    }
  };

  render() {
    var d = this.state.value ? new Date(this.state.value) : new Date();
    var currentValueDisplay = formatDate(d, this.state.precision).replace('@', '\n@');

    return (
      <FSScrollView style={styles.container} contentContainerStyle={this.contentContainerStyle}>

        <FS.Text style={styles.currentValue}>
          {currentValueDisplay}
        </FS.Text>

        {this.state.precision === 'date' ? (
          <DatePicker
            minimumDate={this.props.mininumDate}
            maximumDate={this.props.maximumDate}
            onDateChange={(val) => this.handleChangeEvent(val)}
            date={d}
            mode={'date'}
          />
        ) : (
          <DatePicker
            minimumDate={this.props.mininumDate}
            maximumDate={this.props.maximumDate}
            onDateChange={(val) => this.handleChangeEvent(val)}
            date={d}
            mode={this.state.precision}
            minuteInterval={15}
          />
        )}

        {this.state.precision === 'date' ? (
          <FS.Button onPress={() => this.setPrecision('datetime')} style={styles.showTimeWrapWrap}>
            <View style={styles.showTimeWrap}>
              <FS.Icon name="clock" size={14} style={styles.showTimeIcon} />
              <FS.Text style={styles.showTime}>Add specific time</FS.Text>
            </View>
          </FS.Button>
        ) : (
          <FS.Button onPress={() => this.setPrecision('date')} style={styles.hideTimeWrap}>
            <FS.Text style={styles.removeTime}>Remove specific time</FS.Text>
          </FS.Button>
        )}

      </FSScrollView>
    )
  }
}

var styles = {
  container: {
    flex: 1,
    paddingTop: 25,
    paddingBottom: 25,
  },
  contentContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  currentValue: {
    margin: 15,
    color: colors.gray,
    fontSize: 18,
    textAlign: 'center',
    padding: 10,
  },
  showTimeWrapWrap: {
    backgroundColor: colors.white,
    marginTop: 15,
  },
  showTimeWrap: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    paddingTop: 10, paddingBottom: 10,
    paddingLeft: 12, paddingRight: 12,
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: colors.gray,
    backgroundColor: colors.lightMediumGray,
  },
  showTime: {
    fontSize: 14,
    color: colors.veryDarkGray,
  },
  showTimeIcon: {
    marginRight: 8,
    color: colors.veryDarkGray,
  },
  hideTimeWrap: {
    backgroundColor: colors.white,
  },
  hideTime: {
    fontSize: 12,
    color: colors.lightMediumGray,
    padding: 12,
    borderRadius: 5,
  },
}
