'use strict'

import React from 'react';
import Dropzone from 'react-dropzone';
import FSModal from '../../components/FSModal';
import FSText from '../../components/FSText';
import FSTouchable from '../../components/FSTouchable';
import FSImage from '../../components/FSImage';
import UserAvatar from '../../components/UserAvatar';
import View from '../../components/View';

import colors from '../../lib/colors';

export default class AvatarEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: Object.assign({}, props.user),
      edited: false,
      cameraOpen: false,
    }
  }

  componentWillMount() {
  }

  selectImage = () => {
    //ImagePicker.show(this.refs.image, this.handleChangeEvent, this.props.onCancel, /*this.openCamera */);
  };

  selectPhoto = (imageURL, metadata) => {
    //this.closeCamera();
    //this.handleChangeEvent(imageURL);
  };

  onOpenClick = () => {
    this.refs.dropzone.open();
  };

  onDrop = (files) => {
    this.resetPreview();

    //this.props.dispatch(photoFileSelected(files[0]));

    this.setState({
      files: files,
      previewInitialized: false,
    });
    this.handleChangeEvent(files[files.length - 1]);

    setTimeout(() => {
      var el = this.resetPreview();
      var w = el.width();
      var h = el.height();
      var size = 220;

      //crop out the middle as a square
      var width = (w > h) ? (w / h) * size : size;
      var height = (h > w) ? (h / w) * size : size;
      var leftOffset = -1 * (width - size) / 2;
      var topOffset = -1 * (height - size) / 2;
      el.css({width: width , height: height})
      el.css('margin-left', leftOffset);
      el.css('margin-top', topOffset);
      el.show();
    }, 250);
  };

  resetPreview() {
    var el = $('.FoodstandEditProfile-photo-preview');
    el.css({width: 'auto', height: 'auto', 'margin-left': 0, 'margin-right': 0}).hide();
    return el;
  }

  handleChangeEvent = (photoFile) => {
    this.setState({
      edited: true,
    });

    if (this.props.onChange) {
      this.props.onChange(photoFile);
    }
  };

  renderEditedButtons = () => {
    if (this.state.edited) {
      return (
        <View>
          <FSTouchable
            onPress={() => this.props.onDone(this.state.files[0])}
            style={Object.assign({}, styles.chooseButtonWrap, {backgroundColor: colors.forUser(this.state.user)})}
          >
            <FSText weight='bold' style={styles.chooseButton}>Yep, That's me!</FSText>
          </FSTouchable>
        </View>
      )
    }
  };

  renderPreviewPhoto = () => {
    if (this.state.files) {
      return (
        <div style={styles.previewWrapper}>
          {this.state.files.map((file) => (
            <img key="img" src={file.preview} className="FoodstandEditProfile-photo-preview" style={Object.assign({}, styles.preview, this.state.previewInitialized ? {} : {display: 'none'})} />)
          )}
        </div>
      )
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <Dropzone ref="dropzone" onDrop={this.onDrop} accept="image/*" multiple={false}
          style={Object.assign({}, styles.dropzone, this.state.files ? styles.dropzoneWithImage : {})}
          activeStyle={styles.dropzoneActive}
          >
            {this.state.files ? (
              this.renderPreviewPhoto()
            ) : (
              <UserAvatar user={this.state.user} size={220} style={styles.avatar}/>
            )}
        </Dropzone>

        {this.renderEditedButtons()}

        <FSTouchable onPress={this.onOpenClick} style={styles.chooseAnotherWrapper}>
          <FSText style={styles.chooseAnother}>Choose Another</FSText>
        </FSTouchable>

      </View>
    )
  }
}

var styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    margin: 15,
  },
  previewWrapper: {
    margin: 20,
    borderRadius: '50%',
    overflow: 'hidden',
    width: 220,
    height: 220,
  },
  chooseButtonWrap: {
    borderRadius: 5,
    padding: 15,
  },
  chooseButton: {
    color: colors.white,
  },
  chooseAnotherWrapper: {
    padding: 15,
  },
  chooseAnother: {
    color: colors.gray,
  }
}
