'use strict'
import React from 'react'
import fixtures from './fixtures';
import Post from '../models/Post';
import Challenge from '../models/Challenge';
import User from '../models/User';

var {
  AsyncStorage
} = React

import sign from 'signum'

/**
 * A home for all entity data.  Simple hash by type and id for quick access
 * Globally available to the whole app for fetching.  API should really be the
 * only one setting things...
 **/

// Compares by reference and bails out if inequality encountered
function sortedArrayEqual(a, b) {
  for(var i = a.length - 1; i>=0; i--) {
    if (a[i] !== b[i]) return false
  }
  return true
}

// Returns -1, 0, or 1 for sorting by id:
function objectComparator(a, b) {
  return sign(a.id - b.id)
}

function singularize(key) {
  if (key === 'featured_searches') {
    return 'featured_search'
  } else {
    return key.substr(0, key.length - 1);
  }
}

function pluralize(key) {
  if (key === 'featured_search') {
    return 'featured_searches'
  } else {
    return key + 's'
  }
}

var _entities = {
};

const dataStore = {
  OBJECT_TYPES: {
    'post': {
      model: Post,
      attributes: {
        created_at: Date,
        start_date: Date,
        start_time: Date,
        end_date: Date,
        end_time: Date,
      }
    },
    'user': {
      model: User,
      attributes: {
        created_at: Date,
      }
    },
    'comment': {
      attributes: {
        created_at: Date,
      }
    },
    'location': {},
    'location_source': {},
    'mention': {},
    'remote_message': {
      attributes: {
        created_at: Date,
      }
    },
    'direct_message': {
      attributes: {
        created_at: Date,
      }
    },
    'conversation': {
      attributes: {
        updated_at: Date,
      }
    },
    'notification': {},
    'activity_log': {
      attributes: {
        created_at: Date,
      }
    },
    'profile_type': {},
    'ingredient': {},
    'post_ingredient': {},
    'ingredient_level_tag': {},
    'post_type': {},
    'challenge': {
      model: Challenge,
    },
    'challenge_category': {},
    'post_level_tag': {},
    'user_level_tag': {},
    'featured_hash_tag': {},
    'featured_search': {},
  },
  get: function(objectType, objectId) {
    if (!this.isValidObjectType(objectType)) {
      //fsConsole.warn('dataStore get attempted with unknown object type', objectType);
      return;
    }

    var objects = _entities[objectType];
    if (objects) {
      return objects[objectId];
    }
    return null;
  },
  /* Augment this object with a prototype if a model was provided in the type
   * definition above. Otherwise just a pass-thru
   */
  augment: function(objectType, object) {
    var type = this.OBJECT_TYPES[objectType]
    if (type.model) {
      // Augment this object with fancy functions
      Object.defineProperties(object, type.model)
    }
    return object;
  },
  set: function(objectType, objectId, object) {
    if (!this.isValidObjectType(objectType)) {
      //fsConsole.warn('dataStore set attempted with unknown object type', objectType);
      return;
    }

    //First of this type, initialize
    if (!_entities[objectType]) {
      _entities[objectType] = {};
    }

    //Set it
    _entities[objectType][objectId] = this.augment(objectType, object);
  },
  delete: function(objectType, objectId) {
    if (!this.isValidObjectType(objectType)) {
      //fsConsole.warn('dataStore delete attempted with unknown object type', objectType);
      return;
    }

    if (!!_entities[objectType]) {
      delete _entities[objectType][objectId]
    }
  },
  getMany: function(objectType, objectIds) {
    if (this.isValidObjectType(objectType)) {
      var e = _entities[objectType]
      return objectIds.map(id => e[id])
    } else {
      //fsConsole.warn('dataStore getMany attempted with unknown object type', objectType, objectIds);
      return;
    }
  },
  getAllIds: function(objectType) {
    return (Object.keys(_entities[objectType] || {}) || []).map(Number)
  },
  getAll: function(objectType) {
    var objects = _entities[objectType];
    if (objects) {
      return objects;
    }
    return null;
  },
  setAttributes: function(objectType, objectId, changes) {
    if (!this.isValidObjectType(objectType)) {
      //fsConsole.warn('dataStore set attempted with unknown object type', objectType);
      return;
    }

    var obj = this.get(objectType, objectId);
    if (!obj) {
      //fsConsole.warn('dataStore set attribute attempted with missing object', objectType, objectId);
      return;
    }
    var updatedObj = Object.assign({}, obj, changes);
    this.set(objectType, objectId, updatedObj);
    this._updateObjectRelationships();
    return updatedObj;
  },
  isValidObjectType: function(objectType) {
    return this.OBJECT_TYPES[objectType] !== undefined;
  },
  setPayloadObject(objectType, id, obj) {
    //Before we dump this object into _entities, see if we need to
    //do any datatype manipulation (e.g. date strings from json into date objects)
    var attrs = this.OBJECT_TYPES[objectType].attributes;
    for(var attr in attrs) {
      if (attrs.hasOwnProperty(attr) && obj[attr]) {
        var dataType = attrs[attr];
        if (dataType === Date) {
          obj[attr] = new Date(obj[attr]);
        }
      }
    }
    this.set(objectType, id, obj);
  },
  wipeAndPushPayload: function(payload, shouldUpdateRelationships = true) {
    for (var objectType in payload) {
      if (payload.hasOwnProperty(objectType)) {
        delete _entities[singularize(objectType)]
      }
    }
    this.pushPayload(payload, shouldUpdateRelationships)
  },
  pushPayload: function(payload, shouldUpdateRelationships = true) {
    //Look through the raw data for known object types and update them
    //in the store if we can.
    for(var key in payload) {
      if ( payload.hasOwnProperty(key) && payload[key] instanceof Array) {
        var objectType = singularize(key)

        if (this.isValidObjectType(objectType)) {
          for(var i = 0 ; i < payload[key].length ; i++) {
            var obj = payload[key][i];
            if (obj && obj.id) {
              this.setPayloadObject(objectType, obj.id, obj);
            }
          }
        }
      } else if (payload[key] instanceof Object) {
        //Second we check the singular case, "comment"... Happens usually when a new thing is created

        var objectType = key;
        var obj = payload[objectType];
        if (obj && obj.id && this.isValidObjectType(objectType)) {
          this.setPayloadObject(objectType, obj.id, obj);
        }
      }
    }
    if (shouldUpdateRelationships) {
      this._updateObjectRelationships();
    }
  },
  pushPayloads: function(payloads) {
    for(var i = 0 ; i < payloads.length ; i++) {
      this.pushPayload(payloads[i], false);
    }
    this._updateObjectRelationships();
  },
  _updateObjectRelationships: function() {
    //Loop through and create references on each object to all relevant other object_ids
    //for easy access by consumers of this store's data.  e.g. if user_id is present, add
    //a reference .user = this.get('user', user_id)
    for(var objectType in _entities) {
      if (_entities.hasOwnProperty(objectType)) {
        var objects = _entities[objectType];
        for(var id in objects) {
          if (objects.hasOwnProperty(id)) {
            var object = objects[id];
            for(var attribute in object) {
              if (object.hasOwnProperty(attribute)) {
                if (attribute.indexOf('_id') == attribute.length - 3) {
                  //e.g. user_id => user
                  var relatedObjectType = attribute.replace('_id', '');
                  if (this.isValidObjectType(relatedObjectType)) {
                    var relatedObject = this.get(relatedObjectType, object[attribute]);
                    object[relatedObjectType] = relatedObject;
                  }
                } else if (attribute.indexOf('_ids') == attribute.length - 4
                           && object[attribute] instanceof Array) {
                  //e.g. comment_ids => [comment, comment]
                  var relatedObjectType = attribute.replace('_ids', '');
                  if (this.isValidObjectType(relatedObjectType)) {

                    // Get the objects from ids:
                    var related = this.getMany(relatedObjectType, object[attribute]).filter(x => !!x)

                    // Determine if we should create a new relation reference. Criteria are:
                    //   1. Was currentObjects blank? If so, then we need to set it no matter what.
                    //   2. Did the length change? This prevents sorting for obvious changes.
                    //   3. Compare object references. This means first sorting by id and then
                    //      comparing the references one by one.
                    var currentObjects = object[pluralize(relatedObjectType)]
                    if (currentObjects === undefined ||
                        related.length !== currentObjects.length ||
                        !sortedArrayEqual(related.sort(objectComparator), currentObjects.sort(objectComparator)))
                    {
                      object[pluralize(relatedObjectType)] = related;
                      //console.log('object relationships updated',objectType, attribute)
                    }
                    //else {
                      //console.log('object relationships NOT updated',objectType, attribute)
                    //}
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  getAllData: () => _entities
}

//Load fixtures
dataStore.pushPayload(fixtures);

/*
window.AsyncStorage = AsyncStorage

AsyncStorage.getItem('bootstrap-data').then(data => {
  if (!data) {
    // On first initial app load, there will be no data, so trigger this immediately and force update:
    bootstrap(null, true)
  } else {
    var parsedData = JSON.parse(data)
    if (Object.keys(parsedData).length === 0) {
      bootstrap(null, true)
    } else {
      // Otherwise, take data from local storage and push it:
      dataStore.pushPayload(parsedData)
    }
  }
}).catch(() => {
  // If error, wipe bootstrap-data dn bootstrap-revision from local
  // storage to decrease the change that garbage data will make its
  // way into local storage and crash the app repeatedly:
  AsyncStorage.removeItem('bootstrap-data')
  AsyncStorage.removeItem('bootstrap-revision')
  dataStore.pushPayload(fixtures);
})
*/

window.dataStore = dataStore

//Done!
export default dataStore;
