import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class FSText extends Component {

  componentDidMount() {
  }

  render() {
    return (
      <span style={Object.assign({}, {display: 'block'}, this.props.style)} className={this.props.className}>
        {this.props.children}
      </span>
    );
  }
}

export default FSText;

var styles = {
  heading: {
  }
}
