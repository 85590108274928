export const NEW_POST_INGREDIENT_QUERY = 'NEW_POST_INGREDIENT_QUERY'
export const NEW_POST_INGREDIENT_QUERY_COMPLETE = 'NEW_POST_INGREDIENT_QUERY_COMPLETE'
export const NEW_POST_INGREDIENT_CLEAR_QUERY = 'NEW_POST_INGREDIENT_CLEAR_QUERY'
export const NEW_POST_INGREDIENT_ADD = 'NEW_POST_INGREDIENT_ADD'
export const NEW_POST_INGREDIENT_REMOVE = 'NEW_POST_INGREDIENT_REMOVE'
export const NEW_POST_INGREDIENT_LOADING = 'NEW_POST_INGREDIENT_LOADING'
export const NEW_POST_INGREDIENT_FOCUS = 'NEW_POST_INGREDIENT_FOCUS'
export const NEW_POST_INGREDIENT_BLUR = 'NEW_POST_INGREDIENT_BLUR'
export const NEW_POST_INGREDIENT_TOGGLE_TAG = 'NEW_POST_INGREDIENT_TOGGLE_TAG'
export const NEW_POST_INGREDIENT_TOGGLE_INGREDIENT_LEVEL_TAG = 'NEW_POST_INGREDIENT_TOGGLE_INGREDIENT_LEVEL_TAG'
export const NEW_POST_INGREDIENT_CREATE = 'NEW_POST_INGREDIENT_CREATE'
export const NEW_POST_INGREDIENT_CREATE_COMPLETE = 'NEW_POST_INGREDIENT_CREATE_COMPLETE'
export const NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_DETAILS = 'NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_DETAILS'
export const NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_DETAILS = 'NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_DETAILS'
export const NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_TIP = 'NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_TIP'
export const NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_TIP = 'NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_TIP'


export function updateQuery(q) {
  if( q && q.length > 0 ) {
    return dispatch => {
      dispatch({
        type: NEW_POST_INGREDIENT_QUERY,
        query: q,
        receivedAt: Date.now(),
        API_CALL: {
          url: '/ingredients',
          method: 'GET',
          data: {
            name: q
          },
          success: (json, response) => {
            dispatch({
              type: NEW_POST_INGREDIENT_QUERY_COMPLETE,
              ingredientIds: json.ingredients.map(i => i.id),
              receivedAt: Date.now(),
            })
          }
        }
      })
    }
  } else {
    return {
      receivedAt: Date.now(),
      query: q,
      type: NEW_POST_INGREDIENT_CLEAR_QUERY,
    }
  }
}

export function createIngredient(name, callback) {
  return dispatch => {
    dispatch({
      type: NEW_POST_INGREDIENT_CREATE,
      receivedAt: Date.now(),
      API_CALL: {
        url: '/ingredients',
        method: 'POST',
        data: { 'ingredient[name]': name },
        success: (json, response) => {
          dispatch({
            type: NEW_POST_INGREDIENT_CREATE_COMPLETE,
            ingredient: json.ingredient,
            receivedAt: Date.now(),
          })
          if(callback) callback(json.ingredient)
        }
      }
    })
  }
}

export function addIngredient(id) {
  return {
    receivedAt: Date.now(),
    ingredientId: id,
    type: NEW_POST_INGREDIENT_ADD,
  }
}

export function removeIngredient(id) {
  return {
    receivedAt: Date.now(),
    ingredientId: id,
    type: NEW_POST_INGREDIENT_REMOVE,
  }
}

export function clearQuery() {
  return {
    receivedAt: Date.now(),
    type: NEW_POST_INGREDIENT_CLEAR_QUERY,
  }
}

export function focusIngredient(id) {
  return {
    receivedAt: Date.now(),
    ingredientId: id,
    type: NEW_POST_INGREDIENT_FOCUS,
  }
}

export function blurIngredient() {
  return {
    receivedAt: Date.now(),
    type: NEW_POST_INGREDIENT_BLUR,
  }
}

export function toggleIngredientLevelTag( ingredientId, ingredientLevelTagId ) {
  return {
    ingredientId: ingredientId,
    ingredientLevelTagId: ingredientLevelTagId,
    receivedAt: Date.now(),
    type: NEW_POST_INGREDIENT_TOGGLE_INGREDIENT_LEVEL_TAG,
  }
}

export function viewIngredientLevelTagDetails( ingredientLevelTagId ) {
  return {
    ingredientLevelTagId: ingredientLevelTagId,
    receivedAt: Date.now(),
    type: NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_DETAILS,
  }
}

export function dismissIngredientLevelTagDetails() {
  return {
    receivedAt: Date.now(),
    type: NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_DETAILS,
  }
}

export function viewIngredientLevelTagTip() {
  return {
    receivedAt: Date.now(),
    type: NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_TIP,
  }
}

export function setLoading() {
  return {
    loading: true,
    receivedAt: Date.now(),
    type: NEW_POST_INGREDIENT_LOADING,
  }
}

export function dismissIngredientLevelTagTip() {
  return {
    receivedAt: Date.now(),
    type: NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_TIP,
  }
}
