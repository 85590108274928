'use strict'

import dataStore from '../lib/dataStore'
import signum from 'signum'

class PostType {
}

// This is just a number by which types present are ranked:
PostType.rank = {
  Event: 70,
  Offer: 60,
  Question: 50,
  Tip: 30,
  Info: 20
}

PostType.comparator = function postTypeComparator (a, b) {
  return signum(PostType.rank[b] - PostType.rank[a])
}

PostType.iconNames = {
  Event: 'calendar',
  Info: 'type-info',
  Offer: 'offer',
  Question: 'type-question',
  Tip: 'type-tip',
  'Just for Fun': 'type-fun',
  Recipe: 'list-with-bg',
}

// Return this as a list since it's not inconceivable that the order
// could matter at some point in the future:
PostType.all = function() {
  var types = dataStore.getAll('post_type')
  var results = []
  for(let key in types) {
    if( types.hasOwnProperty(key) ) {
      results.push(types[key])
    }
  }
  results.sort((a, b) => a.order - b.order)
  return results
}

PostType.getIdForName = function(name) {
  var types = dataStore.getAll('post_type');
  for(let key in types) {
    if (types[key].name === name) {
      return types[key].id;
    }
  }
  return null;
}

export default PostType
