'use strict'

import React, { Component, PropTypes } from "react";
import FSText from '../../components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from '../../components/FSIcon'
import colors from '../../lib/colors'
import c from '../constants'
import View from '../../components/View'

import {
  removeAttribute,
  editAttribute,
} from '../actions'

export default class FormLabelLocation extends React.Component {

  get locationName() {
    if( ! this.props.post.location_id ) return null;
    return dataStore.get('location', this.props.post.location_id).name;
  }

  removeLocation = () => {
    this.props.dispatch(removeAttribute('location_id'));
  };

  editAttribute = () => {
    this.props.dispatch(editAttribute('location_id'));
  };

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)

    return (
      <FormLabelWithIcon
        name="location"
        color={colorForCurrentUser}
        placeholder="Location"
        value={this.locationName}
        onPress={this.editAttribute}
        onRemove={this.removeLocation}
      ></FormLabelWithIcon>
    )
  }
}
