'use strict'

import fsConsole from '../utils/fs-console'

var Geolocation = {
  getCurrentPosition: function(success, error, options) {
    // Fake the device location:
    //
    // This exists for testing because I get either get device location failed or
    // device location denied when testing in the simulator, which seem suspicious.
    //
    var delay = window.fakeLocationDelay === undefined ? 100 : window.fakeLocationDelay
    //fsConsole.log('%c faking geolocation response with ' + delay + 'ms delay.', 'background: #fca')
    //fsConsole.log('%c Enable/disable with FS_FAKE_DEVICE_LOCATION env var. Set window.(fakeLatitude|fakeLongitude|fakeLocationDelay) to control this behavior.', 'background: #fca')

    setTimeout(() => {
      var coords = {
        latitude: window.fakeLatitude || 40.7033160,
        longitude: window.fakeLongitude || -73.9881450
      }
      success({coords: coords})
    }, delay)
  }
}

export default Geolocation
