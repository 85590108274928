'use strict'

import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux'
import colors from '../../../lib/colors'

import HeaderArea from '../HeaderArea'
import ProfileType from '../../../models/ProfileType';
import {
  changeProfileType,
  updateOnboardingStepIndex,
  nextScreen,
  previousScreen,
  setHeading,
} from '../../actions/signup'

import { downloadPhoto } from '../../actions/socialAuth'

import NextButton from './NextButton'

import FSView from '../../../components/FSView'
import FSText from '../../../components/FSText'
import FSTouchable from '../../../components/FSTouchable'
import FSIcon from '../../../components/FSIcon'
import FSScrollView from '../../../components/FSScrollView'
//import ProfileTypeEditor from '../../../components/ValueEditor/ProfileTypeEditor'

class StepProfileType extends FSView {

  getMyUrl() {
    return 'onboarding/signup/profile-type'
  }

  constructor(props) {
    super(props);
  }

  componentDidFocus() {
    this.props.dispatch(updateOnboardingStepIndex(1));
  }

  componentDidMount(props) {
    super.componentDidMount(props)
    this.props.dispatch(changeProfileType(this.props.profileTypeId));
    this.props.dispatch(setHeading('Select one that represents you'));

    //If they just authenticated via social network, start download of their profile photo now
    if(this.props.remotePhotoTemp) {
      this.props.dispatch(downloadPhoto(this.props.remotePhotoTemp));
    }
  }

  handleChangeEvent = (profileTypeId) => {
    this.props.dispatch(changeProfileType(profileTypeId));
  };

  iconForProfileType = (profileType) => {
    return "profiletype-" + profileType.string_id.replace(/_/g, '');
  };

  handleNextPress = () => {
    this.props.dispatch(nextScreen())
  };

  renderSelected = (pt) => {
    if (pt.id == this.props.profileTypeId) {
      return (
        <div style={Object.assign({}, styles.selectedIconWrapper, {backgroundColor: colors.forProfileType(pt)})}>
          <FSIcon name="checkmark" size={14}
            style={styles.selectedIcon}
            color={colors.white}
          />
        </div>
      )
    } else {
      return ( <div style={styles.selectedIconSpacer}/> )
    }
  };

  renderProfileType = (pt) => {
    var selected = (pt.id === this.props.profileTypeId)
    return (
      <FSTouchable
        style={Object.assign({},
          styles.profileType,
          selected ? styles.profileTypeSelected : null,
          selected ? {backgroundColor: colors.forProfileType(pt)} : null,
        )}
        key={pt.id}
        onPress={() => this.handleChangeEvent(pt.id)}>

        {this.renderSelected(pt)}

        <FSIcon name={this.iconForProfileType(pt)} size={51} style={styles.profileIcon} color={selected ? colors.white : colors.forProfileType(pt)}/>

        <div style={styles.profileTypeInfo}>
          <FSText style={Object.assign({}, styles.name, selected ? styles.nameSelected : {}, selected ? {} : {color: colors.forProfileType(pt)})}>{pt.label}</FSText>
          <FSText style={Object.assign({}, styles.description, selected ? styles.descriptionSelected : {})}>{pt.description}</FSText>
        </div>

      </FSTouchable>
    )
  };

  renderProfileTypes() {
    var types = ProfileType.all();

    return (
      <div style={Object.assign({}, styles.container, this.props.style)}>
        {types.map(pt => {
          return this.renderProfileType(pt);
        })}
      </div>
    )
  }

  render() {
    return (
      <div>
        <FSScrollView style={styles.container} className="FoodstandAuth-contents">

          {<HeaderArea className={'inner-header'}/>}
          {this.renderProfileTypes()}

        </FSScrollView>
        <NextButton
          onPress={this.handleNextPress}
          active={(this.props.profileTypeId !== null)}/>
      </div>
    )
  }

}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.signup, { });
}

export default connect(mapStateToProps)(StepProfileType);

var styles = {
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  profileType: {
    display: 'table',
    padding: 18,
    borderWidth: 1,
    borderBottomWidth: 0.5,
    borderColor: colors.lightGray,
    textAlign: 'center',
  },
  profileTypeSelected: {
    borderColor: 'transparent',
  },
  profileIcon: {
    marginRight: 18,
  },
  selectedIconSpacer: {
    display: 'table-cell',
    width: 25,
    marginLeft: -10,
  },
  selectedIconWrapper: {
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingRight: 5,
    paddingLeft: -10,
    width: 20,
    height: 20,
    borderRadius: 10,
    alignItems: 'center',
    textAlign: 'center',
  },
  selectedIcon: {
  },
  profileTypeInfo: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'left',
  },
  name: {
    display: 'block',
    marginBottom: 5,
    fontWeight: '500',
  },
  nameSelected: {
    color: colors.white,
  },
  description: {
    flex: 1,
    fontSize: 14,
    textAlign: 'left',
  },
  descriptionSelected: {
    color: colors.white,
  },
  tipWrap: {

  },
  tipText: {
    color: colors.white,
  },

  info: {
    padding: 25,
    backgroundColor: colors.white,
  },
  heading: {
    display: 'block',
    color: colors.gray,
    textAlign: 'center',
    fontSize: 16,
    padding: 5,
  },
  subheading: {
    color: colors.white,
    textAlign: 'center',
    marginTop: 15,
    fontSize: 14,
  },
  profileTypeEditor: {
    backgroundColor: colors.veryLightGray,
  }
}
