import dataStore from "../../lib/dataStore"
import uploadImageFileToS3 from "../../lib/utils/uploadImageFileToS3"

export const PROFILE_EDIT_ATTRIBUTE = "PROFILE_EDIT_ATTRIBUTE"
export const PROFILE_CANCEL_EDIT_ATTRIBUTE = "PROFILE_CANCEL_EDIT_ATTRIBUTE"
export const PROFILE_UPDATE = "PROFILE_UPDATE"
export const PROFILE_UPDATE_COMPLETE = "PROFILE_UPDATE_COMPLETE"
export const PROFILE_UPDATE_FAILED = "PROFILE_UPDATE_FAILED"
export const PROFILE_UPDATE_AVATAR = "PROFILE_UPDATE_AVATAR"
export const PROFILE_UPDATE_AVATAR_COMPLETE = "PROFILE_UPDATE_AVATAR_COMPLETE"
export const PROFILE_UPDATE_AVATAR_FAILED = "PROFILE_UPDATE_AVATAR_FAILED"
export const PROFILE_LINK_OAUTH_CREDENTIALS = "PROFILE_LINK_OAUTH_CREDENTIALS"
export const PROFILE_UNLINK_OAUTH_CREDENTIALS = "PROFILE_UNLINK_OAUTH_CREDENTIALS"
export const EDIT_PROFILE_SETUP_CURRENT_USER = "EDIT_PROFILE_SETUP_CURRENT_USER"

function _serializeUser(user) {
  return {
    bio: user.bio,
    ios_device_token: user.ios_device_token,
    android_device_token: user.android_device_token,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    password: user.password,
    invitation_code: user.invitation_code,
    profile_is_indexable: user.profile_is_indexable,
    profile_type_id: user.profile_type_id,
    username: user.username,
    website: user.website,
    postal_code: user.postal_code,
    home_latitude: user.home_latitude,
    home_longitude: user.home_longitude,
    custom_feed_initialized: user.custom_feed_initialized,
    user_level_tag_ids: user.user_level_tag_ids,
    facebook_access_token: user.facebook_access_token,
    facebook_user_id: user.facebook_user_id,
    foursquare_access_token: user.foursquare_access_token,
    foursquare_user_id: user.foursquare_user_id,
    instagram_access_token: user.instagram_access_token,
    instagram_user_id: user.instagram_user_id,
  }
}

export function setupCurrentUser() {
  //Current user info is provided by rails app via "ENV" var
  return dispatch => {
    dispatch({
      type: EDIT_PROFILE_SETUP_CURRENT_USER,
    })
  }
}

export function editAttribute(user, attribute) {
  return {
    type: PROFILE_EDIT_ATTRIBUTE,
    user: user,
    attribute: attribute,
  }
}

export function cancelEditingAttribute() {
  return {
    type: PROFILE_CANCEL_EDIT_ATTRIBUTE,
    errors: null,
  }
}

export function updateUserProfilePhoto(user, photoFileUrl) {
  return (dispatch, getState) => {
    var state = getState()
    if (state.profile.savingAvatar) {
      return
    }

    dispatch({
      type: PROFILE_UPDATE_AVATAR,
    })

    uploadImageFileToS3(photoFileUrl, "pending/")
      .then((fileName, destinationPath) => {
        dispatch({
          type: PROFILE_UPDATE_AVATAR,
          fileName: fileName,
          API_CALL: {
            url: "/avatar_upload",
            method: "POST",
            data: {
              pending_photo_filename: fileName,
            },
            success: (json, response) => {
              dispatch({
                type: PROFILE_UPDATE_AVATAR_COMPLETE,
                updatedUser: json.user,
              })
            },
            error: (statusCode, error) => {
              dispatch({
                type: PROFILE_UPDATE_AVATAR_FAILED,
                error: error,
              })
            },
          },
        })
      })
      .catch(error => {
        dispatch({
          type: PROFILE_UPDATE_AVATAR_FAILED,
          error: error,
        })
      })
  }
}

export function updateUser(user, attributesToUpdate, callback) {
  return (dispatch, getState) => {
    var state = getState()
    if (state.profile.isSaving) {
      return
    }

    //Photo is special case
    if (attributesToUpdate.avatar) {
      dispatch(updateUserProfilePhoto(user, attributesToUpdate["avatar"]))
      return
    }

    if (attributesToUpdate.home_location) {
      attributesToUpdate.home_latitude = attributesToUpdate.home_location.latitude
      attributesToUpdate.home_longitude = attributesToUpdate.home_location.longitude
    }

    if (attributesToUpdate.full_name) {
      attributesToUpdate.first_name = attributesToUpdate.full_name.first_name
      attributesToUpdate.last_name = attributesToUpdate.full_name.last_name
    }

    //Mark it as saving so UI can show loading state if it wants to
    var userToSerialize = Object.assign({}, user, attributesToUpdate)

    //Send the action so display can update throughout app, and then actually save it via api
    dispatch({
      type: PROFILE_UPDATE,
      attributesToUpdate: attributesToUpdate,
      API_CALL: {
        url: "/users/" + user.id,
        method: "PUT",
        dataType: "json",
        data: {
          user: _serializeUser(userToSerialize),
        },
        success: resp => {
          dispatch({ type: PROFILE_UPDATE_COMPLETE })
          callback && callback(null, resp)
        },
        error: (statusCode, json, resp) => {
          // Don't fail catastrophically if json is not defined because another error was encountered
          // (in rendering, perhaps), but also don't return something falsey so that the callback
          // registered as having failed:
          var errors = (json || {}).errors || {}

          dispatch({
            type: PROFILE_UPDATE_FAILED,
            errors: errors,
          })
          callback && callback(errors)
        },
      },
    })
  }
}

export function unlinkOauthCredentials(service, callback = null) {
  return {
    type: PROFILE_UNLINK_OAUTH_CREDENTIALS,
    service: service,
  }
}

export function linkOauthCredentials(service, callback = null) {
  return {
    type: PROFILE_LINK_OAUTH_CREDENTIALS,
    service: service,
  }
}
