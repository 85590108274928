'use strict'

import React, { Component, PropTypes } from "react"
import FSText from '../../components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from '../../components/FSIcon'
import FSTouchable from '../../components/FSTouchable'
import { truncate } from '../../lib/utils/text'
import colors from '../../lib/colors'
import c from '../constants'
import View from '../../components/View'

import {
  removeAttribute,
  editAttribute,
} from '../actions'

export default class FormLabelIngredients extends React.Component {

  get ingredientList() {
    if( ! this.props.post ) return [];
    var i = dataStore.getMany('ingredient', this.props.post.post_ingredients_attributes.map(i=>i.ingredient_id)).map(i => i.name)
    return i
  }

  get ingredientCount() {
    return this.props.post.post_ingredients_attributes.length
  }

  removeAttribute = () => {
    this.props.dispatch(removeAttribute('ingredients'));
  };

  editAttribute = () => {
    this.props.dispatch(editAttribute('ingredients', this.props.post));
  };

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)
    var hasIngredients = this.ingredientCount > 0
    var value = hasIngredients ? this.ingredientList.join(', ') : null;

    return (
      <FormLabelWithIcon
        name="list-with-bg"
        color={colorForCurrentUser}
        replaceIconWith={this.ingredientCount}
        placeholder={'Ingredients'}
        value={value}
        onPress={this.editAttribute}
        onRemove={this.removeAttribute}
      ></FormLabelWithIcon>
    )
  }
}

var styles = {
  wrapper: {
    marginTop: c.fieldSpacing,
    display: "flex", flexDirection: "row",
    paddingTop: c.fieldSpacing * 0.75, paddingBottom: c.fieldSpacing * 0.75,
    paddingLeft: c.iconPadding, paddingRight: c.iconPadding,
    marginBottom: c.gutterWidth,
    backgroundColor: colors.white,
    justifyContent: 'center',
  },
  label: {
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray,
  },
  value: {
    textAlign: 'right',
    paddingLeft: 10,
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray,
    flex: 1,
  },
}
