export const AUTOCOMPLETE_COMPLETE = 'AUTOCOMPLETE_COMPLETE';
export const AUTOCOMPLETE_CLEAR_RESULTS = 'AUTOCOMPLETE_CLEAR_RESULTS';

export const AUTOCOMPLETE_USER = 'AUTOCOMPLETE_USER';
export const AUTOCOMPLETE_HASHTAG = 'AUTOCOMPLETE_HASHTAG';
export const AUTOCOMPLETE_LOCATION = 'AUTOCOMPLETE_LOCATION';
export const AUTOCOMPLETE_GEOCODE = 'AUTOCOMPLETE_GEOCODE';

// QueryId is just an identifier so that separate views can conceivably maintain
// their own autocomplete results without resorting to some global autocomplete.
export function autocompleteUser(query, queryId) {
  return dispatch => {
    dispatch({
      type: AUTOCOMPLETE_USER,
      username: query,
      API_CALL: {
        url: '/users/search',
        data: {
          query: query
        },
        success: (resp => {
          dispatch({
            type: AUTOCOMPLETE_COMPLETE,
            kind: 'user',
            queryId: queryId,
            receivedAt: Date.now(),
            results: resp.matches.map(m=>m.id),
          })
        }),
        getDataPayloads: (json) => [json.matching_user_data],
      }
    });
  }
}

// QueryId is just an identifier so that separate views can conceivably maintain
// their own autocomplete results without resorting to some global autocomplete.
export function autocompleteHashtag(query, queryId) {
  return dispatch => {
    dispatch({
      type: AUTOCOMPLETE_HASHTAG,
      hashtag: query,
      API_CALL: {
        url: '/hashtags/autocomplete',
        data: {
          q: query
        },
        success: (resp => {
          dispatch({
            type: AUTOCOMPLETE_COMPLETE,
            kind: 'hashtag',
            queryId: queryId,
            receivedAt: Date.now(),
            results: resp.hashtags
          })
        }),
      }
    });
  }
}

export function clearAutocomplete(kind, queryId) {
  return {
    type: AUTOCOMPLETE_CLEAR_RESULTS,
    kind: kind,
    receivedAt: Date.now(),
    queryId: queryId,
  }
}


// TODO: Honestly, everything below this line only complicates things and should disappear.
// Users, maybe, but for everything else here there's absolutely no reason this should go
// through some central autocomplete whatnot since the results don't have an id and so just
// end up on the component state anyway as transient data. In other words, redux does
// absolutely nothing for this.
//
// If you read this and feel ambitious, feel free to move this to where it's needed instead.
//
export function autocompleteLocation(query, lat, lng, queryId, onSuccess, onFailure) {
  return dispatch => {
    dispatch({
      type: AUTOCOMPLETE_LOCATION,
      query: query,
      latitude: lat,
      longitude: lng,
      API_CALL: {
        url: '/locations',
        data: {
          query: query,
          lat: lat,
          lng: lng,
        },
        success: (resp => {
          dispatch({
            type: AUTOCOMPLETE_COMPLETE,
            kind: 'location',
            queryId: queryId,
            receivedAt: Date.now(),
            results: resp.locations,
          })
          if( onSuccess ) onSuccess(resp.locations)
        }),
        error: () => {
          if( onFailure ) onFailure()
        }
      }
    })
  }
}

export function autocompleteGeocode(query, lat, lng, queryId, onSuccess, onFailure) {
  return dispatch => {
    dispatch({
      type: AUTOCOMPLETE_GEOCODE,
      query: query,
      lat: lat,
      lng: lng,
      API_CALL: {
        url: '/geocode',
        data: {
          query: query,
          lat: lat,
          lng: lng,
        },
        success: (resp) => {
          dispatch({
            type: AUTOCOMPLETE_COMPLETE,
            kind: 'geocode',
            queryId: queryId,
            receivedAt: Date.now(),
            results: resp.matches,
          })
          if( onSuccess ) onSuccess(resp.matches)
        },
        error: () => {
          if( onFailure ) onFailure()
        }
      }
    })
  }
}

