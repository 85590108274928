import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class FSView extends Component {

  componentDidMount() {
  }

  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

export default FSView;

var styles = {
  heading: {
  }
}
