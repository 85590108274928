import FSText from './FSText'
import FSView from './FSView'
import FSButton from './FSButton'
import FSImage from './FSImage'
import FSIcon from './FSIcon'
import FSTouchable from './FSTouchable'
import FSScrollView from './FSScrollView'
import FSModal from './FSModal'

export default {
  Text: FSText,
  View: FSView,
  Button: FSButton,
  Image: FSImage,
  Icon: FSIcon,
  Touchable: FSTouchable,
  ScrollView: FSScrollView,
  Modal: FSModal,
}
