'use strict'

export default {
  track: (actionName, options = {}) => {
    if (typeof(window.ga) !== 'undefined') {
      ga('send', {
        hitType: 'event',
        eventAction: actionName,
        eventCategory: options.category,
        eventLabel: options.label,
        eventValue: options.value,
      });
    } else {
      console.log("Analytics Event Track: GA not present", actionName, options);
    }
  },
  screen: (url, options = {}) => {
    if (typeof(window.ga) !== 'undefined') {
      ga('send', {
        hitType: 'pageview',
        page: url
      });
    } else {
      console.log("Analytics Screen: GA not present", url, options);
    }
  },
}
