import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors';

class Navigator extends Component {

  state = {
    routes: [],
  };

  push = (route) => {
console.log('in navigator push');
    var routes = this.state.routes;
    routes.push(route);
    this.setState({
      routes: routes,
    });
  };

  pop = () => {
    var routes = this.state.routes;
    this.setState({
      routes: routes.slice(0, routes.length - 1),
    });
console.log('in navigator pop', routes);
  };

  renderScene = (route, nav) => {
    var Component = route.component;

    /*
    var topHeight = this.navbarHeight(route)

    var bottomHeight = TAB_BAR_HEIGHT;
    if (this.props.hasTabs === false || (Platform.OS === 'android' && this.props.keyboardVisible)) {
      bottomHeight = 0;
    }
    <View style={{height: topHeight}}></View>
    */

    return (
      <div style={styles.container}>
        <Component
          title={route.title}
          navigator={nav}
          {...route.passProps}
        />
      </div>
    );
  };

  componentDidMount() {
    if (this.props.initialRoute) {
      this.state.routes.push(this.props.initialRoute);
    }
  }

  render() {
    if (this.state.routes.length === 0) {
      return (<span/>);
    }

    var currentRoute = this.state.routes[this.state.routes.length - 1];

    return (
      <div style={Object.assign({}, styles.container, this.props.style)}>
        {this.state.routes.map((route, idx) => (
          <div key={idx} style={(route !== currentRoute) ? {display: 'none'} : null}>
            {this.renderScene(route, this)}
          </div>
        ))}
      </div>
    );
  }
}

export default Navigator;

var styles = {
  container: {
//position: 'absolute',
//   top: 0,
//   left: 0,
//   bottom: 0,
//   right: 0,
    color: colors.gray,
    backgroundColor: colors.white,
    textAlign: 'center',
//    padding: 20,
  },
}
