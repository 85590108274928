'use strict';

import deepAssign from 'deep-assign'
import { defaultReducerMapping } from '../../lib/redux';
import dataStore from '../../lib/dataStore'

var {
  EDIT_PROFILE_SETUP_CURRENT_USER,
} = require('../actions/');


var map = {}

map[EDIT_PROFILE_SETUP_CURRENT_USER] = (state, action) => {
  var user = window.FS_ENV.CURRENT_USER;
  var pt = dataStore.get('profile_type', user.profile_type_id);
  user.profile_type = pt;
  dataStore.set('user', user.id, user);
  return { currentUserId: user.id }
}

export default defaultReducerMapping({ currentUserId: null}, map);
