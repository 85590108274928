'use strict'

import uuid from './uuid';
import addUrlQueryParams from './addUrlQueryParams'
import config from '../../config';

/**
 * Uploads an image file to S3 using /sign_img on the rails server
 * to ask for a signed image url
 **/
export default function uploadImageFileToS3(imageInfo, destinationPath = "pending/", onProgress) {
  var progressInterval
  return new Promise((resolve, reject) => {
    var nameParts = imageInfo.name.split(".");
    var fileName = uuid() + '.' + (nameParts[nameParts.length - 1]);
    var fileType = imageInfo.type;

    //We upload the file directly to s3
    var url = config.env.FS_API_HOST + '/sign_img';
    url = addUrlQueryParams(url, {
      'name': destinationPath + fileName,
      'type': fileType
    })

    $.ajax(url).then(signedInfo => {

      $.ajax({
        url: signedInfo.put_url,
        type: 'PUT',
        data: imageInfo,
        cache: false,
        processData: false, // Don't process the files
        contentType: fileType, // Set content type to false as jQuery will tell the server its a query string request
        success: function(data, textStatus, jqXHR) {
          if(typeof data.error === 'undefined') {
            resolve(fileName, destinationPath)
            // Success so call function to process the form
          } else {
            debugger;
            // Handle errors here
            console.log('ERRORS: ' + data.error);
            reject();
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {
          debugger;
          // Handle errors here
          console.log('ERRORS: ' + textStatus);
          reject();
          // STOP LOADING SPINNER
        }
      });

      /*
      progressInterval = setInterval(() => {
        FileOperations.progress(signedInfo.put_url, (err,data) => {
          if (err) return
          onProgress && onProgress(data)
        })
      },100)
      */

    }, (error) => {
      reject(error);
    })
  });
}
