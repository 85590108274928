'use strict';

import { defaultReducerMapping } from '../../lib/redux';
import initialState from './initialState'

var {
  NEW_POST_LOCATION_LOCATION_LOADING,
  NEW_POST_LOCATION_GEOCODE_LOADING,
  NEW_POST_LOCATION_LOCATION_LOADED,
  NEW_POST_LOCATION_GEOCODE_LOADED,
  NEW_POST_LOCATION_LOCATION_FAILED,
  NEW_POST_LOCATION_GEOCODE_FAILED,

  NEW_POST_LOCATION_GEOCODE_FOCUS,
  NEW_POST_LOCATION_GEOCODE_BLUR,
  NEW_POST_LOCATION_SELECT_GEOCODE_RESULT,

  NEW_POST_LOCATION_PERSIST_RESULT,
  NEW_POST_LOCATION_PERSIST_RESULT_FAILED,
  NEW_POST_LOCATION_PERSIST_RESULT_COMPLETE,
  NEW_POST_LOCATION_SELECT_LOCATION,

} = require('../actions/location')

var map = {}

map[NEW_POST_LOCATION_LOCATION_LOADING] = (state, action) => ({ locationQueryLoading: true })
map[NEW_POST_LOCATION_GEOCODE_LOADING] = (state, action) => ({ geocodeQueryLoading: true })
map[NEW_POST_LOCATION_LOCATION_LOADED] = (state, action) => ({ locationQueryLoading: false, locationQueryFailed: false })
map[NEW_POST_LOCATION_GEOCODE_LOADED] = (state, action) => ({ geocodeQueryLoading: false, geocodeQueryFailed: false })
map[NEW_POST_LOCATION_LOCATION_FAILED] = (state, action) => ({ locationQueryLoading: false, locationQueryFailed: true })
map[NEW_POST_LOCATION_GEOCODE_FAILED] = (state, action) => ({ geocodeQueryLoading: false, geocodeQueryFailed: true })

map[NEW_POST_LOCATION_GEOCODE_FOCUS] = (state, action) => ({ geocodeFocused: true })
map[NEW_POST_LOCATION_GEOCODE_BLUR] = (state, action) => ({ geocodeFocused: false })

map[NEW_POST_LOCATION_SELECT_GEOCODE_RESULT] = (state,action) => {
  return {
    selectedGeocodeName: action.name,
    selectedGeocodeLatitude: action.latitude,
    selectedGeocodeLongitude: action.longitude,
    geocodeFocused: false,
  }
}

map[NEW_POST_LOCATION_PERSIST_RESULT] = (state, action) => ({
  persistingLocationInProgress: true,
  persistingLocationFailed: false,
})

map[NEW_POST_LOCATION_PERSIST_RESULT_COMPLETE] = (state, action) => ({
  persistingLocationInProgress: false,
  persistingLocationFailed: false,
})

map[NEW_POST_LOCATION_PERSIST_RESULT_FAILED] = (state, action) => ({
  persistingLocationInProgress: false,
  persistingLocationFailed: true,
})

map[NEW_POST_LOCATION_SELECT_LOCATION] = (state, action) => ({
  selectedLocationId: action.locationId
})

export default defaultReducerMapping(initialState.location, map);
