import config from '../config';

export default function initializeFacebookSDK(callback = null) {
  if (typeof(FB) === 'undefined') {
    window.fbAsyncInit = function() {
      FB.init({
        appId      : window.FS_ENV.FACEBOOK_APP_KEY, //comes from /_javascripts.html.haml
        xfbml      : false,
        version    : 'v2.5'
      });
      //console.log('FB SDK initialized');
      if (callback) { callback(); }
    };

    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "//connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
   } else {
      if (callback) { callback(); }
   }
 }
 window.FS_initializeFacebookSDK = initializeFacebookSDK;
