import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux'
import colors from '../../lib/colors'

import FS from '../../components/FS'
import LoadingOverlay from '../../components/LoadingOverlay'
import DebouncedTextInput from '../../components/DebouncedTextInput'

//import LoadingOverlay from 'a../../components/LoadingOverlay'

import { checkCredentials } from '../actions/signin'

import {
  switchView,
  ONBOARDING_VIEW_KEY_SPLASHSCREEN,
  ONBOARDING_VIEW_KEY_FORGOT_PASSWORD
} from '../actions/base'

import {
  authenticateWithInstagram,
  authenticateWithFoursquare,
  authenticateWithFacebook,
} from '../actions/socialAuth'

const USERNAME_INPUT = 'USERNAME_INPUT';
const PASSWORD_INPUT = 'PASSWORD_INPUT';

class SignIn extends Component {

  state = {
    refreshingPage: false,
  }

  getMyUrl() {
    return 'onboarding/signin'
  }

  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username || '',
      password: this.props.password || '',
    };
  }

  componentDidMount() {
    this.refs[USERNAME_INPUT].refs.input.focus();
  }

  onPressSignInButton = () => {
    if (this.state.username && this.state.password && !this.props.checkingSignIn) {
      this.props.dispatch(checkCredentials(
        this.state.username,
        this.state.password,
        () => {
          this.setState({
            refreshingPage: true
          });
          if (this.props.buddyRef) {
            location.href = "accept-challenge-buddy/success?ref="+this.props.buddyRef
          } else {
            location.reload();
          }
        },
      ));
    }
  };

  renderError(errorText) {
    return (
      <div style={Object.assign({}, styles.inputWrapper, styles.errorWrap)}>
        <FS.Text style={styles.error}>{errorText}</FS.Text>
      </div>
    )
  }

  renderSocialIcon(service) {
    var color = colors[service];

    return (
      <FS.Touchable
        onPress={() => this.doSocialSignUp(service)}
        style={Object.assign({}, styles.socialButton, {backgroundColor: color})}>

        <FS.Icon
          name={service}
          size={20}
          color={colors.white}
        />

      </FS.Touchable>
    )
  }

  renderSocialIcons() {
    return (
      <div style={styles.socialAuth}>
        {this.renderSocialIcon('foursquare')}
        {this.renderSocialIcon('facebook')}
        {this.renderSocialIcon('instagram')}
      </div>
    )
  }

  doSocialSignUp(service) {
    service = service.toLowerCase();
    if (service === 'instagram') {
      this.props.dispatch(authenticateWithInstagram());
    } else if (service === 'foursquare') {
      this.props.dispatch(authenticateWithFoursquare());
    } else if (service === 'facebook') {
      this.props.dispatch(authenticateWithFacebook());
    }
  };

  goToSignUp = () => {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SPLASHSCREEN));
  };

  doFocus = (refName) => {
  };

  renderForm() {
    return (
      <div style={styles.formWrapper}>

        <div style={styles.inputWrapper}>
          <DebouncedTextInput
            ref={USERNAME_INPUT}
            value={this.state.username}
            autoCapitalize="none"
            placeholder="Username"
            autoCorrect={false}
            onChangeText={(text) => {
              this.setState({username: text});
            }}
            onEnter={this.onPressSignInButton}
            onFocus={() => this.doFocus(USERNAME_INPUT)}
            onSubmitEditing={this.onPressSignInButton}
            clearButtonMode="while-editing"
            returnKeyType="go"
            textAlign="center"
            style={styles.input}
          />
        </div>

        <div style={styles.inputWrapper}>
          <DebouncedTextInput
            ref={PASSWORD_INPUT}
            value={this.state.password}
            password={true}
            autoCapitalize="none"
            placeholder="Password"
            autoCorrect={false}
            onFocus={() => this.doFocus(PASSWORD_INPUT)}
            onChangeText={(text) => {
              this.setState({password: text});
            }}
            onEnter={this.onPressSignInButton}
            onSubmitEditing={this.onPressSignInButton}
            clearButtonMode="while-editing"
            returnKeyType="go"
            textAlign="center"
            style={styles.input}
          />
        </div>

        {this.props.invalidCredentials ? this.renderError("Username and password do not match") : null}

        <div style={styles.inputWrapper}>
          <FS.Touchable onPress={this.onPressSignInButton} style={styles.signInButtonWrap}>
            <FS.Text style={styles.signInButton}>Sign In</FS.Text>
          </FS.Touchable>
        </div>

        <div style={styles.inputWrapper}>
          <FS.Touchable onPress={() => {this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_FORGOT_PASSWORD))}} style={styles.forgotWrap}>
            <FS.Text style={styles.forgotText}>Forgot your password?</FS.Text>
          </FS.Touchable>
        </div>

      </div>
    )
  }

  render() {
    return (
      <div>
        <FS.ScrollView
          ref='formScrollView'
          className="FoodstandAuth-contents"
          innerRef={(c) => {this._formWrapper = c}}
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
          keyboardShouldPersistTaps={true}>

          <FS.Text style={styles.heading} weight='bold'>Sign In</FS.Text>

          {this.renderSocialIcons()}
          {this.renderForm()}


        </FS.ScrollView>
        <FS.Touchable style={styles.goToSignUp} onPress={this.goToSignUp}>
          <FS.Text style={styles.goToSignUpText}>
            {'I actually don\'t have an account'}
          </FS.Text>
        </FS.Touchable>
        <LoadingOverlay isLoading={this.props.checkingSignIn || this.state.refreshingPage}/>
      </div>
    );
  }

}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.signin, {
    buddyRef: state.base.buddyRef,
  });
}

export default connect(mapStateToProps)(SignIn);

var styles = {
  container: {
    flex: 1,
    width: 260,
    margin: '0 auto',
    boxSizing: 'border-box',
  },
  contentContainer: {
    justifyContent: 'center',
  },
  heading: {
    display: 'block',
    paddingTop: 20,
    fontSize: 16,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  formWrapper: {
    padding: '20px 0',
    marginTop: 0,
    marginBottom: 10,
  },
  inputWrapper: {
  },
  input: {
    display: 'block',
    height: 40,
    boxSizing: 'border-box',
    lineHeight: '40px',
    border: 0,
    padding: '0 25px',
    backgroundColor: colors.lightGray,
    borderRadius: 20,
    color: colors.veryDarkGray,
    fontSize: 14,
    textAlign: 'left',
    alignSelf: 'center',
    width: '100%',
    outline: 0,
    marginBottom: 12,
  },
  signInButtonWrap: {
    borderRadius: 20,
    backgroundColor: colors.red,
    paddingLeft: 20,
    paddingRight: 20,
    flex: 1,
    height: 40,
    lineHeight: '40px',
    width: '100%',
    boxSizing: 'border-box',
  },
  signInButton: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    alignSelf: 'center',
  },
  forgotWrap: {
    flex: 1,
    dispay: 'block',
    marginTop: 15,
  },
  forgotText: {
    alignSelf: 'center',
    color: colors.gray,
    fontSize: 14,
  },
  errorWrap: {
    backgroundColor: colors.red,
    marginLeft: 10, marginRight: 10,
    padding: 5,
    borderRadius: 10,
    marginBottom: 15,
  },
  error: {
    color: colors.white,
    flex: 1,
    textAlign: 'center',
    fontSize: 12,
  },
  socialAuth: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  socialButton: {
    flex: 1,
    alignItems: 'center',
    padding: 10,
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 20,
  },
  iconWrap: {
    width: 25,
    alignItems: 'center',
    margin: '0 10px',
  },
  socialButtonText: {
    color: colors.white,
    fontSize: 14,
    fontWeight: '800',
  },
  goToSignUp: {
    display: 'block',
    padding: 13,
    borderWidth: 0,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightMediumGray,
    textAlign: 'center',
    color: colors.gray,
  }
}
