export const ONBOARDING_SIGN_UP_VALIDATE_USERNAME = "ONBOARDING_SIGN_UP_VALIDATE_USERNAME"
export const ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE = "ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE"
export const ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE = "ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE"
export const ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX = "ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX"
export const ONBOARDING_SIGN_UP_PHOTO_FILE_SELECTED = "ONBOARDING_SIGN_UP_PHOTO_FILE_SELECTED"
export const ONBOARDING_SIGN_UP_UPDATE_VALUE = "ONBOARDING_SIGN_UP_UPDATE_VALUE"
export const ONBOARDING_SIGN_UP_LOCATE_ZIP = "ONBOARDING_SIGN_UP_LOCATE_ZIP"
export const ONBOARDING_SIGN_UP_LOCATE_ZIP_COMPLETE = "ONBOARDING_SIGN_UP_LOCATE_ZIP_COMPLETE"
export const ONBOARDING_SIGN_UP_LOCATE_ZIP_FAILED = "ONBOARDING_SIGN_UP_LOCATE_ZIP_FAILED"
export const ONBOARDING_SIGN_UP_UPLOAD_PHOTO = "ONBOARDING_SIGN_UP_UPLOAD_PHOTO"
export const ONBOARDING_SIGN_UP_UPLOAD_PHOTO_FAILED = "ONBOARDING_SIGN_UP_UPLOAD_PHOTO_FAILED"
export const ONBOARDING_SIGN_UP_UPLOAD_PHOTO_COMPLETE = "ONBOARDING_SIGN_UP_UPLOAD_PHOTO_COMPLETE"
export const ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS = "ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS"
export const ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE =
  "ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE"

export const ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG = "ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG"
export const ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID = "ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID"

export const ONBOARDING_SIGN_UP_VALIDATE_USER = "ONBOARDING_SIGN_UP_VALIDATE_USER"
export const ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE = "ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE"
export const ONBOARDING_SIGN_UP_CREATE_USER = "ONBOARDING_SIGN_UP_CREATE_USER"
export const ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE = "ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE"
export const ONBOARDING_SIGN_UP_NEXT_SCREEN = "ONBOARDING_SIGN_UP_NEXT_SCREEN"
export const ONBOARDING_SIGN_UP_PREVIOUS_SCREEN = "ONBOARDING_SIGN_UP_PREVIOUS_SCREEN"

export const ONBOARDING_SIGN_UP_SET_HEADING = "ONBOARDING_SIGN_UP_SET_HEADING"

export const PROFILE_UPDATE_AVATAR = "PROFILE_UPDATE_AVATAR"
export const PROFILE_UPDATE_AVATAR_COMPLETE = "PROFILE_UPDATE_AVATAR_COMPLETE"
export const PROFILE_UPDATE_AVATAR_FAILED = "PROFILE_UPDATE_AVATAR_FAILED"

export const MIN_USERNAME_LENGTH = 3
export const MIN_PASSWORD_LENGTH = 8
export const MIN_ZIP_LENGTH = 5

import { checkCredentials } from "./signin"
import { switchView, ONBOARDING_VIEW_KEY_SPLASHSCREEN } from "./base"
import uploadImageFileToS3 from "../../lib/utils/uploadImageFileToS3"
//import { fetchGeo } from 'app/actions/deviceLocation'

var _serializeUser = state => {
  return {
    profile_type_id: state.profileTypeId,
    email: state.email,
    password: state.password,
    username: state.username,
    user_level_tag_ids: state.userLevelTagIds,
    postal_code: state.postal_code,
    home_latitude: state.home_latitude,
    home_longitude: state.home_longitude,
    instagram_access_token: state.instagramAccessToken,
    instagram_user_id: state.instagramUserId,
    foursquare_access_token: state.foursquareAccessToken,
    foursquare_user_id: state.foursquareUserId,
    facebook_access_token: state.facebookAccessToken,
    facebook_user_id: state.facebookUserId,
    referring_user_id: state.referringUserId,
    referring_source: state.referringSource ? `${state.referringSource} (${state.referringQuery})` : null,
  }
}

export function updateUserProfilePhoto(user, photoFileInfo, photoUrl, callback = null, errorCallback = null) {
  return dispatch => {
    var doAvatarRequest = (fileName, fileUrl = null) => {
      dispatch({
        type: PROFILE_UPDATE_AVATAR,
        receivedAt: Date.now(),
        fileName: fileName,
        API_CALL: {
          url: "/avatar_upload",
          method: "POST",
          data: {
            pending_photo_filename: fileName,
            pending_photo_url: fileUrl,
          },
          success: (json, response) => {
            dispatch({
              type: PROFILE_UPDATE_AVATAR_COMPLETE,
              updatedUser: json.user,
            })
            if (callback) {
              callback()
            }
          },
          error: (statusCode, error) => {
            dispatch({
              type: PROFILE_UPDATE_AVATAR_FAILED,
              error: error,
            })
          },
        },
      })
    }

    if (photoFileInfo) {
      uploadImageFileToS3(photoFileInfo, "pending/")
        .then((fileName, destinationPath) => {
          doAvatarRequest(fileName)
        })
        .catch(error => {
          dispatch({
            type: PROFILE_UPDATE_AVATAR_FAILED,
            error: error,
          })
          if (errorCallback) {
            errorCallback()
          }
        })
    } else if (photoUrl) {
      doAvatarRequest(null, photoUrl)
    }
  }
}

export function setHeading(heading, subheading = null) {
  return {
    type: ONBOARDING_SIGN_UP_SET_HEADING,
    heading: heading,
    subheading: subheading,
  }
}

export function nextScreen() {
  return {
    type: ONBOARDING_SIGN_UP_NEXT_SCREEN,
  }
}

export function previousScreen() {
  return (dispatch, getState) => {
    var state = getState()

    dispatch({
      type: ONBOARDING_SIGN_UP_PREVIOUS_SCREEN,
    })

    if (state.signup.stepIndex === 1) {
      dispatch(switchView(ONBOARDING_VIEW_KEY_SPLASHSCREEN))
    }
  }
}

export function validateUser() {
  return (dispatch, getState) => {
    var state = getState().signup

    dispatch({
      type: ONBOARDING_SIGN_UP_VALIDATE_USER,
      API_CALL: {
        method: "POST",
        url: "/signup/validate",
        dataType: "json",
        data: {
          user: _serializeUser(state),
        },
        success: json => {
          dispatch({
            type: ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE,
          })
        },
        error: (statusCode, json) => {
          dispatch({
            type: ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE,
            errors: json && json.errors,
          })
        },
      },
    })
  }
}

export function createUser() {
  return (dispatch, getState) => {
    var state = getState().signup

    dispatch({
      type: ONBOARDING_SIGN_UP_CREATE_USER,
      API_CALL: {
        method: "POST",
        url: "/users",
        dataType: "json",
        data: {
          user: _serializeUser(state),
        },
        success: json => {
          dispatch({
            type: ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE,
            errors: json.errors,
          })

          //Whooo, user got created, now Sign them in!
          dispatch(
            checkCredentials(
              state.username,
              state.password,
              () => {
                //Now that they're authenticated, do some more
                if (state.photoTemp || state.remotePhotoTemp) {
                  //Kick off photo upload, will happen in background while their feed loads
                  dispatch(
                    updateUserProfilePhoto(
                      json.user,
                      state.photoTemp,
                      state.remotePhotoTemp,
                      () => {
                        location.reload()
                      },
                      () => {
                        //Even if upload failed for some reason, reload.. They're signed in at least.
                        alert(
                          "Something went wrong while uploading your profile photo, please try that again later.  We will now sign you in."
                        )
                        location.reload()
                      }
                    )
                  )
                } else {
                  setTimeout(() => {
                    location.reload()
                  }, 500) //give analytics half a second to register the goal
                }
              },
              true
            )
          )
        },
        error: (statusCode, json) => {
          location.reload()
        },
      },
    })
  }
}

export function validateUsername(username) {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SIGN_UP_VALIDATE_USERNAME,
      username: username,
      API_CALL: {
        url: "/validate/username",
        data: {
          username: username,
        },
        success: json => {
          dispatch({
            type: ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE,
            valid: json.valid,
          })
        },
        error: (statusCode, json) => {
          dispatch({
            type: ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE,
            errors: json && json.errors,
          })
        },
      },
    })
  }
}

export function updateValue(key, value) {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SIGN_UP_UPDATE_VALUE,
      key: key,
      value: value,
    })

    if (key === "username" && value.length >= MIN_USERNAME_LENGTH) {
      dispatch(validateUsername(value))
    }
  }
}

export function photoFileSelected(file) {
  return {
    type: ONBOARDING_SIGN_UP_PHOTO_FILE_SELECTED,
    file: file,
  }
}

export function changeProfileType(profileTypeId) {
  return {
    type: ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE,
    profileTypeId: profileTypeId,
  }
}

export function updateOnboardingStepIndex(index) {
  return {
    type: ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX,
    index: index,
  }
}

export function toggleUserLevelTag(tagId) {
  return {
    type: ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG,
    tagId: tagId,
  }
}

export function locateZip() {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SIGN_UP_LOCATE_ZIP,
    })

    /*
    dispatch(fetchGeo((err, coords) => {
      if (err) {
        dispatch({
          type: ONBOARDING_SIGN_UP_LOCATE_ZIP_FAILED,
        });
        return;
      }

      dispatch({
        type: ONBOARDING_SIGN_UP_LOCATE_ZIP,
        API_CALL: {
          method: 'GET',
          url: '/geocode/reverse',
          dataType: 'json',
          data: {latitude: coords.latitude, longitude: coords.longitude},
          success: (json) => {
            dispatch({
              type: ONBOARDING_SIGN_UP_LOCATE_ZIP_COMPLETE,
              postalcode: json.postalcode,
              latitude: coords.latitude,
              longitude: coords.longitude,
            });
          },
          error: (statusCode, json) => {
            dispatch({
              type: ONBOARDING_SIGN_UP_LOCATE_ZIP_FAILED,
            });
          },
        }
      });
    }, () => {
      dispatch({
        type: ONBOARDING_SIGN_UP_LOCATE_ZIP_FAILED,
      });
    }))
    */
  }
}

export function fetchNextMembersResults(query) {
  return (dispatch, getState) => {
    var state = getState().signup

    dispatch({
      type: ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS,
      API_CALL: {
        url: "/users/search",
        data: {
          query: query,
          page_size: 20,
        },
        getDataPayloads: json => {
          return [json.matching_user_data]
        },
        success: (json, response) => {
          dispatch({
            type: ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE,
            query: query,
            matches: json.matches,
          })
        },
      },
    })
  }
}

export function updateReferringUserId(id, source, query) {
  return {
    type: ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID,
    userId: id,
    source: source,
    query: query,
  }
}
