import React, { Component, PropTypes } from "react";;

import ActivityIndicator from './ActivityIndicator'
import colors from '../lib/colors'
import View from './View'

export default class Loading extends React.Component {
  static defaultProps = {
    loaded: false,
    size: 'large',
    color: colors.gray,
  };

  render() {
    return (
      <View style={Object.assign({}, styles.container, this.props.style)}>
        <ActivityIndicator
          animating={!this.props.loaded}
          style={styles.centering}
          {...this.props}
        />
      </View>
    )
  }
}


var styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
}
