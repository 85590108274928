import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class FSModal extends Component {

  componentDidMount() {
  }

  render() {
    if (!this.props.visible) {
      return (<span/>);
    }

    return (
      <div style={Object.assign({}, styles.container, this.props.style)}>
        {this.props.children}
      </div>
    );
  }
}

export default FSModal;

var styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    color: colors.gray,
    backgroundColor: colors.white,
    textAlign: 'center',
    padding: 20,
  },
  indicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -15,
    marginTop: -5,
  },
}
