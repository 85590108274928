"use strict"

import React from "react"
import { connect } from "react-redux"
import colors from "../../lib/colors"
import dataStore from "../../lib/dataStore"

import EditProfileValueEditor from "../EditProfileValueEditor"
import { EDITABLE_USER_ATTRIBUTE_TYPES } from "../constants"

import Loading from "../../components/Loading"
import FSText from "../../components/FSText"
import FSTouchable from "../../components/FSTouchable"
import FSScrollView from "../../components/FSScrollView"
import FSView from "../../components/FSView"
import View from "../../components/View"
import FSIcon from "../../components/FSIcon"
import UserAvatar from "../../components/UserAvatar"

import { cancelEditingAttribute, updateUser, setupCurrentUser } from "../actions/index"

import styles from "../styles"

class EditProfile extends FSView {
  getMyUrl() {
    return "profile/edit"
  }

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.props.dispatch(setupCurrentUser())
    this.setState({
      currentEditingAttribute: null,
      currentEditingValue: null,
      currentEditingPassesClientSideValidation: true,
    })
  }

  componentWillReceiveProps(newProps) {
    if (
      !this.props.saveSucceeded &&
      newProps.saveSucceeded &&
      !this.props.isLinkingOauth &&
      !this.props.isUnlinkingOauth
    ) {
      this.props.navigator.pop()
    }
  }

  renderLoading = () => {
    return (
      <View style={styles.container}>
        <Loading />
      </View>
    )
  }

  onCancel = () => {
    this.props.dispatch(cancelEditingAttribute())
    this.props.navigator.pop()
  }

  onDone = () => {
    setTimeout(() => {
      if (this.state.currentEditingPassesClientSideValidation) {
        var user = dataStore.get("user", this.props.currentUserId)
        var changes = {}
        changes[this.state.currentEditingAttribute] = this.state.currentEditingValue
        this.props.dispatch(updateUser(user, changes))
      }
    }) //needed if change() and done() are called immediately after one another, like location editor
  }

  handleValueChange = (newVal, passesClientSideValidation = true) => {
    this.setState({
      currentEditingValue: newVal,
      currentEditingPassesClientSideValidation: passesClientSideValidation,
    })
  }

  editAttribute = (user, attr, additionalProps = {}) => {
    var value = user[attr]
    if (attr === "full_name") {
      value = { first_name: user.first_name, last_name: user.last_name }
    } else if (attr === "home_location") {
      value = user.postal_code
    }

    this.setState({
      currentEditingAttribute: attr,
      currentEditingValue: value,
    })

    this.props.navigator.push({
      component: EditProfileValueEditor,
      title: EDITABLE_USER_ATTRIBUTE_TYPES[attr].title,
      backButtonTitle: "Cancel",
      rightButtonText: "Save",
      rightButtonStyle: {
        fontSize: 18,
        color: colors.forUser(user),
      },
      //onPressLeftButton: this.onCancel,
      //onPressRightButton: this.onDone,
      passProps: {
        handleValueChange: this.handleValueChange,
        userId: this.props.userId,
        attr: attr,
        value: value,
        hideHeader: false,
        user: user,
        onCancel: this.onCancel,
        onDone: this.onDone,
        ...EDITABLE_USER_ATTRIBUTE_TYPES[attr],
        ...additionalProps,
      },
    })
  }

  renderItem = (user, text, attr, iconName, additionalProps = {}, onPressAction = null) => {
    var userColor = colors.forUser(user)
    return (
      <FSTouchable
        onPress={onPressAction ? onPressAction : () => this.editAttribute(user, attr, additionalProps)}
        style={styles.attribute}
      >
        {typeof iconName === "string" ? (
          <FSIcon name={iconName} size={20} color={userColor} style={styles.attributeIcon} />
        ) : (
          <View>{iconName}</View>
        )}
        <FSText numberOfLines={1} style={styles.attributeText} weight="medium">
          {text}
        </FSText>
        <FSIcon name="right-arrow" size={15} color={colors.lightMediumGray} style={styles.arrowIcon} />
      </FSTouchable>
    )
  }

  renderProfilePhotoItem = user => {
    var hasProfilePhoto = user.images && user.images.length > 0 && user.images[0]
    return (
      <FSTouchable onPress={() => this.editAttribute(user, "avatar")} style={styles.attribute}>
        <View style={styles.attributeIcon}>
          <UserAvatar size={23} borderWidth={1} padding={1} user={user} />
        </View>
        <FSText style={styles.attributeText} weight="medium">
          {hasProfilePhoto ? "Edit Profile Photo" : "Add A Profile Photo!"}
        </FSText>
        <FSIcon name="right-arrow" size={15} color={colors.lightMediumGray} style={styles.arrowIcon} />
      </FSTouchable>
    )
  }

  render() {
    var user = dataStore.get("user", this.props.currentUserId)
    if (!user) {
      return this.renderLoading()
    }

    var full_name = [user.first_name, user.last_name].filter(x => x).join(" ")
    var profileType = dataStore.get("profile_type", user.profile_type_id)

    return (
      <FSScrollView style={styles.container} automaticallyAdjustContentInsets={false}>
        <View style={styles.spacer} />

        {this.renderProfilePhotoItem(user)}
        {this.renderItem(user, user.bio || "Add a Bio!", "bio", "pencil", {
          placeholder: profileType.placeholder_text,
        })}
        {this.renderItem(user, full_name && full_name.length ? full_name : "Add Your Real Name", "full_name", "pencil")}
        {this.renderItem(user, user.website || "Add Website", "website", "link")}
        {this.renderItem(user, user.home_location_display || "Home Location", "home_location", "current-location")}
        {this.renderItem(user, profileType.label, "profile_type_id", "profiletype-generic")}
        {/*this.renderItem(user, 'Edit Interests', 'user_level_tag_ids', 'dots')*/}

        <View style={styles.spacer} />

        {/*this.renderItem(user, user.profile_is_indexable ? 'Profile Searchability' : 'Profile is NOT Searchable', 'profile_is_indexable', 'search', {
          info: `Allow thefoodstand.com/${user.username.toLowerCase()} to be searchable on the web by search engines like Google?`,
          })*/}
        {this.renderItem(user, "Change Password", "password", "lock")}
      </FSScrollView>
    )
  }
} //User

function mapStateToProps(state) {
  return Object.assign({}, state.profile, {
    currentUserId: state.app.currentUserId,
  })
}

export default connect(mapStateToProps)(EditProfile)
