export const NEW_POST_LOCATION_LOCATION_LOADING = 'NEW_POST_LOCATION_LOCATION_LOADING'
export const NEW_POST_LOCATION_GEOCODE_LOADING = 'NEW_POST_LOCATION_GEOCODE_LOADING'
export const NEW_POST_LOCATION_LOCATION_LOADED = 'NEW_POST_LOCATION_LOCATION_LOADED'
export const NEW_POST_LOCATION_GEOCODE_LOADED = 'NEW_POST_LOCATION_GEOCODE_LOADED'
export const NEW_POST_LOCATION_LOCATION_FAILED = 'NEW_POST_LOCATION_LOCATION_FAILED'
export const NEW_POST_LOCATION_GEOCODE_FAILED = 'NEW_POST_LOCATION_GEOCODE_FAILED'
export const NEW_POST_LOCATION_GEOCODE_FOCUS = 'NEW_POST_LOCATION_GEOCODE_FOCUS'
export const NEW_POST_LOCATION_GEOCODE_BLUR = 'NEW_POST_LOCATION_GEOCODE_BLUR'
export const NEW_POST_LOCATION_SELECT_GEOCODE_RESULT = 'NEW_POST_LOCATION_SELECT_GEOCODE_RESULT'
export const NEW_POST_LOCATION_PERSIST_RESULT = 'NEW_POST_LOCATION_PERSIST_RESULT'
export const NEW_POST_LOCATION_PERSIST_RESULT_FAILED = 'NEW_POST_LOCATION_PERSIST_RESULT_FAILED'
export const NEW_POST_LOCATION_PERSIST_RESULT_COMPLETE = 'NEW_POST_LOCATION_PERSIST_RESULT_COMPLETE'
export const NEW_POST_LOCATION_SELECT_LOCATION = 'NEW_POST_LOCATION_SELECT_LOCATION'

var {
  autocompleteLocation,
  autocompleteGeocode
} = require('../../actions/autocomplete')

export function setLocationLoading() {
  return { type: NEW_POST_LOCATION_LOCATION_LOADING, }
}

export function setGeocodeLoading() {
  return { type: NEW_POST_LOCATION_GEOCODE_LOADING, }
}

export function setLocationFailed() {
  return { type: NEW_POST_LOCATION_LOCATION_LOADING, }
}

export function setGeocodeFailed() {
  return { type: NEW_POST_LOCATION_GEOCODE_LOADING, }
}

export function executeGeocodeQuery(query, lat, lng, onSuccess) {
  return dispatch => {
    dispatch({ type: NEW_POST_LOCATION_GEOCODE_LOADING })
    dispatch(autocompleteGeocode(query, lat, lng, 'newPostGeocode', (results) => {
      dispatch({
        type: NEW_POST_LOCATION_GEOCODE_LOADED,
        results: results,
      })
      if( onSuccess ) onSuccess(results)
    }, () => {
      dispatch({ type: NEW_POST_LOCATION_GEOCODE_FAILED })
    }))
  }
}

export function executeLocationQuery(query, lat, lng, onSuccess) {
  if( ! lat || ! lng ) return {type: 'NOOP'}
  return dispatch => {
    dispatch({ type: NEW_POST_LOCATION_LOCATION_LOADING })
    dispatch(autocompleteLocation(query, lat, lng, 'newPostLocation', (results) => {
      dispatch({
        type: NEW_POST_LOCATION_LOCATION_LOADED,
        results: results,
      })
      if( onSuccess ) onSuccess(results)
    }, () => {
      dispatch({ type: NEW_POST_LOCATION_LOCATION_FAILED })
    }))
  }
}

export function focusGeocode() {
  return { type: NEW_POST_LOCATION_GEOCODE_FOCUS }
}

export function blurGeocode() {
  return { type: NEW_POST_LOCATION_GEOCODE_BLUR }
}

export function selectGeocodeResult( data ) {
  return {
    type: NEW_POST_LOCATION_SELECT_GEOCODE_RESULT,
    name: data.name,
    latitude: data.latitude,
    longitude: data.longitude,
  }
}

export function persistLocation( result, callback ) {
  if( !! result.id ) {
    if( callback ) callback(result.id)
  } else {
    return dispatch => {
      dispatch({
        type: NEW_POST_LOCATION_PERSIST_RESULT,
        API_CALL: {
          url: '/locations',
          method: 'POST',
          dataType: 'json',
          data: {
            location: result
          },
          success: (resp) => {
            dispatch({
              type: NEW_POST_LOCATION_PERSIST_RESULT_COMPLETE,
              location: resp.location,
            })
            if( callback ) callback(resp.location)
          },
          error: (resp) => {
            dispatch({
              type: NEW_POST_LOCATION_PERSIST_RESULT_FAILED,
            })
          }
        }
      })
    }
  }
}

export function selectLocation( locationId ) {
  return {
    type: NEW_POST_LOCATION_SELECT_LOCATION,
    locationId: locationId,
  }
}
