import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class DatePicker extends Component {

  static defaultProps = {
    minuteInterval: 10,
    mode: 'date',
  };

  monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  constructor(props) {
    super(props)
    var d = props.date
    if (!d) {
      d = new Date();
    }
    this.state = {
      date: d,
    }
  }

  handleChange = () => {
    var year = parseInt(this.refs.year.value)
    var month = parseInt(this.refs.month.value) - 1
    var day = parseInt(this.refs.day.value)

    var d = null
    if (this.props.mode === 'datetime') {
      var hour = parseInt(this.refs.hour.value)
      var minute = parseInt(this.refs.minute.value)
      var isPM = this.refs.ampm.value === 'pm'
      if (hour === 12) {
        hour = 0;
      }
      if (isPM) {
        hour += 12;
      }
      d = new Date(year, month, day, hour, minute)
    } else {
      d = new Date(year, month, day)
    }
    this.setState({
      date: d,
    })
    this.props.onDateChange(d)
  };

  daysInMonth = () => {
    return new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, 0).getDate();
  };

  renderDaySelect() {
    var maxDays = this.daysInMonth()
    var currDay = this.state.date.getDate()
    var days = []
    for(var i = 1 ; i <= maxDays ; i++) {
      days.push(i)
    }
    return (
      <select ref="day" className="DatePickerDay" style={styles.select} onChange={this.handleChange} value={currDay}>
        {days.map(day => (
          <option key={day} value={day}>{day}</option>
          ))}
      </select>
    )
  }

  renderMonthSelect() {
    var currMonth = this.state.date.getMonth()
    var months = [1,2,3,4,5,6,7,8,9,10,11,12]
    return (
      <select ref="month" className="DatePickerMonth" style={styles.select} onChange={this.handleChange} value={currMonth + 1}>
        {months.map(month => (
          <option key={month} value={month}>{this.monthAbbreviations[month - 1]}</option>
        ))}
      </select>
    )
  }

  renderYearSelect() {
    var currYear = this.state.date.getFullYear()
    var maxYear = currYear + 5;
    var years = []
    for(var i = currYear ; i < maxYear ; i++) {
      years.push(i)
    }
    return (
      <select ref="year" className="DatePickerYear" style={styles.select} onChange={this.handleChange} value={currYear}>
        {years.map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
    )
  }

  renderHourSelect() {
    var currHour = this.state.date.getHours()
    if (currHour > 12) {
      currHour -= 12
    }
    if (currHour === 0) {
      currHour = 12
    }
    var hours = [1,2,3,4,5,6,7,8,9,10,11,12]
    return (
      <select ref="hour" className="DatePickerHour" style={styles.select} onChange={this.handleChange} value={currHour}>
        {hours.map(hour => (
          <option key={hour} value={hour}>{hour}</option>
        ))}
      </select>
    )
  }

  renderMinuteSelect() {
    var currMinute = this.state.date.getMinutes()
    var minutes = []
    for(var i = 0 ; i <= 60 ; i += this.props.minuteInterval) {
      minutes.push(i)
    }
    return (
      <select ref="minute" className="DatePickerMinute" style={styles.select} onChange={this.handleChange} value={currMinute}>
        {minutes.map(minute => (
          <option key={minute} value={minute}>{minute.toString().length === 1 ? "0" + minute : minute}</option>
        ))}
      </select>
    )
  }

  renderAmPmSelect() {
    var isPM = this.state.date.getHours() >= 12
    return (
      <select ref="ampm" className="DatePickerAmPm" style={styles.select} onChange={this.handleChange} value={isPM ? 'pm' : 'am'}>
        <option key="am" value="am">AM</option>
        <option key="pm" value="pm">PM</option>
      </select>
    )
  }

  render() {
    return (
      <div className="DatePicker" style={Object.assign({}, styles.wrapper, this.props.style)}>
        {this.renderMonthSelect()}
        /
        {this.renderDaySelect()}
        /
        {this.renderYearSelect()}

        {(this.props.mode === 'datetime') ? (
          <div style={styles.timeWrapper}>
            {this.renderHourSelect()}
            :
            {this.renderMinuteSelect()}

            {this.renderAmPmSelect()}
          </div>
        ) : null}
      </div>
    );
  }
}

export default DatePicker;

var styles = {
  wrapper: {
    color: colors.mediumGray,
  },
  select: {
    display: 'inline-block',
    padding: 4,
    backgroundColor: colors.lightGray,
    color: colors.black,
    border: '1px '+colors.mediumGray+' solid',
    margin: 4,
  },
}
