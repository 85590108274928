export const ONBOARDING_SWITCH_VIEW = 'ONBOARDING_SWITCH_VIEW';

export const ONBOARDING_VIEW_KEY_SPLASHSCREEN = 'ONBOARDING_VIEW_KEY_SPLASHSCREEN';
export const ONBOARDING_VIEW_KEY_SIGNIN = 'SIGNIN';
export const ONBOARDING_VIEW_KEY_SIGNUP = 'SIGNUP';
export const ONBOARDING_VIEW_KEY_FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const ONBOARDING_SHOW_AUTH = 'ONBOARDING_SHOW_AUTH';
export const ONBOARDING_HIDE_AUTH = 'ONBOARDING_HIDE_AUTH';

export function switchView(toView, buddyRef) {
  return {
    type: ONBOARDING_SWITCH_VIEW,
    toView: toView,
    buddyRef: buddyRef,
  }
}

export function showAuth(buddyRef) {
  $('.foodstand-auth-dialog').show();
  $('.content, header, .footer').addClass('blurredContent');

  return {
    type: ONBOARDING_SHOW_AUTH,
    buddyRef: buddyRef,
  }
}

export function hideAuth() {
  $('.foodstand-auth-dialog').hide();
  $('.blurredContent').removeClass('blurredContent');

  return {
    type: ONBOARDING_HIDE_AUTH,
  }
}
