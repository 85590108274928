'use strict';

import { defaultReducerMapping } from '../../lib/redux';
import deepFreeze from 'deep-freeze';

var initialState = deepFreeze({
  loaded: false,
  loading: false,
});

var {
  USER_LEVEL_TAGS_FETCH,
  USER_LEVEL_TAGS_FETCH_COMPLETE,
} = require('../actions/userLevelTags')

var actionsMap = {
  USER_LEVEL_TAGS_FETCH: (state, action) => ({
    loaded: false,
    loading: true,
  }),
  USER_LEVEL_TAGS_FETCH_COMPLETE: (state, action) => ({
    loaded: true,
    loading: false,
    fetchedAt: Date.now(),
  }),
}

export default defaultReducerMapping(initialState, actionsMap)
