import dataStore from '../lib/dataStore'

export const USER_LEVEL_TAGS_FETCH = 'USER_LEVEL_TAGS_FETCH'
export const USER_LEVEL_TAGS_FETCH_COMPLETE = 'USER_LEVEL_TAGS_FETCH_COMPLETE'
export const USER_LEVEL_TAGS_FETCH_FAILED = 'USER_LEVEL_TAGS_FETCH_FAILED'

export function fetchUserLevelTags( cb ) {
  return (dispatch, getState) => {
    var state = getState();
    if (!state.userLevelTags.loaded && !state.userLevelTags.loading) {
      dispatch({
        type: USER_LEVEL_TAGS_FETCH,
        receivedAt: Date.now(),
        API_CALL: {
          url: '/user_level_tags',
          method: 'GET',
          success: (json, response) => {
            dispatch({
              type: USER_LEVEL_TAGS_FETCH_COMPLETE,
              receivedAt: Date.now(),
              user_level_tags: response.user_level_tags
            })
            cb && cb(null, json)
          },
          error: (error) => {
            dispatch({
              type: USER_LEVEL_TAGS_FETCH_FAILED,
              error: error,
            })
            cb && cb(error)
          },
        }
      })
    }
  }
}
