'use strict';

import { defaultReducerMapping } from '../../lib/redux';
import deepFreeze from 'deep-freeze'
import moveElementsToFront from '../../lib/utils/moveElementsToFront'
import search from '../../lib/utils/search_filter'
import initialState from './initialState'
import UserLevelTag from '../../models/UserLevelTag'
import ProfileType from '../../models/ProfileType'
import Challenge from '../../models/Challenge'

var {
  NEW_POST_CANCEL_EDITING_ATTRIBUTE,
  NEW_POST_EDIT_ATTRIBUTE,
} = require('../actions/');

var {
  NEW_POST_ORDER_TAGS,
  NEW_POST_TOGGLE_TAG_SELECTION,
  NEW_POST_UPDATE_TAG_QUERY,
  NEW_POST_POPULATE_TAGS,
  NEW_POST_FETCH_USER_LEVEL_TAGS,
  NEW_POST_SET_TAG_IDS,
  NEW_POST_CLEAR_TAG_QUERY,
} = require('../actions/tags');

var {
  USER_LEVEL_TAGS_FETCH_COMPLETE,
} = require('../../actions/userLevelTags')

var actionsMap = {}

actionsMap[NEW_POST_TOGGLE_TAG_SELECTION] = (state, action) => {
  var i, tagId = action.tagId
  var ids = state.selectedTagIds.slice(0)
  if( (i=ids.indexOf(tagId)) === -1 ) {
    ids.push(tagId)
  } else {
    ids.splice(i,1)
  }
  return { selectedTagIds: ids }
}

actionsMap[NEW_POST_UPDATE_TAG_QUERY] = (state, action) => {
  var challenges = Challenge.all()
  var q = action.query

  var challengeIds = [];
  if (q && q.trim().length > 0) {
    challengeIds = search( challenges, q.toLowerCase(), (tag, word) => tag.name.toLowerCase().indexOf(word)!==-1 ).map(c=>c.id)
  } else {
    challengeIds = dataStore.getAllIds('challenge')
  }

  return Object.assign({}, state, {
    query: action.query,
    visibleChallengeIds: challengeIds,
  })
}

actionsMap[NEW_POST_CANCEL_EDITING_ATTRIBUTE] = (state, action) => {
  return initialState.tags
}

actionsMap[NEW_POST_EDIT_ATTRIBUTE] = (state, action) => {
  var attr = action.attr
  if( attr !== 'challenge_ids' ) return;
  var post = action.post

  var state = Object.assign({}, initialState.tags)
  state.selectedTagIds = post.challenge_ids
  return state
}

actionsMap[NEW_POST_ORDER_TAGS] = (state, action) => ({
  allChallengeIds: moveElementsToFront( state.selectedTagIds,  state.allChallengeIds )
})

actionsMap[NEW_POST_SET_TAG_IDS] = (state,action) => ({
  allChallengeIds: action.tagIds
})

actionsMap[NEW_POST_POPULATE_TAGS] = (state,action) => ({
  loading: false,
  allChallengeIds: Challenge.all().map(p=>p.id)
})

actionsMap[NEW_POST_CLEAR_TAG_QUERY] = (state,action) => ({
  loading: false,
  query: '',
})

export default defaultReducerMapping(initialState.tags, actionsMap);
