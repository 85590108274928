import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class ActivityIndicator extends Component {

  componentDidMount() {
  }

  render() {
    return (
      <img src="http://foodstand.imgix.net/assets/images/large-spinner.gif" width="25" height="25"/>
    );
  }
}

export default ActivityIndicator;

var styles = {
}
