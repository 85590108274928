'use strict'

import React from 'react'
import { connect } from 'react-redux'
import colors from '../lib/colors'
import dataStore from '../lib/dataStore'
import humanize from 'humanize-string'

import ValueEditor from '../components/ValueEditor'
import { styles } from '../components/ValueEditor'

class EditProfileValueEditor extends ValueEditor {

  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      passesClientSideValidation: true,
    }
  }

  handleValueChange = (newVal, passesClientSideValidation) => {
    this.props.handleValueChange(newVal, passesClientSideValidation);
  };

  onDone = (val) => {
    if (this.props.onDone) {
       this.props.onDone(val);
     }
  };

  onCancel = () => {
    this.props.navigator.pop();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  renderEditingArea = () => {
    var errors = null

    if (this.props.errors) {
      switch (this.props.attr) {
        case 'full_name':
          errors = {}
          if (this.props.errors.first_name) errors.first_name = this.props.errors.first_name
          if (this.props.errors.last_name) errors.last_name = this.props.errors.last_name
          break;
        default:
          errors = (this.props.errors[this.props.attr] || []).map(e => `${humanize(this.props.attr)} ${e}`)
      }
    }

    var Component = this.editingComponentForType();
    if (Component) {
      return (<Component {...this.props}
                errors={errors}
                style={styles.editor}
                tabsPresent={true}
                onDone={this.onDone}
                onCancel={this.onCancel}
                onChange={this.handleValueChange}/>);
    }
  };


}

function mapStateToProps(state) {
  return Object.assign({}, state.profile, { });
}

export default connect(mapStateToProps)(EditProfileValueEditor);
