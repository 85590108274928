import React, { Component, PropTypes } from 'react';

class View extends Component {

  render() {
    var styles = {};
    if (this.props.style) {
      if (Array.isArray(this.props.style)) {
        var arr = [].concat.apply([], this.props.style); //flatten array of arrays
        arr.forEach(s => {
          styles = Object.assign(styles, s);
        });
      }
      else {
        styles = this.props.style;
      }
    }
    return (
      <div {...this.props} style={styles} >
        {this.props.children}
      </div>
    );
  }
}

export default View;
