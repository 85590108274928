import React, { Component } from 'react';
import { render } from 'react-dom'
import { Provider } from 'react-redux';
import FoodstandAuth from '../containers/FoodstandAuth';
import configureStore from '../../store/configureStore';
import rootReducer from '../reducers';

const store = configureStore(rootReducer);

export default class Root extends Component {
  componentWillMount() {
    //store.dispatch(setCounter(this.props.counter));
  }
  render() {
    return (
      <Provider store={store}>
        <FoodstandAuth {...this.props} />
      </Provider>
    );
  }
}
