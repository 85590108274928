import Color from "color"
import colors from "../../lib/colors"
import { defaultReducerMapping } from "../../lib/redux"

import deepFreeze from "deep-freeze"

var {
  ONBOARDING_SIGN_UP_VALIDATE_USER,
  ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE,
  ONBOARDING_SIGN_UP_CREATE_USER,
  ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE,
  ONBOARDING_SIGN_UP_UPDATE_VALUE,
  ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE,
  ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX,
  ONBOARDING_SIGN_UP_CHANGE_STEP_NAV_COLOR,
  ONBOARDING_SIGN_UP_PHOTO_FILE_SELECTED,
  ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE,
  ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG,
  ONBOARDING_SIGN_UP_LOCATE_ZIP,
  ONBOARDING_SIGN_UP_LOCATE_ZIP_FAILED,
  ONBOARDING_SIGN_UP_LOCATE_ZIP_COMPLETE,
  ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS,
  ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE,
  ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID,
  ONBOARDING_SIGN_UP_NEXT_SCREEN,
  ONBOARDING_SIGN_UP_PREVIOUS_SCREEN,
  ONBOARDING_SIGN_UP_SET_HEADING,
} = require("../actions/signup")

/*
var {
  PROFILE_UNLINK_OAUTH_CREDENTIALS,
} = require('../actions/profile');
*/

var {
  ONBOARDING_SOCIAL_AUTH_FACEBOOK_COMPLETE,
  ONBOARDING_SOCIAL_AUTH_FACEBOOK_FETCH_USER_INFO_COMPLETE,
  ONBOARDING_SOCIAL_AUTH_INSTAGRAM_COMPLETE,
  ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FETCH_USER_INFO_COMPLETE,
  ONBOARDING_SOCIAL_AUTH_FOURSQUARE_COMPLETE,
  ONBOARDING_SOCIAL_AUTH_FOURSQUARE_FETCH_USER_INFO_COMPLETE,
  ONBOARDING_SOCIAL_AUTH_DOWNLOAD_PHOTO_COMPLETE,
} = require("../actions/socialAuth")

var initialState = deepFreeze({
  stepIndex: 1,
  stepHeading: null,
  stepSubheading: null,
  profileTypeId: 1,
  profileColor: null,
  profileColorDark: null,
  userLevelTagIds: [],
  photoTemp: null,
  remotePhotoTemp: null,
  username: "",
  usernameValid: false,
  usernameError: null,
  email: "",
  emailError: null,
  password: "",
  passwordError: null,
  home_latitude: null,
  home_longitude: null,
  postal_code: "",
  postal_codeValid: false,
  postal_codeError: null,
  locatingZip: false,
  uploadingPhoto: false,
  uploadingPhotoFailed: false,
  uploadingPhotoSucceeded: false,
  creatingUser: false,
  instagramUserInfoString: null,
  instagramAccessToken: null,
  instagramUserId: null,
  foursquareUserInfoString: null,
  foursquareAccessToken: null,
  foursquareUserId: null,
  facebookUserInfoString: null,
  facebookAccessToken: null,
  facebookUserId: null,
  createdAt: null,
  oauthServiceLinking: null,
  oauthServiceUninking: null,
  validatingUser: false,
  userValid: false,
  memberSearchMatches: [],
  memberSearchInProgress: false,
  referringUserId: null,
  referringSource: null,
  referringQuery: null,
})

/*
//For testing
var rand = Math.floor((1 + Math.random()) * 0x1000000).toString(19).substring(1);
initialState = Object.assign({}, initialState, {
  stepIndex: 3,
  username: 'username_'+rand,
  password: 'password_'+rand,
  email: rand + '@test.com',
  postcalCode: '11217',
  //remotePhotoTemp:"https://scontent.xx.fbcdn.net/hprofile-xpf1/v/t1.0-1/c0.0.640.640/10689598_10101840294192485_5897326587970100042_n.jpg?oh=e512b293e1f31ca31e6c9928042d4fe7&oe=57769937"
});
*/

//Just return the new/change parts of the state
var map = {}

map[ONBOARDING_SIGN_UP_UPDATE_VALUE] = (state, action) => {
  var ret = {}
  ret[action.key] = action.value
  return ret
}

map[ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE] = (state, action) => {
  var pt = dataStore.get("profile_type", action.profileTypeId)
  return {
    profileTypeId: action.profileTypeId,
    profileColor: colors.forProfileType(pt),
    profileColorDark: Color(colors.forProfileType(pt)).darken(0.25).hexString(),
  }
}

map[ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX] = (state, action) => {
  return {
    stepIndex: action.index,
  }
}

map[ONBOARDING_SIGN_UP_CHANGE_STEP_NAV_COLOR] = (state, action) => {
  return {
    stepNavColor: action.color,
  }
}

map[ONBOARDING_SIGN_UP_PHOTO_FILE_SELECTED] = (state, action) => {
  return {
    photoTemp: action.file,
  }
}

map[ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE] = (state, action) => {
  return {
    usernameValid: action.valid ? true : false,
    usernameError: action.errors ? action.errors.username[0] : null,
  }
}

map[ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG] = (state, action) => {
  var newTagIds = []
  var found = false

  state.userLevelTagIds.forEach(tid => {
    if (tid === action.tagId) {
      found = true
    } else {
      newTagIds.push(tid)
    }
  })

  if (!found) {
    newTagIds.push(action.tagId)
  }

  return {
    userLevelTagIds: newTagIds,
  }
}

map[ONBOARDING_SIGN_UP_VALIDATE_USER] = (state, action) => {
  return {
    validatingUser: true,
    userValid: false,
  }
}

map[ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE] = (state, action) => {
  var _getError = field => {
    return action.errors ? (action.errors[field] ? action.errors[field][0] : null) : null
  }
  return {
    validatingUser: false,
    userValid: !action.errors,
    usernameValid: !action.errors || !action.errors.username,
    postal_codeValid: !action.errors || !action.errors.postal_code,
    postal_codeError: _getError("postal_code"),
    emailError: _getError("email"),
    usernameError: _getError("username"),
    passwordError: _getError("password"),
  }
}

map[ONBOARDING_SIGN_UP_CREATE_USER] = (state, action) => {
  return {
    creatingUser: true,
  }
}

map[ONBOARDING_SOCIAL_AUTH_INSTAGRAM_COMPLETE] = (state, action) => {
  return {
    instagramAccessToken: action.accessToken,
    instagramUserId: action.user.id,
  }
}

map[ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FETCH_USER_INFO_COMPLETE] = (state, action) => {
  return {
    instagramUserInfoString: `@${action.user.username}`,
    username: action.user.username,
    remotePhotoTemp: action.user.profile_picture,
  }
}

map[ONBOARDING_SOCIAL_AUTH_FACEBOOK_COMPLETE] = (state, action) => {
  return {
    facebookAccessToken: action.accessToken,
    facebookUserId: action.userId,
  }
}

map[ONBOARDING_SOCIAL_AUTH_FACEBOOK_FETCH_USER_INFO_COMPLETE] = (state, action) => {
  return {
    facebookUserInfoString: action.name,
    username: action.name ? action.name.replace(/ /g, "") : null,
    email: action.email,
    remotePhotoTemp: action.photo,
  }
}

map[ONBOARDING_SOCIAL_AUTH_FOURSQUARE_COMPLETE] = (state, action) => {
  return {
    foursquareAccessToken: action.accessToken,
    foursquareUserId: action.user.id,
  }
}

map[ONBOARDING_SOCIAL_AUTH_FOURSQUARE_FETCH_USER_INFO_COMPLETE] = (state, action) => {
  var photoInfo = action.user.photo
  var photoUrl = photoInfo ? photoInfo.prefix + "500x500" + photoInfo.suffix : null
  return {
    foursquareUserInfoString: `${action.user.firstName} ${action.user.lastName || ""}`,
    username: action.user.firstName,
    email: action.user.contact ? action.user.contact.email : null,
    remotePhotoTemp: photoUrl,
  }
}

/*
map[PROFILE_UNLINK_OAUTH_CREDENTIALS] = (state, action) => {
  var ret = {};
  ret[action.service + 'AccessToken'] = null;
  ret[action.service + 'UserId'] = null;
  return ret;
}
*/

map[ONBOARDING_SIGN_UP_LOCATE_ZIP] = (state, action) => {
  return {
    locatingZip: true,
  }
}

map[ONBOARDING_SIGN_UP_LOCATE_ZIP_COMPLETE] = (state, action) => {
  return {
    locatingZip: false,
    postal_code: action.postalcode,
    home_latitude: action.latitude,
    home_longitude: action.longitude,
    postal_codeValid: true,
    postal_codeError: null,
  }
}

map[ONBOARDING_SIGN_UP_LOCATE_ZIP_FAILED] = (state, action) => {
  return {
    locatingZip: false,
  }
}

map[ONBOARDING_SOCIAL_AUTH_DOWNLOAD_PHOTO_COMPLETE] = (state, action) => {
  return {
    photoTemp: action.fileUrl,
    remotePhotoTemp: null,
  }
}

map[ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE] = (state, action) => {
  var _getError = field => {
    return action.errors ? (action.errors[field] ? action.errors[field][0] : null) : null
  }
  return {
    creatingUser: false,
    createdAt: !action.errors ? Date.now() : null,
    usernameValid: !action.errors || !action.errors.username,
    postal_codeValid: !action.errors || !action.errors.postal_code,
    postal_codeError: _getError("postal_code"),
    emailError: _getError("email"),
    usernameError: _getError("username"),
    passwordError: _getError("password"),
  }
}

map[ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS] = (state, action) => {
  return {
    memberSearchMatches: [],
    memberSearchInProgress: true,
  }
}

map[ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE] = (state, action) => {
  return {
    memberSearchMatches: action.matches,
    memberSearchInProgress: false,
  }
}

map[ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID] = (state, action) => {
  return {
    referringUserId: action.userId,
    referringSource: action.source,
    referringQuery: action.query,
  }
}

map[ONBOARDING_SIGN_UP_NEXT_SCREEN] = (state, action) => {
  return {
    stepIndex: state.stepIndex + 1,
  }
}

map[ONBOARDING_SIGN_UP_PREVIOUS_SCREEN] = (state, action) => {
  return {
    stepIndex: Math.max(state.stepIndex - 1, 1),
  }
}

map[ONBOARDING_SIGN_UP_SET_HEADING] = (state, action) => {
  return {
    heading: action.heading,
    subheading: action.subheading,
  }
}

export default defaultReducerMapping(initialState, map)
