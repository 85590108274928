'use strict'

import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux'
import colors from '../../../lib/colors'
import Color from 'color'

import FS from '../../../components/FS'
import UserAvatar from '../../../components/UserAvatar'
import ModalHeader from '../../../components/ModalHeader'
import DebouncedTextInput from '../../../components/DebouncedTextInput'

import {
  fetchNextMembersResults,
} from '../../actions/signup'


class RefererSelector extends React.Component {

  state = {
    debounce: 300,
    typing: false,
    query: '',
  };
  sources = [
    'From a friend',
    'Instagram',
    'Facebook',
    'Twitter',
    'Event',
    'Article or blog',
    'Online search',
    'Ad',
    'Meetup',
    'Other',
  ];

  componentDidMount = () => {
    this.refs.input.refs.input.focus();
  }

  handleCancel = () => {
    this.props.onCancel && this.props.onCancel()
  };

  renderResult = (result) => {
    var user = dataStore.get('user', result.id);
    if (user) {
      return (
        <FS.Touchable style={styles.resultWrap} key={user.id} onPress={() => {
          this.props.onSelectUser && this.props.onSelectUser(user.id, null, this.state.query)
        }}>
          <UserAvatar user={user} size={54} style={styles.userAvatar}/>
          <div style={styles.userInfo}>
            <FS.Text style={Object.assign({}, styles.username, {color: user.color})}>{user.username}</FS.Text>
            {(user.first_name || user.last_name) ? (
              <FS.Text style={styles.userFullName}>{[user.first_name, user.last_name].join(' ')}</FS.Text>
            ) : null}
            <FS.Text style={styles.profileType}>{user.profile_type.label}</FS.Text>
          </div>
        </FS.Touchable>
      )
    }
  };

  renderSource = (source) => {
    return (
      <FS.Button style={styles.sourceWrap} key={source} onPress={() => {
        this.props.onSelectUser && this.props.onSelectUser(null, source, this.state.query)
      }}>
        <FS.Text style={styles.sourceText}>{source}</FS.Text>
      </FS.Button>
    )
  };

  renderSources = () => {
    return (
      <div style={styles.sources}>
        {this.sources.map(source => this.renderSource(source))}
      </div>
    )
  };

  renderResults() {
    if (this.props.memberSearchMatches.length === 0 && this.state.query && !this.props.memberSearchInProgress) {
      return (
        <FS.ScrollView style={styles.results} contentContainerStyle={styles.resultsContentContainer}>
          <FS.Text style={styles.noResultsText}>
            No members found.  Did you hear about us from one of these?
          </FS.Text>
          {this.renderSources()}
        </FS.ScrollView>
      )
    } else if (this.props.memberSearchMatches.length > 0 && this.state.query) {
      return (
        <FS.ScrollView style={styles.results} contentContainerStyle={styles.resultsContentContainer}>
          {this.props.memberSearchMatches.map(res => this.renderResult(res))}
          {(!this.props.memberSearchInProgress && !this.state.typing) ? (
            <div>
              <FS.Text style={styles.noResultsText}>
                Not one of the above?  Did you hear about us from one of these?
              </FS.Text>
              {this.renderSources()}
            </div>
          ) : null}
        </FS.ScrollView>
      )
    }
  }

  fetchResults = (query) => {
    this.props.dispatch(fetchNextMembersResults(query))
  };

  render() {
    var pt = dataStore.get('profile_type', this.props.profileTypeId)
    var bgstyle = {backgroundColor: this.props.profileColor}
    var bgdarkstyle = {backgroundColor: Color(this.props.profileColor).darken(0.15).hexString()}

    return (
      <div style={styles.wrapper}>
        <ModalHeader
          leftText='CANCEL' rightText=''
          buttonTextStyle={{color: colors.white}}
          onPressLeft={this.handleCancel}
          onPressRight={this.handleSave}
          style={Object.assign({}, bgstyle, styles.header)}
          />

        <DebouncedTextInput
          ref='input'
          style={Object.assign({}, styles.input, bgdarkstyle)}
          autoCapitalize="none"
          autoCorrect={false}
          autoFocus={true}
          clearButtonMode="while-editing"
          placeholderTextColor={Color(this.props.profileColor).lighten(0.3).hexString()}
          placeholder={'Who referred you?'}
          onChangeText={(text) => {
            this.setState({typing: true})
          }}
          onChangeTextDebounced={(text) => {
            this.setState({query: text, typing: false})
            this.fetchResults(text)
          }}
          />

        {this.renderResults()}

      </div>
    )
  }

}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.signup, { });
}

export default connect(mapStateToProps)(RefererSelector)

var styles = {
  wrapper: {
    flex: 1,
    backgroundColor: colors.white,
    margin: -20,
  },
  header: {
    textAlign: 'left',
  },
  contentContainer: {
    flex: 1,
    paddingTop: 55,
    padding: '0 30px',
    paddingBottom: 35,
  },
  input: {
    backgroundColor: colors.white,
    height: 40,
    padding: '0 22px',
    color: colors.white,
    border: 0,
    outline: 0,
    width: '100%',
    boxSizing: 'border-box',
  },
  results: {
    flex: 1,
    backgroundColor: colors.white,
  },
  resultWrap: {
    display: 'table',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: 'row',
    padding: '12px 20px',
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray,
    alignItems: 'center',
  },
  noResultsText: {
    color: colors.mediumGray,
    fontSize: 16,
    textAlign: 'center',
    padding: 30,
    display: 'block',
  },
  userAvatar: {
    marginRight: 18,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  userInfo: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'left',
    paddingLeft: 18,
  },
  username: {
    display: 'block',
  },
  userFullName: {
    display: 'block',
  },
  profileType: {
    color: colors.mediumGray,
  },
  sources: {
    display: 'block',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 25,
    backgroundColor: colors.white,
    marginTop: -25,
    marginBottom: 25,
  },
  sourceWrap: {
    backgroundColor: colors.lightGray,
    borderRadius: 4,
    height: 40,
    lineHeight: '40px',
    margin: 8,
    padding: '0 12px',
  },
  sourceText: {
    color: colors.darkGray,
  },
}
