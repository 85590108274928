import React, { Component, PropTypes } from 'react';

var Alert = {
  alert: (title, message) => {
    var msg = message || '';
    if (title) {
      msg = title + ' ' + msg
    }
    alert(msg)
  }
}

export default Alert;
