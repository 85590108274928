'use strict'

// Well they're constants as far as everything external is concerned...
var c = {
  iconSize: 30,
  fontSize: 14,
  gutterWidth: 1,
  iconPadding: 20,
  fieldSpacing: 20,
  fontWeight: '500',
  footerHeight: 50,
}

//c.leftColWidth = c.iconSize + 2 * c.iconPadding

export default c
