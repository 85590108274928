import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class Heading extends Component {

  componentDidMount() {
  }

  render() {
    return (
      <div className="FoodstandAuth-Heading" style={styles.heading}>
        {this.props.text}
      </div>
    );
  }
}

export default Heading;

var styles = {
  heading: {
  }
}
