'use strict'

import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'
import c from './constants'

export default {
  container: {
    flex: 1,
    textAlign: 'center',
  },
  editOptionsWrapper: {
    position: 'relative',
    flex: 1,
    height: '100%',
  },
  scrollViewContainer: {
    backgroundColor: colors.lightGray,
  },
  postTypeSelector: {
    marginLeft: 5,
    marginRight: 5,
  },
  tipWrap: {
    position: 'relative',
    alignItems: 'center',
    marginTop: -12,
    marginBottom: 12,
  },
  tipIcon: {
    color: colors.lightMediumGray,
  },
  tipLine: {
    height: 1,
    backgroundColor: colors.lightMediumGray,
    position: 'absolute',
    left: 10,
    right: 10,
    top: 3,
  },
  tipText: {
    fontSize: 11,
    color: colors.mediumGray,
    textAlign: 'center',
    marginTop: -2,
  },
  cameraContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }
}
