'use strict'

import React, { Component, PropTypes } from "react";
import FSText from '../../components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from '../../components/FSIcon'
import colors from '../../lib/colors'
import c from '../constants'
import { formatDate } from '../../lib/utils/date'
import moment from 'moment';
import View from '../../components/View'

import {
  removeAttribute,
  editAttribute,
} from '../actions'

export default class FormLabelDateTime extends React.Component {

  removeAttribute = () => {
    var startOrEnd = this.props.field.split('_')[0];
    this.props.dispatch(removeAttribute(startOrEnd + '_date'));
    this.props.dispatch(removeAttribute(startOrEnd + '_time'));
  };

  editAttribute = () => {
    this.props.dispatch(editAttribute(this.props.field));
  };

  get formattedDate() {
    var startOrEnd = this.props.field.split('_')[0];
    var time = this.props.post[startOrEnd + '_time'];
    var date = this.props.post[startOrEnd + '_date'];
    var val = time || date;
    if (val) {
      return formatDate(val, time ? 'datetime' : 'date');
    }
    return null;
  }

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)

    return (
      <FormLabelWithIcon
        name="calendar"
        color={colorForCurrentUser}
        placeholder={this.props.placeholder}
        value={this.formattedDate}
        title={this.props.title}
        onPress={this.editAttribute}
        onRemove={this.removeAttribute}
      ></FormLabelWithIcon>
    )
  }
}
