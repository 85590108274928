'use strict'

import React, { Component, PropTypes } from "react"
import FSText from '../../components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from '../../components/FSIcon'
import FSTouchable from '../../components/FSTouchable'
import { truncate } from '../../lib/utils/text'
import colors from '../../lib/colors'
import c from '../constants'
import splitArrayByRegexes from '../../lib/utils/split-array-by-regexes'
import View from '../../components/View'

import {
  removeAttribute,
  editAttribute,
} from '../actions'

var idSplitter = {
  userLevelTags: /^[0-9]+$/,
  profileTypes: /^pt-[0-9]+$/
}

export default class FormLabelTags extends React.Component {

  get tagList() {
    if( ! this.props.post ) return [];
    return UserLevelTag.findAll( this.props.post.user_level_tag_ids ).map(t => t.name)
  }

  removeAttribute = () => {
    this.props.dispatch(removeAttribute('challenge_ids'));
  };

  editAttribute = () => {
    this.props.dispatch(editAttribute('challenge_ids', this.props.post));
  };

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)
    var hasTags = this.props.post.user_level_tag_ids.length > 0 || this.props.post.profile_type_ids.length > 0

    var challenges = dataStore.getMany('challenge', this.props.post.challenge_ids || [])
    var value = (challenges && challenges.length > 0) ? challenges.map(c => c ? c.name : 'General Food Info').join(', ') : 'SELECT AT LEAST ONE';

    return (
      <FSTouchable style={Object.assign({}, styles.wrapper,this.props.style)} onPress={this.editAttribute}>
        <FSText style={Object.assign({}, styles.label, hasTags ? {color: colorForCurrentUser} : null)} numberOfLines={1}>
          Best for
        </FSText>
        <FSText style={styles.value} numberOfLines={1}>
          {value}
        </FSText>
      </FSTouchable>
    )
  }
}

var styles = {
  wrapper: {
    marginTop: c.fieldSpacing,
    display: "flex", flexDirection: "row",
    paddingTop: c.fieldSpacing * 0.75, paddingBottom: c.fieldSpacing * 0.75,
    paddingLeft: c.iconPadding, paddingRight: c.iconPadding,
    marginBottom: c.gutterWidth,
    backgroundColor: colors.white,
    justifyContent: 'center',
  },
  label: {
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray,
  },
  value: {
    textAlign: 'right',
    paddingLeft: 10,
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray,
    flex: 1,
  },
}
