import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import api from '../middleware/api';
import analytics from '../middleware/analytics';
import logger from '../middleware/logger';

var middleware;
if (window.FS_ENV.ENVIRONMENT === 'development') {
  middleware = applyMiddleware(thunk, api, analytics, logger)
} else {
  middleware = applyMiddleware(thunk, api, analytics)
}

export default function configureStore(rootReducer, initialState) {
  const store = createStore(rootReducer, initialState, compose(
    middleware,
    typeof window === 'object' && typeof window.devToolsExtension !== 'undefined' ? window.devToolsExtension() : f => f
  ));
  return store;
}
