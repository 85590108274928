'use strict'

import config from '../config'
import fsConsole from '../lib/utils/fs-console'
export const DEVICE_LOCATION_FETCH_GEO = 'DEVICE_LOCATION_FETCH_GEO';
export const DEVICE_LOCATION_FETCH_GEO_FAILED = 'DEVICE_LOCATION_FETCH_GEO_FAILED';
export const DEVICE_LOCATION_FETCH_GEO_COMPLETE = 'DEVICE_LOCATION_FETCH_GEO_COMPLETE';

import Geolocation from '../lib/Geolocation'
import GeolocationStub from '../lib/Geolocation/index.stub'


if (config.env.FS_DEBUG && config.env.FS_FAKE_DEVICE_LOCATION) {
  var NavigatorGeolocation = GeolocationStub
} else {
  var NavigatorGeolocation = Geolocation
}


export function fetchGeo(cb) {
  return dispatch => {
    dispatch({
      type: DEVICE_LOCATION_FETCH_GEO,
    });

    var t2
    var t1 = Date.now()

    NavigatorGeolocation.getCurrentPosition((geoPosition) => {
      t2 = Date.now()
      fsConsole.log('geolocation succeeded in',(t2-t1),'ms')
      dispatch({
        type: DEVICE_LOCATION_FETCH_GEO_COMPLETE,
        coords: geoPosition.coords,
      });

      cb && cb(null,geoPosition.coords)
    },
    (error) => {
      t2 = Date.now()
      fsConsole.info('Geolocation failed in '+(t2-t1)+' ms: "'+error.message+'"')
      dispatch({
        type: DEVICE_LOCATION_FETCH_GEO_FAILED,
        error: error,
      });
      cb && cb(error);
    },
    {
      enableHighAccuracy: true,
      timeout: 20000,
      maximumAge: 1000
    })
  }
}

export function fetchGeoPromise(dispatch) {
  return new Promise(function(resolve, reject) {
    dispatch(fetchGeo((err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    }))
  })
}

