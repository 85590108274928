'use strict'

import React from 'react'
import colors from '../../lib/colors'
import { numberFormat } from '../../lib/utils/number'
import insertAtPosition from '../../lib/utils/insertAtPosition'
import View from '../View'

//import SuggestedItems from './SuggestedItems'
//import Autocomplete from './Autocomplete'

import FSText from '../../components/FSText'
import TextInput from '../../components/TextInput'

export default class TextEditor extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || '',
      isValid: this.isValid(this.props.value || ''),
    }
  }

  renderMaxLengthCounter = () => {
    if (this.props.maxLength) {
      var left = this.props.maxLength - this.state.value.length;
      left = numberFormat(left);
      var emptyStyle = this.state.isValid ? null: styles.counterEmpty;
      var weight = this.state.isValid ? 'normal' : 'bold';
      return (
        <View style={styles.maxLengthWrapper}>
          <FSText style={Object.assign({}, styles.counter, emptyStyle)} weight={weight}>{left}</FSText>
        </View>
      )
    }
  };

  isValid = (val) => {
    return !this.props.maxLength || (this.props.maxLength >= val.length);
  };

  handleChangeEvent = (val) => {
    var isValid = this.isValid(val);
    this.setState({
      value: val,
      isValid: isValid,
    });
    this.props.onChange(val, isValid);
  };

  setText = (text) => {
    this.handleChangeEvent(text);
  };

  get hasSuggestedItems() {
    return false; //TODO
    return this.props.suggestedItems && this.props.suggestedItems.length > 0
  }

  renderSuggestedItems() {
    if( ! this.hasSuggestedItems ) return
    return (
      <SuggestedItems
        list={this.props.suggestedItems}
        onPress={(value) => this.insertText(value, this.state.value.length, this.state.value.length)}
      />
    )
  }

  renderAutocompleteArea = () => {
    return; //TODO
    return (
      <Autocomplete
        autocompleteId={this.props.autocompleteId}
        autocompleteContainerStyle={this.props.autocompleteContainerStyle}
        value={this.state.value}
        types={this.props.autocomplete}
        activeStyle={styles.autocomplete}
        inactiveStyle={{height: 0}}
        onComplete={this.handleComplete}
      />
    )
  };

  handleComplete = (type, delimiter, text) => {
    switch(type) {
      case 'user':
        // Get the index of the last @:
        var delimiterPos = this.state.value.lastIndexOf(delimiter)

        // Truncate everything after whitespace:
        var partialComplete = this.state.value.substr(delimiterPos).replace(/\s.*/,'')

        this.insertText( '@' + text + ' ', delimiterPos, delimiterPos + partialComplete.length )
        break

      case 'hashtag':
        // Get the index of the last @:
        var delimiterPos = this.state.value.lastIndexOf(delimiter)

        // Truncate everything after whitespace:
        var partialComplete = this.state.value.substr(delimiterPos).replace(/\s.*/,'')

        // Note that hashtags come back with a hash since that's how they're stored in the search
        // index:
        this.insertText( text + ' ', delimiterPos, delimiterPos + partialComplete.length )
        break
    }
  };

  insertText = (item, start, end) => {
    // This requires native functionality that doesn't exist but is simlar to:
    // https://github.com/facebook/react-native/issues/2478
    //
    // TODO: This should get the start and end of the selection range instead
    // of just using the length of the string twice. That means it currently
    // just appends the text
    var {newText,newCursorPosition} = insertAtPosition(this.state.value, item, start, end, false)
    this.setText(newText)

    setTimeout(() => {
      /*
      if (Platform.OS === 'ios') {
        this.refs.input.setSelectionRange( newCursorPosition, newCursorPosition )
      }
      */
    })
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.container}>
          <TextInput
            ref="input"
            value={this.state.value}
            autoCapitalize={this.props.autoCapitalize || 'sentences'}
            placeholder={this.props.placeholder}
            autoCorrect={this.props.autoCorrect || true}
            onChangeText={this.handleChangeEvent}
            onEnter={() => {
              this.props.onDone && this.props.onDone()
            }}
            underlineColorAndroid="transparent"
            multiline={this.props.multiline !== false}
            autoFocus
            keyboardType={this.props.keyboardType}
            style={styles.input}
          />

          {this.renderMaxLengthCounter()}
          {this.renderSuggestedItems()}
          {this.props.autocomplete && this.props.autocomplete.length > 0 && this.renderAutocompleteArea()}
        </View>
      </View>
    )
  }
}

var styles = {
  container: {
    flex: 1,
    display: 'flex', flexDirection: 'column',
  },
  input: {
    flex: 1,
    padding: 15,
    fontSize: 16,
    textAlignVertical: 'top',
  },
  maxLengthWrapper: {
    padding: 5,
  },
  counter: {
    textAlign: 'right',
    color: colors.mediumGray,
    fontSize: 16,
  },
  counterEmpty: {
    color: colors.errorRed,
  },
  autocomplete: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  }
}
