import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class TextInput extends Component {

  handleKeyPress = (e) => {
    if (this.props.multiline !== true && e.key === 'Enter') {
      if (this.props.onEnter) {
        this.props.onEnter(e.target.value);
      }
      if (this.props.onSubmitEditing) {
        this.props.onSubmitEditing(e.target.value);
      }
    }
  };

  handleChange = (event) => {
    this.props.onChange && this.props.onChange(event);
    this.props.onChangeText && this.props.onChangeText(event.target.value)
  };

  render() {
    if (this.props.multiline === true) {
      return (
        <textarea
          type={(this.props.secureTextEntry || this.props.password) ? 'password' : ''}
          onKeyPress={this.handleKeyPress}
          onChange={this.handleChange}
          {...this.props}
          style={Object.assign({}, {height: 150}, this.props.style)}
        />
      )
    } else {
      return (
        <input
          type={(this.props.secureTextEntry || this.props.password) ? 'password' : ''}
          onKeyPress={this.handleKeyPress}
          onChange={this.handleChange}
          {...this.props}
        />
      );
    }
  }
}

export default TextInput;

var styles = {
}
