import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'
import extendUrlQueryParams from '../lib/utils/extendUrlQueryParams'

class FSImage extends Component {

  componentDidMount() {
  }

  render() {
    var source = this.props.source;

    if (source.uri.indexOf('imgix.net') !== -1) {
      var attrs = this.props.imgixAttrs || {};
      source.uri = extendUrlQueryParams(source.uri, attrs, { });
    }

    return (
      <img src={source.uri} style={this.props.style}/>
    );
  }
}

export default FSImage;

var styles = {
  heading: {
  }
}
