
import deepAssign from 'deep-assign'
import config from '../config';
import dataStore from '../lib/dataStore';

/**
 * Action key that carries API call info interpreted by this Redux middleware.
 */
export const API_CALL = 'API_CALL';

//Utility fn to convert dictionary to "&key=val&key1=val1" query string for POST requests
//Optional dataType=formData leads to id[]=1&id[]=2&id[]=3 style result
function _toQueryString(obj, dataType = null) {
  var parts = [];
  var i;
  if (dataType === 'formData') {
    for (i in obj) {
      if (obj.hasOwnProperty(i)) {
        if (obj[i] instanceof Array) {
          for(var j in obj[i]) {
            parts.push(encodeURIComponent(i + '[]') + '=' + obj[i][j])
          }
        } else {
          parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
        }
      }
    }
  } else {
    for (i in obj) {
      if (obj.hasOwnProperty(i)) {
        parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
      }
    }
  }
  return parts.join("&");
}

/**
 * A Redux middleware that interprets actions with API_CALL info specified.
 * Essentially a wrapper around fetch with some special options and that also
 * persists data payloads received into the dataStore
 */
export default store => next => action => {
  if (!action[API_CALL] ) {
    return next(action);
  }

  //Send this action on
  var returnValue = next(action);

  //Do the api call async
  var state = store.getState();
  var authToken = null; //state.app.authToken;

  var apiCall = action[API_CALL];
  var url = config.env.FS_API_HOST + apiCall.url;
  var timezoneOffsetInMinutes = new Date().getTimezoneOffset();

  //Default options below, caller can override or add
  var options = deepAssign({}, {
    method: "GET",
    headers: {
      "x-foodstand-app-version": config.env.FS_APP_VERSION,
      "x-fs-tz-in-min": timezoneOffsetInMinutes,
      "x-foodstand-platform": 'web',
      "x-foodstand-current-user-id": (state.app && state.app.currentUserId) ? state.app.currentUserId : '',
      //"Authorization": "Bearer " + authToken,
      "Accepts": 'application/json',
      'X-CSRF-Token': $.rails.csrfToken(),
    },
  }, apiCall.options || {});

  //Method
  if (apiCall.method) {
    options.method = apiCall.method;
  }

  //Params/Body
  /*
  if (apiCall.data) {
    if (options.method === 'GET') {
      var qs = _toQueryString(apiCall.data, apiCall.dataType);
      if (qs) {
        url += '?' + qs;
      }
    } else {
      if (apiCall.dataType === 'json') {
        options.headers["Content-Type"] = 'application/json';
        options.body = JSON.stringify(apiCall.data);
      } else {
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        options.body = _toQueryString(apiCall.data, apiCall.dataType);
      }
    }
  }
  */

  // API caller can override this, default assumes root is a payload
  var getDataPayloads;
  if (apiCall.getDataPayloads) {
    getDataPayloads = apiCall.getDataPayloads;
  } else {
    getDataPayloads = (json) => [json];
  }

  // Finally, run the api call
  options.data = apiCall.data;
  options.dataType = apiCall.dataType;
  $.ajax(url, options).then((json, status, raw) => {

    //If no response from server, return empty api response
    if (!json) {
      return {json: null, response: raw}
    }

    // This is a hook we provide in case caller needs to do something
    // before the new data gets injected into the dataStore
    if (apiCall.preprocessResponse) {
      apiCall.preprocessResponse(json);
    }

    // Push the new data into the datastore
    dataStore.pushPayloads(getDataPayloads(json));

    var result = {json: json, response: raw};
    return apiCall.success(result.json, result.response);
  }, (error, xxx, yyy) => {
    var result

    // If api responds with unauthorized, assume something is wrong with auth token and
    // sign the user out immediately.
    if (error.status === 401 && authToken) {
      //next(signOut());
      return;
    }

    if (apiCall.error) {
      result = apiCall.error(error.status, error.responseJSON, error);
    }
    if( config.env.FS_DEBUG ) {
      throw error
    }
    return result
  });

  return returnValue;
};
