import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class FSTouchable extends Component {

  componentDidMount() {
  }

  render() {
    //components.self.js?body=1:25977 Warning: validateDOMNesting(...): <a> cannot appear as a descendant of <a>.
    if (this.props.containsTouchables) {
      return (
        <div className={"acts-as-link " + this.props.className} onClick={this.props.onPress} style={this.props.style}>
          {this.props.children}
        </div>
      );
    }

    return (
      <a onClick={this.props.onPress} style={Object.assign({}, styles.link, this.props.style)} className={this.props.className}>
        {this.props.children}
      </a>
    );
  }
}

export default FSTouchable;

var styles = {
  link: {
    display: 'block',
  }
}
