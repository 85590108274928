'use strict'

import React, { Component, PropTypes } from 'react';
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import colors from '../../../lib/colors'

import NextButton from './NextButton'
import StepCredentials from './StepCredentials'

import FS from '../../../components/FS'
import FSView from '../../../components/FSView'
import FSText from '../../../components/FSText'
import FSScrollView from '../../../components/FSScrollView'
import FSTouchable from '../../../components/FSTouchable'
import UserAvatar from '../../../components/UserAvatar'
import DebouncedTextInput from '../../../components/DebouncedTextInput'
import HeaderArea from '../HeaderArea'

import {
  MIN_USERNAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  changeProfileType,
  updateOnboardingStepIndex,
  updateValue,
  receiveCameraResponse,
  createUser,
  setHeading,
  photoFileSelected,
} from '../../actions/signup'

//var UIImagePickerManager = require('NativeModules').UIImagePickerManager;

const AVATAR_SIZE = 90;
const IMAGE_SIZE = 250;

class StepProfilePic extends FSView {

  state = {
    files: null,
    previewInitialized: false,
  };

  getMyUrl() {
    return 'onboarding/signup/profile-pic'
  }

  componentDidMount() {
    this.props.dispatch(setHeading('Hey '+this.props.username, 'Add a photo so the community knows who you are'));
  }

  componentDidFocus() {
    this.props.dispatch(updateOnboardingStepIndex(4));
  }

  handleNextPress = () => {
    this.props.dispatch(createUser());
  };

  resetPreview() {
    var el = $('.FoodstandAuth-signup-photo-preview');
    el.css({width: 'auto', height: 'auto', 'margin-left': 0, 'margin-right': 0}).hide();
    return el;
  }

  onDrop = (files) => {
    this.resetPreview();

    this.props.dispatch(photoFileSelected(files[0]));

    this.setState({
      files: files,
      previewInitialized: false,
    });

    setTimeout(() => {
      var el = this.resetPreview();
      var w = el.width();
      var h = el.height();
      var size = IMAGE_SIZE;

      //crop out the middle as a square
      var width = (w > h) ? (w / h) * size : size;
      var height = (h > w) ? (h / w) * size : size;
      var leftOffset = -1 * (width - size) / 2;
      var topOffset = -1 * (height - size) / 2;
      el.css({width: width , height: height})
      el.css('margin-left', leftOffset);
      el.css('margin-top', topOffset);
      el.show();
    }, 250);
  };

  onOpenClick = () => {
    this.refs.dropzone.open();
  };

  renderPreviewPhoto = () => {
    if (this.state.files) {
      return (
        <div>
          {this.state.files.map((file) => (
            <img key="img" src={file.preview} className="FoodstandAuth-signup-photo-preview" style={Object.assign({}, styles.preview, this.state.previewInitialized ? {} : {display: 'none'})} />)
          )}
        </div>
      )
    } else if (this.props.remotePhotoTemp) {
      return (
        <div>
          <img key="img" src={this.props.remotePhotoTemp} className="FoodstandAuth-signup-photo-preview" style={Object.assign({}, styles.preview)} />)
        </div>
      )
    }
  }

  renderPhotoCTA(dummyUser) {
    return (
      <div>
        <Dropzone ref="dropzone" onDrop={this.onDrop} accept="image/*" multiple={false}
          style={Object.assign({}, styles.dropzone, this.state.files ? styles.dropzoneWithImage : {})}
          activeStyle={styles.dropzoneActive}
        >
          {this.renderPreviewPhoto()}
          {(!this.state.files && !this.props.remotePhotoTemp) ? (
            <div>
              <FS.Icon style={styles.dropzoneIcon} name='tab-profile' size={40} />
              <FS.Text style={styles.dropzoneText}>Drag & Drop</FS.Text>
              <FS.Touchable type="button" onClick={this.onOpenClick} style={styles.dropzoneButton}>
                Or Browse
              </FS.Touchable>
            </div>
          ) : null}
        </Dropzone>
      </div>
    )
  }

  renderTOS() {
    return (
      <div style={styles.tos}>
        <FSText style={styles.tosText}>By signing up, you are agreeing to our</FSText>
        <a href='http://www.thefoodstand.com/terms-of-service' target="_blank">
          <FSText style={styles.tosUrl}>Terms Of Service</FSText>
        </a>
      </div>
    )
  }

  render() {
    var dummyUser = {
      profile_type_id: this.props.profileTypeId,
      profile_type: dataStore.get('profile_type', this.props.profileTypeId),
      images: [this.props.photoTemp],
    };

    return (
      <div style={Object.assign({}, styles.wrapper, {backgroundColor: this.props.profileColor})}>
        <FSScrollView
          className="FoodstandAuth-contents"
          style={styles.scrollContainer}
          innerRef={(c) => {this._formWrapper = c}}
          ref="formScrollView">

          {<HeaderArea className={'inner-header'}/>}
          {this.renderPhotoCTA(dummyUser)}
          {this.renderTOS()}

        </FSScrollView>
        <NextButton text={this.state.files ? 'FINISH REGISTRATION' : 'SKIP & FINISH'} onPress={this.handleNextPress} active={true}/>
      </div>
    )
  }

}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.signup, { });
}

export default connect(mapStateToProps)(StepProfilePic);

var styles = {
  wrapper: {
    flex: 1,
    backgroundColor: colors.veryLightGray,
  },
  scrollContainer: {
    flex: 1,
    paddingTop: 15,
  },
  tos: {
    marginTop: 5,
    marginBottom: 30,
    opacity: 0.8,
    textAlign: 'center',
  },
  tosText: {
    color: colors.mediumGray,
    display: 'block',
  },
  tosUrl: {
    display: 'block',
    textAlign: 'center',
    fontSize: 12,
    marginTop: 3,
    color: colors.darkBlue,
  },
  dropzone: {
    backgroundColor: colors.lightGray,
    borderRadius: '500px',
    border: '2px dashed ' + colors.gray,
    margin: '20px auto',
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    position: 'relative',
    overflow: 'hidden',
  },
  dropzoneWithImage: {
    border: '2px solid ' + colors.darkGray,
  },
  dropzoneActive: {
    border: '2px solid ' + colors.darkGreen,
  },
  dropzoneIcon: {
    display: 'block',
    marginBottom: 13,
    marginTop: 50,
    fontSize: 40,
    backgroundColor: colors.lightGray,
    width: 75,
    height: 75,
    borderColor: colors.white,
    lineHeight: '73px',
    border: '3px white solid',
    borderBottomWidth: 10,
    marginLeft: 84,
  },
  dropzoneText: {
    display: 'block',
    fontWeight: '500',
    fontSize: 20,
    marginBottom: 5,
  },
  dropzoneButton: {
    fontSize: 12,
    fontWeight: '500',
    color: colors.gray,
    textDecoration: 'underline',
  },
  preview: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  }
}
