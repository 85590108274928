'use strict'

import { connect } from 'react-redux'
import React, { Component, PropTypes } from "react"
import FSText from '../../components/FSText'
import FSScrollView from '../../components/FSScrollView'
import FSTouchable from '../../components/FSTouchable'
import colors from '../../lib/colors'
import dataStore from '../../lib/dataStore'
import View from '../../components/View'

import {
  dismissIngredientLevelTagDetails
} from '../actions/ingredients'

class IngredientLevelTagDetails extends React.Component {

  get ingredientLevelTag() {
    return dataStore.get('ingredient_level_tag', this.props.ingredientLevelTagId)
  }

  dismiss = () => this.props.dispatch(dismissIngredientLevelTagDetails());

  render() {
    return (
      <View style={styles.page}>
        <FSScrollView styles={{flex:1}} contentContainerStyle={styles.scrollContainer}>
          <View style={styles.header}>
            <FSText style={styles.name}>{this.ingredientLevelTag.title}</FSText>
            <FSTouchable style={styles.dismissWrapper} onPress={this.dismiss}>
              <FSText style={styles.dismissText}>Done</FSText>
            </FSTouchable>
          </View>
          <FSText style={styles.text}>
            {this.ingredientLevelTag.definition}
          </FSText>
        </FSScrollView>
      </View>
    )
  }

}

var styles = {
  scrollContainer: {
    paddingTop: 30, paddingBottom: 30,
    paddingLeft: 20, paddingRight: 20,
  },
  page: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex", flexDirection: "column",
    alignItems: 'stretch',
    backgroundColor: 'rgba(0,0,0,0)',
  },
  header: {
    display: "flex", flexDirection: "row",
    alignItems: 'stretch',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  name: {
    color: colors.gray,
    alignSelf: 'flex-start',
    fontWeight: '300',
    fontSize: 28,
  },
  dismissWrapper: {
    alignSelf: 'flex-end'
  },
  dismissText: {
    fontSize: 18,
    color: colors.gray,
  },
  text: {
    color: colors.gray,
    fontWeight: '300',
    fontSize: 18,
    flex: 1,
  }
}


function mapStateToProps(state) {
  return Object.assign({}, state.ingredients, {
    currentUserId: state.app.currentUserId,
  })
}

export default connect(mapStateToProps)(IngredientLevelTagDetails)
