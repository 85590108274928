'use strict'

import React, { Component, PropTypes } from "react"
import IngredientEditor from './IngredientEditor'
import colors from '../../lib/colors'
import dataStore from '../../lib/dataStore'
import Carousel from '../../components/Carousel'
import View from '../../components/View'

export default class IngredientSwiper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ingredientCount: props.ingredientIds.length,
    }
  }

  get ingredients() {
    return dataStore.getMany( 'ingredient', this.props.ingredientIds || [] )
  }

  handleInitialization = (scroller) => {
    scroller.scrollToPage(scroller.state.totalHorizontalPages, 1)
  };

  render() {
    return (
      <Carousel
        {...this.props}
        key={this.ingredients.length}
        containerStyles={[styles.swiper, {width: '100%', flex: 1, flexDirection: 'row'}, this.props.styles]}
        paginationInset={20}
        initialPage={this.ingredients.length}
        onInitialization={this.handleInitialization}
        paginationProps={{
          defaultFillColor: 'transparent',
          borderColor: colors.darkGray,
          activeFillColor: colors.darkGray,
          borderWidth: 1,
          spacing: 8,
          radius: 12,
        }}
      >
        { this.ingredients.map(i => {
          return (
            <View key={i.id} style={styles.page}>
              <IngredientEditor ingredient={i}/>
            </View>
          )
        }) }
      </Carousel>
    )
  }

}

var styles = {
  swiper: {
    flex: 1,
  },
  page: {
    flex: 1,
    justifyContent: 'flex-start',
    display: "flex", flexDirection: "column",
    alignItems: 'stretch',
  }
}
