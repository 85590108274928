'use strict'

import React, { Component, PropTypes } from 'react';
import colors from '../../lib/colors'
import styles from './styles'
import FS from '../FS'

class TagSelector extends React.Component {
  static defaultProps = {
    nameProp: 'name',
    selectedOptionIds: [],
    uppercase: false,
    gutterWidth: 4,
    justify: "flex-start",
    buttonsPerRow: undefined
  };

  get groups () {
    var groups = []
    if (this.isWrap) {
      groups.push(this.props.options)
    } else {
      for (var i = 0; i < this.props.options.length; i += this.props.buttonsPerRow) {
        groups.push(this.props.options.slice(i, i + this.props.buttonsPerRow))
      }
    }
    return groups
  }

  get isWrap () {
    return this.props.buttonsPerRow === undefined
  }

  renderTag (tag, idx, buttonsInGroup) {
    var isActive = this.props.selectedOptionIds.indexOf(tag.id) !== -1
    var text = this.props.uppercase ?
      tag[this.props.nameProp].toUpperCase() :
      tag[this.props.nameProp]

    return (
      <FS.Button
        onPress={() => this.props.onPress(tag)}
        onLongPress={() => {this.props.onLongPress && this.props.onLongPress(tag)}}
        style={Object.assign({}, 
          styles.tag,
          {marginBottom: this.props.gutterWidth, marginRight: this.props.gutterWidth},
          this.isWrap && styles.tagWrap,
          {backgroundColor: this.props.inactiveColor},
          this.props.inactiveStyle,
          isActive && {backgroundColor: this.props.activeColor},
          isActive && this.props.activeStyle,
          (this.props.inactiveStyle && this.props.inactiveStyle.height && !this.props.inactiveStyle.lineHeight) ? {lineHeight: this.props.inactiveStyle.height + 'px'} : {},
          (idx === buttonsInGroup - 1) && styles.tagLast,
          this.props.style
        )}
        key={tag.id}>

        <FS.Text
          style={Object.assign({}, 
            styles.tagText,
            this.props.inactiveTextStyle,
            {color: this.props.inactiveTextColor},
            isActive && {color: this.props.activeTextColor},
            isActive && this.props.activeTextStyle
          )}
          {...this.props.textProps}
        >
          {text}
        </FS.Text>

      </FS.Button>
    )
  }

  renderGroup (group, idx) {
    return (
      <div style={Object.assign({}, styles.tagGroup, this.isWrap && styles.tagGroupWrap, {justifyContent: this.props.justify})} key={idx}>
        {group.map((t, i) => this.renderTag(t, i, group.length))}
      </div>
    )
  }

  render () {
    return (
      <div style={Object.assign({}, styles.container, this.props.style)}>
        {this.groups.map((g, idx) => this.renderGroup(g, idx))}
      </div>
    )
  }
}

export default TagSelector
