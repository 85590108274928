import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class FSIcon extends Component {

  componentDidMount() {
  }

  render() {
    var iconClass = "icon-" + this.props.name + " " + this.props.className;
    var style = Object.assign({}, this.props.style, this.props.color ? {color: this.props.color} : {}, this.props.size ? {fontSize: this.props.size} : {});
    return (
      <span style={style} className={iconClass}>
        {this.props.children}
      </span>
    );
  }
}

export default FSIcon;

var styles = {
}
