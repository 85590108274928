import Analytics from '../lib/analytics'
import fsConsole from '../lib/utils/fs-console'

export default store => next => action => {

  var whitelist = {
    NEW_POST_ACTIVATE: 2,
    NEW_POST_CANCEL: 2,
    NEW_POST_EDIT_PHOTO: 2,
    NEW_POST_UPDATE_PHOTO: 2,
    NEW_POST_OPEN_CAMERA: 2,
    NEW_POST_UPDATE_TITLE: 2,
    NEW_POST_UPDATE_DESCRIPTION: 2,
    NEW_POST_HANDLE_LINK_URL_CHANGE_COMPLETE: 2,
    NEW_POST_HANDLE_LINK_URL_CHANGE_FAILED: 2,
    NEW_POST_DOWNLOAD_SCRAPED_IMAGE: 2,
    NEW_POST_DOWNLOAD_SCRAPED_IMAGE_COMPLETE: 2,
    NEW_POST_ADD_FEATURED_HASH_TAG_TEXT: 2,
    NEW_POST_RECEIVE_CAMERA_RESPONSE: 2,
    NEW_POST_CLOSE_CAMERA: 2,
    NEW_POST_UPLOADING_IMAGE: 2,
    NEW_POST_UPLOADING_IMAGE_COMPLETE: 2,
    NEW_POST_UPLOADING_IMAGE_FAILED: 2,
    NEW_POST_UPLOADING_IMAGE_CANCELED: 2,
    NEW_POST_SUBMIT_POST: 2,
    NEW_POST_SUBMIT_POST_COMPLETE: 2,
    NEW_POST_SUBMIT_POST_FAILED: 2,
    NEW_POST_SUBMIT_POST_DISMISS_ERROR: 2,
    NEW_POST_UPDATE_POST: 2,
    NEW_POST_UPDATE_POST_COMPLETE: 2,
    NEW_POST_UPDATE_POST_FAILED: 2,
    NEW_POST_CLEAR_EXISTING_LINK_POSTS: 2,
    ONBOARDING_SIGN_IN_PASSWORD_RESET: 1,
    ONBOARDING_SIGN_IN_PASSWORD_RESET_COMPLETE: 1,
    ONBOARDING_SIGN_IN_PASSWORD_RESET_ERROR: 1,
    ONBOARDING_SIGN_UP_UPLOAD_PHOTO: 1,
    ONBOARDING_SIGN_UP_UPLOAD_PHOTO_FAILED: 1,
    ONBOARDING_SIGN_UP_UPLOAD_PHOTO_COMPLETE: 1,
    ONBOARDING_SIGN_UP_CREATE_USER: 1,
    ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE: 1,
    ONBOARDING_SOCIAL_AUTH_INSTAGRAM: 1,
    ONBOARDING_SOCIAL_AUTH_INSTAGRAM_COMPLETE: 1,
    ONBOARDING_SOCIAL_AUTH_FOURSQUARE: 1,
    ONBOARDING_SOCIAL_AUTH_FOURSQUARE_COMPLETE: 1,
    ONBOARDING_SOCIAL_AUTH_FACEBOOK: 1,
    ONBOARDING_SOCIAL_AUTH_FACEBOOK_COMPLETE: 1,
  }

  if (whitelist[action.type]) {
    var actionName = action.type;
    var settings = whitelist[action.type];
    var categoryParts = (typeof(settings) === "number") ? settings : settings[0];
    var category = action.type.split('_').splice(0, categoryParts).join("_");
    var traits = {
      category: category,
    }

    //If it's an array, it holds an additional key to fetch label from
    if (typeof(settings) !== "number") {
      if (action[settings[1]]) {
        traits.label = action[settings[1]];
      }
    }

    Analytics.track(actionName, traits);
  }

  return next(action);

};
