'use strict'

import React from 'react';

import FSText from '../../components/FSText';
import FSScrollView from '../../components/FSScrollView';
import FSTouchable from '../../components/FSTouchable';
import FSIcon from '../../components/FSIcon';
import View from '../../components/View';

import ProfileType from '../../models/ProfileType';
import colors from '../../lib/colors';

export default class ProfileTypeEditor extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || '',
    }
  }

  iconForProfileType = (profileType) => {
    return "profiletype-" + profileType.string_id.replace(/_/g, '');
  };

  handleChangeEvent = (id) => {
    this.setState({
      value: id,
    });
    if (this.props.onChange) {
      this.props.onChange(id);
    }
  };

  renderSelected = (pt) => {
    if (pt.id == this.state.value) {
      return (
        <View style={Object.assign({}, styles.selectedIconWrapper, {backgroundColor: colors.forProfileType(pt)})}>
          <FSIcon name="checkmark" size={14}
            style={styles.selectedIcon}
            color={colors.white}
          />
        </View>
      )
    } else {
      return ( <View style={styles.selectedIconSpacer}/> )
    }
  };

  renderProfileType = (pt) => {
    var selected = (pt.id === this.state.value)
    return (
      <FSTouchable
        style={Object.assign({}, 
          styles.profileType,
          selected ? styles.profileTypeSelected : {},
          selected ? {backgroundColor: colors.forProfileType(pt)} : {},
        )}
        key={pt.id}
        onPress={() => this.handleChangeEvent(pt.id)}>

        {this.renderSelected(pt)}

        <FSIcon name={this.iconForProfileType(pt)} size={51} style={styles.profileIcon} color={selected ? colors.white : colors.forProfileType(pt)}/>

        <View style={styles.profileTypeInfo}>
          <FSText style={Object.assign({}, styles.name, selected ? styles.nameSelected : {}, selected ? {} : {color: colors.forProfileType(pt)})}>{pt.label}</FSText>
          <FSText style={Object.assign({}, styles.description, selected ? styles.descriptionSelected : {})}>{pt.description}</FSText>
        </View>

      </FSTouchable>
    )
  };

  render() {
    var types = ProfileType.all();

    return (
      <FSScrollView style={Object.assign({}, styles.container, this.props.style)} automaticallyAdjustContentInsets={false}>
        {types.map(pt => {
          return this.renderProfileType(pt);
        })}
      </FSScrollView>
    )
  }
}

var styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'left',
  },
  profileType: {
    display: 'flex',
    flexDirection: 'row',
    padding: 18,
    borderWidth: 1,
    borderBottomWidth: 0.5,
    borderColor: colors.lightGray,
    alignItems: 'center',
  },
  profileTypeSelected: {
    borderColor: 'transparent',
  },
  profileIcon: {
    marginRight: 18,
  },
  selectedIconSpacer: {
    width: 25,
    marginLeft: -10,
  },
  selectedIconWrapper: {
    alignSelf: 'center',
    marginRight: 5,
    marginLeft: -10,
    width: 20,
    height: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedIcon: {
  },
  profileTypeInfo: {
    flex: 1,
  },
  name: {
    marginBottom: 5,
    fontWeight: '500',
  },
  nameSelected: {
    color: colors.white,
  },
  description: {
    flex: 1,
    fontSize: 14,
    textAlign: 'left',
  },
  descriptionSelected: {
    color: colors.white,
  },
  tipWrap: {

  },
  tipText: {
    color: colors.white,
  },

}
