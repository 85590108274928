'use strict'

import React, { Component, PropTypes } from "react";
import FSIcon from '../../components/FSIcon';
import colors from '../../lib/colors';
import View from '../../components/View'
import DebouncedTextInput from '../../components/DebouncedTextInput'

const constants = {
  iconWidth: 60,
}

class SearchField extends React.Component {
  static defaultProps = {
    minLength: 0,
    icon: 'search-hit',
    iconSize: 1,
  };

  componentWillMount() {
  }

  componentWillUnmount() {
  }

  handleChange = (value) => {
    if( this.props.onInput) this.props.onInput(value)
  };

  handleChangeDebounced = (value) => {
    if( this.props.onUpdate) this.props.onUpdate(value)
  };

  render() {
    return (
      <View style={Object.assign({}, styles.container,this.props.style)}>
        <FSIcon
          name={this.props.icon}
          size={this.props.fontSize*0.5*this.props.iconSize}
          style={styles.icon}>
        </FSIcon>
        <DebouncedTextInput
          ref={this.props.inputRef}
          autoCapitalize="none"
          autoCorrect={false}
          autoFocus={this.props.autoFocus || false}
          placeholder={this.props.placeholder}
          placeholderTextColor={this.props.placeholderTextColor}
          value={this.props.value}
          onChangeText={this.handleChange}
          onChangeTextDebounced={this.handleChangeDebounced}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          style={Object.assign({}, styles.input, {fontSize: this.props.fontSize})}>
        </DebouncedTextInput>
      </View>
    )
  }
}

export default SearchField

var styles = {
  container: {
    display: "flex", flexDirection: "row",
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  icon: {
    width: constants.iconWidth,
    color: colors.darkGray,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    //fontWeight: '700'
  },
  input: {
    flex: 1,
    backgroundColor: colors.white,
    color: colors.darkGray,
    marginRight: 15,
  }
}
