'use strict'

import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux'
import LoadingOverlay from '../../../components/LoadingOverlay'
import FSScrollView from '../../../components/FSScrollView'

import StepProfileType from './StepProfileType'
import StepProfilePic from './StepProfilePic'
import StepCredentials from './StepCredentials'
import NavBar from './NavBar'

class SignUp extends React.Component {

  renderContent() {
    switch(this.props.stepIndex) {
      case 1:
        return (<StepProfileType/>);
        break;
      case 2:
        return (<StepCredentials/>);
        break;
      case 3:
        return (<StepProfilePic/>);
        break;
    }
  }

  render() {
    return (
      <FSScrollView style={styles.container}>
        {this.renderContent()}
        <LoadingOverlay isLoading={this.props.creatingUser}/>
      </FSScrollView>
    )
  }

}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.signup, { });
}

export default connect(mapStateToProps)(SignUp);

var styles = {
  container: {
    flex: 1,
  },
}
