import { defaultReducerMapping } from '../../lib/redux';
import deepFreeze from 'deep-freeze'

var {
  DEVICE_LOCATION_FETCH_GEO,
  DEVICE_LOCATION_FETCH_GEO_COMPLETE,
  DEVICE_LOCATION_FETCH_GEO_FAILED,
} = require('../../actions/deviceLocation');

var initialState = deepFreeze({
  geoLookupInProgress: false,
  geoLookupFailed: false,
  latitude: null,
  longitude: null,
});

//Just return the new/change parts of the state
var map = {};

map[DEVICE_LOCATION_FETCH_GEO] = (state, action) => {
  return {
    geoLookupInProgress: true,
  }
}

map[DEVICE_LOCATION_FETCH_GEO_COMPLETE] = (state, action) => {
  return {
    geoLookupInProgress: false,
    geoLookupFailed: false,
    latitude: action.coords.latitude,
    longitude: action.coords.longitude,
  }
}

map[DEVICE_LOCATION_FETCH_GEO_FAILED] = (state, action) => {
  return {
    geoLookupInProgress: false,
    geoLookupFailed: true,
    latitude: null,
    longitude: null,
  }
}

export default defaultReducerMapping(initialState, map);
