'use strict'

//Just a promise wrapper around LocalStorage so we can leave our other code as is

export default {
  getItem: (key) => {
    return new Promise((resolve, reject) => {
      var val = window.localStorage.getItem(key)
      resolve(val)
    })
  },
  setItem: (key, val) => {
    return new Promise((resolve, reject) => {
      window.localStorage.setItem(key, val)
      resolve()
    })
  },
  removeItem: (key) => {
    return new Promise((resolve, reject) => {
      window.localStorage.removeItem(key)
    })
  },
};
