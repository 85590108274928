'use strict'

export default function filter (set, query, comparator) {

  // Trim, split, and sort in descending order of word length so that
  // hopefully we cut down the matches as quickly as possible
  var queryParts = query
    .trim()
    .split(/\s+/)
    .sort((a,b) => a.length < b.length)

  // Initial list of keys:
  var matched = set

  queryParts.forEach((word) => {
    matched = matched.filter(item => comparator(item,word))
  })

  return matched

}
