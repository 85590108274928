'use strict'

var ActionSheet = {
  showActionSheetWithOptions: function(config, onSelect, ref) {
    var options = config.options || {}
    var cancelButtonIndex = config.cancelButtonIndex
    var menuOptions = options.slice(0)

    if (cancelButtonIndex !== undefined) {
      menuOptions.splice(cancelButtonIndex, 1)
    }

    alert('action sheet needs implementation');
    /*

    UIManager.showPopupMenu(
      findNodeHandle(ref),
      menuOptions,
      function () {
      },
      function (type, index) {
        if (type !== 'itemSelected') {
          return
        }
        if (cancelButtonIndex !== undefined && index >= cancelButtonIndex) {
          index++
        }
        onSelect && onSelect(index)
      }
    )
    */
  }
}

export default ActionSheet
