'use strict'

import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux'
import colors from '../../lib/colors'

import FSView from '../../components/FSView'
import FSText from '../../components/FSText'
import FSScrollView from '../../components/FSScrollView'
import FSIcon from '../../components/FSIcon'
import LoadingOverlay from '../../components/LoadingOverlay'
import FSTouchable from '../../components/FSTouchable'
import TextInput from '../../components/TextInput'

import { passwordReset } from '../actions/signin'

import {
  switchView,
  ONBOARDING_VIEW_KEY_SIGNIN
} from '../actions/base'

class ForgotPassword extends Component {

  getMyUrl() {
    return 'onboarding/forgot-password';
  }

  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email || '',
    };
  }

  onSubmit = () => {
    if (this.state.email) {
      this.props.dispatch(passwordReset(
        this.state.email,
      ));
    }
  };

  renderError(errorText) {
    return (
      <div style={Object.assign({}, styles.inputWrapper, styles.errorWrap)}>
        <FSText style={styles.error}>{errorText}</FSText>
      </div>
    )
  }

  goToSignIn = () => {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SIGNIN));
  };

  renderSuccess() {
    return (
      <div style={styles.formWrapper}>
        <div style={styles.successWrapper}>
          <FSText style={styles.successMessage}>
            An email has been sent to {this.state.email}
          </FSText>
          <FSText style={styles.successMessage}>
            Please follow the instructions to reset your password then Sign In with your new password.
          </FSText>
        </div>
      </div>
    )
  }

  renderForm() {
    return (
      <div style={styles.formWrapper}>

        <div style={styles.inputWrapper}>
          <TextInput
            value={this.state.username}
            autoCapitalize="none"
            placeholder="Email"
            autoCorrect={false}
            autoFocus={true}
            onChangeText={(text) => {
              this.setState({email: text});
            }}
            onSubmitEditing={this.onSubmit}
            clearButtonMode="while-editing"
            returnKeyType="go"
            style={styles.input}
          />
        </div>

        {this.props.passwordResetError && this.renderError("This email was not found")}

        <div style={styles.inputWrapper}>
          <FSTouchable onPress={this.onSubmit} style={styles.signInButtonWrap}>
            <FSText style={styles.signInButton}>Check & Send</FSText>
          </FSTouchable>
        </div>

      </div>
    )
  }

  render() {
    return (
      <FSScrollView
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
        keyboardShouldPersistTaps={true}>

        <FSText style={styles.heading} weight='bold'>Password Reset</FSText>
        <FSTouchable style={styles.closeButton} onPress={this.goToSignIn}>
          <FSIcon name="left-arrow" size={15} color={colors.darkGray}/>
        </FSTouchable>

        {this.props.passwordResetSuccess ? this.renderSuccess() : this.renderForm()}

        <LoadingOverlay isLoading={this.props.checkingPasswordReset}/>

      </FSScrollView>
    );
  }

}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.signin, { });
}

export default connect(mapStateToProps)(ForgotPassword);

var styles = {
  container: {
    flex: 1,
  },
  contentContainer: {
    justifyContent: 'center',
  },
  heading: {
    display: 'block',
    paddingTop: 20,
    fontSize: 20,
    color: colors.mediumGray,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  closeButton: {
    position: 'absolute',
    left: 22,
    top: 13,
    padding: 12,
  },
  formWrapper: {
    padding: 20,
    marginTop: 20,
    marginBottom: 10,
  },
  inputWrapper: {
    padding: 10,
    flexDirection: 'row',
  },
  input: {
    display: 'block',
    height: 40,
    width: 260,
    boxSizing: 'border-box',
    lineHeight: '40px',
    border: 0,
    padding: '0 25px',
    backgroundColor: colors.lightGray,
    borderRadius: 20,
    color: colors.veryDarkGray,
    fontSize: 14,
    textAlign: 'left',
    alignSelf: 'center',
    margin: '10px auto',
    outline: 0,
  },
  signInButtonWrap: {
    borderRadius: 20,
    backgroundColor: '#dF5A48',
    padding: '0 20px',
    flex: 1,
  },
  signInButton: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    alignSelf: 'center',
    height: 40,
    lineHeight: '40px',
  },
  errorWrap: {
    maxWidth: 260,
    backgroundColor: colors.red,
    padding: '12px 25px',
    borderRadius: 4,
    margin: '0 auto 10px',
  },
  error: {
    fontSize: 11,
    color: colors.white,
    width: '100%',
    textAlign: 'center'
  },
  errorNotch: {
    width: 10,
    height: 10,
    position: 'absolute',
    left: '50%',
    top: -3,
    marginLeft: -5,
    transform: 'rotate(45deg)',
  },
  successWrapper: {
    padding: '10px 30px',
    flexDirection: 'column',
  },
  successMessage: {
    textAlign: 'center',
    fontSize: 14,
    color: colors.darkGray,
    marginBottom: 15,
  }
};
