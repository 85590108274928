'use strict'

import React, { Component, PropTypes } from "react"
import FSText from '../../components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from '../../components/FSIcon'
import { truncate } from '../../lib/utils/text'
import colors from '../../lib/colors'
import c from '../constants'
import View from '../../components/View'

import {
  removeAttribute,
  editAttribute,
} from '../actions'

export default class FormLabelLink extends React.Component {

  removeAttribute = () => {
    this.props.dispatch(removeAttribute('link_url'));
  };

  editAttribute = () => {
    this.props.dispatch(editAttribute('link_url'));
  };

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)

    if (!this.props.post.link_url || this.props.post.link_url.length === 0) {
      return (<View/>);
    }

    return (
      <FormLabelWithIcon
        name="link"
        color={colorForCurrentUser}
        placeholder="Link"
        value={truncate(this.props.post.link_url, 40)}
        onPress={this.editAttribute}
        onRemove={this.removeAttribute}
      ></FormLabelWithIcon>
    )
  }
}
