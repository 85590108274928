"use strict"

import React, { Component, PropTypes } from "react"
import { connect } from "react-redux"
import colors from "../../../lib/colors"

import { initCap } from "../../../lib/utils/text"
import Color from "color"

import NextButton from "./NextButton"
import StepProfilePic from "./StepProfilePic"
import RefererSelector from "./RefererSelector"

import LoadingOverlay from "../../../components/LoadingOverlay"
import FS from "../../../components/FS"
import FSScrollView from "../../../components/FSScrollView"
import FSTouchable from "../../../components/FSTouchable"
import DebouncedTextInput from "../../../components/DebouncedTextInput"
import HeaderArea from "../HeaderArea"

import {
  MIN_USERNAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_ZIP_LENGTH,
  changeProfileType,
  updateOnboardingStepIndex,
  openCamera,
  updateValue,
  validateUser,
  locateZip,
  updateReferringUserId,
  nextScreen,
  previousScreen,
  setHeading,
} from "../../actions/signup"

class StepCredentials extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: this.props.email,
      username: this.props.username,
      postal_code: this.props.postal_code,
      password: this.props.password,
      editingReferer: false,
    }
  }

  getMyUrl() {
    return "onboarding/signup/credentials"
  }

  componentDidFocus() {
    this.props.dispatch(updateOnboardingStepIndex(3))
  }

  componentDidMount(props) {
    //this.props.dispatch(changeProfileType(this.props.profileTypeId)); //useful for testing only
    this.props.dispatch(setHeading("TELL US A FEW THINGS SO WE CAN CONNECT YOU WITH THE COMMUNITY"))

    //If values are pre-filled, run validation
    for (var field in this.state) {
      if (this.state[field]) {
        this.props.dispatch(updateValue(field, this.state[field]))
      }
    }

    this.refs["input-username"].refs.input.focus()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userValid && !nextProps.validatingUser && this.props.validatingUser) {
      this.props.dispatch(nextScreen())
    }

    //After user does "locate me"
    if (nextProps.postal_code && this.props.locatingZip && !nextProps.locatingZip) {
      this.setState({
        postal_code: nextProps.postal_code,
      })
    }
  }

  get goodToGo() {
    return (
      this.state.username &&
      this.state.username.length >= MIN_USERNAME_LENGTH &&
      this.state.email &&
      this.state.password &&
      this.state.postal_code
    )
  }

  handleNextPress = () => {
    if (this.goodToGo) {
      this.props.dispatch(validateUser())
    }
  }

  renderSuccess(successText) {
    if (successText) {
      return (
        <div style={Object.assign({}, styles.inputWrapper, styles.successWrap)}>
          <FS.Text style={Object.assign({}, styles.error, styles.success)}>{successText}</FS.Text>
        </div>
      )
    }
  }

  renderError(errorText) {
    if (errorText) {
      errorText = initCap(errorText)
      var bgcolor = Color(colors.red).darken(0.5).hexString()
      return (
        <div style={Object.assign({}, styles.inputWrapper, styles.errorWrap, { backgroundColor: bgcolor })}>
          <div style={Object.assign({}, styles.errorNotch, { backgroundColor: bgcolor })} />
          <FS.Text style={styles.error}>{errorText}</FS.Text>
        </div>
      )
    }
  }

  renderInput(props) {
    var refName = "input-" + props.slug
    var hasError = !!props.errorText
    var successful = !hasError && (props.validIfNoError || props.successText)
    return (
      <div>
        <div style={styles.inputWrapper}>
          <DebouncedTextInput
            ref={refName}
            autoCapitalize="none"
            autoCorrect={false}
            clearButtonMode="while-editing"
            placeholderTextColor={colors.mediumGray}
            {...props}
            onFocus={() => {
              if (props.onFocus) {
                props.onFocus()
              }
            }}
            onChangeText={text => {
              var updates = {}
              updates[props.slug] = text
              this.setState(updates)
            }}
            onEnter={this.handleNextPress}
            style={Object.assign(
              {},
              styles.input,
              successful ? styles.inputSuccess : null,
              hasError ? [styles.inputError, { color: this.props.profileColorDark, fontWeight: "500" }] : null
            )}
          />
          {successful ? (
            <FS.Icon style={styles.inputStatusIcon} size={16} name="checkmark" className="input-status-icon" />
          ) : (
            <div style={styles.inputStatusIcon} />
          )}
          {props.renderInnerContent && props.renderInnerContent()}
        </div>
        {this.renderError(props.errorText)}
        {this.renderSuccess(props.successText)}
      </div>
    )
  }

  toggleRefererModal = () => {
    this.setState({ editingReferer: !this.state.editingReferer })
  }

  renderReferalButton() {
    var refUser = dataStore.get("user", this.props.referringUserId)
    var refStr = refUser ? "@" + refUser.username : this.props.referringSource

    return (
      <FSTouchable
        style={Object.assign({}, styles.input, styles.fakeInput)}
        containsTouchables={true}
        onPress={() => this.toggleRefererModal()}
      >
        {refStr ? (
          <FS.Text style={Object.assign({}, styles.fakeInputText, styles.activeFakeInputText)}>{refStr}</FS.Text>
        ) : (
          <FS.Text style={styles.fakeInputText}>Who referred you?</FS.Text>
        )}

        {refStr ? (
          <FS.Button
            style={styles.fakeInputClear}
            onPress={() => {
              this.props.dispatch(updateReferringUserId(null, null))
            }}
          >
            <FS.Icon name="x-circle" style={styles.fakeInputClearIcon} size={14} />
          </FS.Button>
        ) : (
          <FS.Text family="condensed" style={styles.optionalText}>
            OPTIONAL
          </FS.Text>
        )}
      </FSTouchable>
    )
  }

  renderReferalModal() {
    return (
      <FS.Modal
        visible={this.state.editingReferer}
        fullScreen={true}
        animation="slideInUp"
        closeAnimation="slideOutDown"
        duration={250}
      >
        <RefererSelector
          onCancel={() => this.toggleRefererModal()}
          onSelectUser={(userId, source, query) => {
            this.props.dispatch(updateReferringUserId(userId, source, query))
            this.toggleRefererModal()
          }}
        />
      </FS.Modal>
    )
  }

  renderForm() {
    var emailSeemsValid = false
    var email = this.props.email
    if (
      email &&
      email.indexOf("@") > 0 &&
      email.indexOf(".") > email.indexOf("@") &&
      email.indexOf(".") < email.length - 2
    ) {
      emailSeemsValid = true
    }
    var zipSeemsValid = false
    var zip = this.props.postal_code
    zipSeemsValid = zip && zip.length >= 3

    return (
      <div style={styles.formWrapper}>
        {this.renderInput({
          slug: "username",
          value: this.state.username,
          onChangeTextDebounced: text => {
            this.props.dispatch(updateValue("username", text))
          },
          nextField: "username",
          validIfNoError: this.props.usernameValid && this.state.username.length >= MIN_USERNAME_LENGTH,
          errorText: this.props.usernameError,
          successText:
            this.props.username.length >= MIN_USERNAME_LENGTH && !this.props.usernameError
              ? "You'll be at thefoodstand.com/" + this.props.username
              : null,
          placeholder: "Choose a username",
        })}

        {this.renderInput({
          slug: "email",
          value: this.state.email,
          onChangeTextDebounced: text => {
            this.props.dispatch(updateValue("email", text))
          },
          nextField: "password",
          placeholder: "Add your email",
          validIfNoError: emailSeemsValid,
          errorText: this.props.emailError,
          keyboardType: "email-address",
        })}

        {this.renderInput({
          slug: "password",
          value: this.state.password,
          onChangeTextDebounced: text => {
            this.props.dispatch(updateValue("password", text))
          },
          secureTextEntry: true,
          validIfNoError: this.props.password.length >= MIN_PASSWORD_LENGTH,
          errorText: this.props.passwordError,
          placeholder: "Password",
        })}

        {this.renderInput({
          slug: "postal_code",
          value: this.state.postal_code,
          onChangeTextDebounced: text => {
            this.props.dispatch(updateValue("postal_code", text))
          },
          nextField: "referrer",
          placeholder: "Zipcode",
          validIfNoError: zipSeemsValid,
          errorText: this.props.postal_codeError,
          keyboardType: "numbers-and-punctuation",
          onFocus: () => {
            //this.props.dispatch(locateZip())
          },
        })}

        {this.renderReferalButton()}
        {this.renderReferalModal()}
      </div>
    )
  }

  render() {
    return (
      <div style={Object.assign({}, styles.wrapper)}>
        <FSScrollView
          className="FoodstandAuth-contents"
          style={styles.scrollContainer}
          innerRef={c => {
            this._formWrapper = c
          }}
          ref="formScrollView"
        >
          {<HeaderArea className={"inner-header"} />}
          {this.renderForm()}
        </FSScrollView>
        <NextButton onPress={this.handleNextPress} active={this.goodToGo} />
        <LoadingOverlay isLoading={this.props.validatingUser || this.props.locatingZip} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.signup, {})
}

export default connect(mapStateToProps)(StepCredentials)

var styles = {
  wrapper: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 4,
  },
  scrollContainer: {
    flex: 1,
    paddingTop: 15,
  },
  heading: {
    color: colors.gray,
    textAlign: "center",
    fontSize: 16,
    margin: "0 10px 25px 0",
  },
  formWrapper: {
    borderRadius: 4,
    overflow: "hidden",
    padding: "15px 10px",
    backgroundColor: colors.white,
  },
  inputWrapper: {
    position: "relative",
    width: "100%",
    boxSizing: "border-box",
  },
  input: {
    display: "block",
    height: 40,
    width: 260,
    boxSizing: "border-box",
    lineHeight: "40px",
    border: 0,
    padding: "0 25px",
    backgroundColor: colors.lightGray,
    borderRadius: 20,
    color: colors.veryDarkGray,
    fontSize: 14,
    textAlign: "left",
    alignSelf: "center",
    margin: "10px auto",
    outline: 0,
  },
  inputStatusIcon: {
    position: "absolute",
    right: 20,
    top: 15,
    color: colors.green,
  },
  inputSuccess: {
    //color: colors.successGreen,
  },
  inputError: {
    //color: colors.red,
  },
  errorWrap: {
    maxWidth: 260,
    backgroundColor: colors.red,
    padding: "12px 25px",
    borderRadius: 4,
    margin: "0 auto 10px",
  },
  error: {
    fontSize: 11,
    color: colors.white,
    width: "100%",
    textAlign: "center",
  },
  errorNotch: {
    width: 10,
    height: 10,
    position: "absolute",
    left: "50%",
    top: -3,
    marginLeft: -5,
    transform: "rotate(45deg)",
  },
  successWrap: {
    padding: "0px 25px",
    backgroundColor: colors.white,
    marginBottom: 10,
  },
  success: {
    fontSize: 11,
    color: colors.gray,
    width: "100%",
    textAlign: "center",
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray,
  },
  tos: {
    marginTop: 5,
    opacity: 0.5,
    marginBottom: 30,
  },
  tosText: {
    textAlign: "center",
    color: colors.gray,
    fontSize: 12,
  },
  tosUrl: {
    textAlign: "center",
    fontSize: 12,
    marginTop: 3,
    color: colors.darkBlue,
  },
  locateMeWrap: {
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  locateMeIcon: {
    color: colors.gray,
    marginBottom: 5,
  },
  locateMeText: {
    color: colors.gray,
    fontSize: 11,
  },
  fakeInput: {
    display: "block",
  },
  fakeInputText: {
    display: "inline",
    fontSize: 14,
    color: colors.gray,
    textAlign: "left",
    width: "100%",
    lineHeight: "40px",
  },
  fakeInputClear: {
    float: "right",
    padding: "0 10px",
    marginRight: -10,
  },
  fakeInputClearIcon: {
    color: colors.lightMediumGray,
  },
  activeFakeInputText: {
    color: colors.black,
  },
  optionalText: {
    fontSize: 11,
    color: colors.lightMediumGray,
    marginRight: -12,
    float: "right",
  },
}
