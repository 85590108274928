'use strict'

import React from 'react'
import FSText from './FSText'
import View from './View'

export default class CarouselIndicator extends React.Component {
  static defaultProps = {
    defaultFillColor: 'blue',
    activeFillColor: 'red',
    borderColor: 'green',
    borderWidth: 1,
    spacing: 10,
    radius: 10,
  };

  renderIndicator = (x,i) => (
    <View
      style={Object.assign({}, this.indicatorStyle(i), (i+1)===this.props.currentPage && this.activeIndicatorStyle)}
      key={`pagination-${i}`}
    />
  );

  indicatorStyle (i) {
    return {
      width: this.props.radius,
      height: this.props.radius,
      borderRadius: this.props.radius * 0.5,
      backgroundColor: this.props.defaultFillColor,
      borderWidth: this.props.borderWidth,
      borderColor: this.props.borderColor,
      marginLeft: i === 0 ? 0 : this.props.spacing
    }
  }

  get activeIndicatorStyle () {
    return {
      backgroundColor: this.props.activeFillColor,
      borderColor: this.props.activeBorderColor || this.props.borderColor,
    }
  }


  renderPagination = () => (
    new Array(this.props.totalPages).fill(0).map(this.renderIndicator)
  );

  render () {
    if (Number.isNaN(this.props.totalPages) ||
        Number.isNaN(this.props.currentPage) ||
        this.props.totalPages < 1) {
      return <View/>
    } else {
      return (
        <View style={Object.assign({}, styles.indicators, this.props.styles)}>
          {this.props.totalPages > 1 && this.renderPagination()}
        </View>
      )
    }
  }
}

var styles = {
  indicators: {
    display: "flex", flexDirection: "row",
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
}
