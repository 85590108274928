import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import Start from './Start';
import FS from '../../components/FS';
import LoadingOverlay from '../../components/LoadingOverlay';
import colors from '../../lib/colors';
import HeaderArea from './HeaderArea';
import initializeFacebookSDK from '../../lib/facebook';
import { checkForOauthRedirect } from '../actions/socialAuth'
import { hideAuth, showAuth } from '../actions/base'

class FoodstandAuth extends Component {

  componentDidMount() {
    this.props.dispatch(checkForOauthRedirect());
    if (location.hash.indexOf('activate-sign-up') !== -1) {
      this.props.dispatch(showAuth(this.props.buddyRef));
    }
    if (location.hash.indexOf('submit-new-post') !== -1) {
      var authDialog = $('.foodstand-auth-dialog');
      authDialog.find('.FoodstandAuth-intro-msg').html('Please Sign In Before Posting');
      this.props.dispatch(showAuth(this.props.buddyRef));
    }
  }

  turnOffAuth = () => {
    this.props.dispatch(hideAuth());
  }

  render() {
    return (
      <div className="FoodstandAuth">
        <div className="FoodstandAuth-background"/>

        {<HeaderArea/>}

        <div className="FoodstandAuth-contents-wrapper">
          <Start initialView={this.props.initialView} buddyRef={this.props.buddyRef} />
          <LoadingOverlay isLoading={this.props.base.isLoading}/>
        </div>

        <FS.Touchable className="FoodstandAuth-remover" onPress={this.turnOffAuth}>
          <FS.Icon name="x-square" size={20} color={colors.white}/>
        </FS.Touchable>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state, { });
}
export default connect(mapStateToProps)(FoodstandAuth);

var styles = {
}
