'use strict'

import React, { Component, PropTypes } from "react";
import FSScrollView from '../FSScrollView'
import CarouselPagination from '../CarouselPagination'
import View from '../View'

export default class Carousel extends React.Component {
  static defaultProps = {
    paginationInset: 50
  };

  state = {};

  handlePageChange = (state) => {
    this.props.onPageChange && this.props.onPageChange(state)
    this.setState(state)
  };

  render () {
    return (
      <View style={this.props.containerStyles}>
        <FSScrollView
          {...this.props}
          showsHorizontalScrollIndicator={false}
          pagingEnabled={true}
          horizontal={true}
          onPageChange={this.handlePageChange}
        >
          {this.props.children}
        </FSScrollView>
        {<CarouselPagination
          {...(this.props.paginationProps || {})}
          currentPage={this.state.currentHorizontalPage}
          totalPages={this.state.totalHorizontalPages}
          styles={[styles.pagination, {bottom: this.props.paginationInset}]}
        />}
      </View>
    )
  }
}

var styles = {
  pagination: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  }
}
