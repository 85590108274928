'use strict'

import React, { Component, PropTypes } from "react";
import FSTouchable from '../../../components/FSTouchable'
import FSIcon from '../../../components/FSIcon'
import colors from '../../../lib/colors'
import View from '../../../components/View'

class SocialButton extends React.Component {

  get color() {
    return {
      facebook: colors.facebookBlue,
      instagram: colors.instagramBrown,
      twitter: colors.twitterBlue
    }[this.props.network];
  }

  toggle = () => {
    this.props.onPress( this.props.network );
  };

  render() {
    return (
      <FSTouchable style={Object.assign({}, this.props.style, this.props.active && styles[this.props.network+'Active'])} onPress={this.toggle}>
        <FSIcon style={Object.assign({}, styles.socialButtonIcon,styles[this.props.network], this.props.active && styles.textActive)} name={this.props.network}></FSIcon>
      </FSTouchable>
    )
  }
}


export default class FormShareBar extends React.Component {

  render() {
    return (<View/>)
    return (
      <View style={Object.assign({}, styles.socialButtons,this.props.style)}>
        <SocialButton network="facebook"  style={styles.socialButton} onPress={this.props.onPress} active={this.props.shareVia.facebook}>Facebook</SocialButton>
        <SocialButton network="twitter"   style={styles.socialButton} onPress={this.props.onPress} active={this.props.shareVia.twitter}>Twitter</SocialButton>
        {this.props.post.image_url ? (
          <SocialButton network="instagram" style={styles.socialButton} onPress={this.props.onPress} active={this.props.shareVia.instagram}>Instagram</SocialButton>
        ) : null}
      </View>
    )
  }
}

var styles = {

  textActive: {
    color: '#fff'
  },

  facebookActive: {
    backgroundColor: colors.facebookBlue,
  },

  twitterActive: {
    backgroundColor: colors.twitterBlue,
  },

  instagramActive: {
    backgroundColor: colors.instagramBrown,

    // This weirdness is because flexbox appears to leave a 1px gap if the width is not divisible
    // by three, which seems surprising. Only happens on the 5s.
    borderLeftWidth: 1,
    borderLeftColor: colors.instagramBrown,
    marginLeft: -1,
  },

  socialButtons: {
    backgroundColor: colors.lightMediumGray,
    display: "flex", flexDirection: "row",
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },

  socialButton: {
    flex: 1,
    backgroundColor: colors.lightMediumGray,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },

  socialButtonIcon: {
    color: colors.gray,
    textAlign: 'center',
    fontSize: 22
  },

  facebook: { },
  twitter: { fontSize: 26 },
  instagram: { }

}
