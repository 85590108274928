import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import Start from './Start';
import FS from '../../components/FS';
import LoadingOverlay from '../../components/LoadingOverlay';
import colors from '../../lib/colors';
import bootstrap from '../../lib/bootstrap';
import initializeFacebookSDK from '../../lib/facebook';
import {
  setupCurrentUser,
  fetchFeaturedHashTag,
  checkInitialValuesFromUrl,
  editPost,
  bootstrapComplete,
} from '../actions'

var bootstrapped = false

class FoodstandNewPost extends Component {

  showUI = (postId) => {
    var dialog = $('.foodstand-new-post-dialog');
    if (dialog && dialog.length > 0) {
      this.initializeNewPostInfo(postId);
      dialog.show();
    }
  }

  afterInitialization = (postId) => {
    bootstrapped = true;
    this.props.dispatch(bootstrapComplete())
    if (postId) {
      this.props.dispatch(editPost(postId))
    }
  };

  initializeNewPostInfo = (postId) => {
    this.props.dispatch(setupCurrentUser());

    if (!bootstrapped) {
      bootstrap(null, true).then(() => {
        this.afterInitialization(postId);
      });
      this.props.dispatch(fetchFeaturedHashTag())
    } else {
      this.afterInitialization(postId);
    }

    this.props.dispatch(checkInitialValuesFromUrl())
  };

  componentDidMount() {
    var dialog = $('.foodstand-new-post-dialog');
    if (dialog && dialog.length > 0) {
      $('.new-post-cta').click((e) => {
        e.preventDefault();
        this.showUI();
      });
      $('.post-edit-cta').click((e) => {
        e.preventDefault();
        var postId = $(e.target).data('post-id');
        this.showUI(postId);
      });
      if (location.hash.indexOf('submit-new-post') !== -1) {
        this.showUI();
      }
    } else {
      this.initializeNewPostInfo();
    }
  }

  hideNewPost = () => {
    this.props.dispatch(hideAuth());
    var newPost = $('.foodstand-new-post-dialog')
    newPost.hide();
  }

  render() {
    return (
      <div className="FoodstandNewPost">
        <div className="FoodstandNewPost-background"/>

        <div className="FoodstandNewPost-contents-wrapper">
          <Start/>
          <LoadingOverlay isLoading={this.props.base.isLoading}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state, { });
}
export default connect(mapStateToProps)(FoodstandNewPost);

var styles = {
}
