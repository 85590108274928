'use strict'

import React, { Component, PropTypes } from "react";
import Dropzone from 'react-dropzone'
import Analytics from '../../lib/analytics'
import FSImage from '../../components/FSImage'
import FSScrollView from '../../components/FSScrollView'
import FSButton from '../../components/FSButton'
import FSText from '../../components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from '../../components/FSIcon'
import colors from '../../lib/colors'
import c from '../constants'
import View from '../../components/View'

import {
  cancelUpload,
  uploadImage,
  updatePhoto,
  clearPhoto,
//editAttribute,
  photoFileSelected,
  cancelEditingAttribute,
  doneEditingAttribute,
  openCamera,
} from '../actions'

var imageWidth = '100%'

export default class FormLabelPhoto extends React.Component {

  state = {
    files: null,
    previewInitialized: false,
  };

  componentWillReceiveProps(nextProps) {
    if( nextProps.post.image_url !== this.props.post.image_url) {
      this.centerPhoto()
    }
  }

  centerPhoto = () => {
    //Scroll to the center of the photo in the smaller container
    setTimeout(() => {
      this._photoWrapper && this._photoWrapper.scrollTo({y: 200});
    })
  };

  removeAttr = () => {
    this.props.dispatch(clearPhoto());
  };

  editAttr = () => {
//var currentUser = dataStore.get('user', this.props.currentUserId)
//  this.props.dispatch(openCamera(colors.forUser(currentUser) || colors.gray));
  };

  onDrop = (files) => {
    this.props.dispatch(photoFileSelected(files[0]));

    this.setState({
      files: files,
    });
  };

  onOpenClick = () => {
    this.refs.dropzone.open();
  };

  renderPreviewPhoto = () => {
    if (this.props.pendingFileUpload) {
      return (
        <div>
          <img key="img" src={this.props.pendingFileUpload.preview} className="FoodstandNewPost-photo-preview" style={Object.assign({}, styles.preview)} />
        </div>
      )
    }
  }

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)
    var uri = this.props.post.image_url

    return (
      <Dropzone ref="dropzone" onClick={this.onOpenClick} onDrop={this.onDrop} accept="image/*" multiple={false}
          style={Object.assign({}, styles.wrapper, this.state.files ? styles.dropzoneWithImage : {})}
          className="Dropzone"
          activeStyle={styles.dropzoneActive}
        >
        <FormLabelWithIcon
          name="photo"
          color={colorForCurrentUser}
          value={(this.props.post.image_url || this.props.pendingFileUpload) ? "Photo" : ""}
          placeholder="Photo"
          onPress={this.editAttr}
          onRemove={this.removeAttr}
          isLoading={this.props.processingRemoteImage}
        ></FormLabelWithIcon>

        {this.props.post.image_url && (
          <View style={styles.photoInnerWrapper}>
            <FSScrollView innerRef={(c) => {this._photoWrapper = c}} style={styles.photoWrapper}>
              <FSImage
                source={{uri: uri}}
                style={styles.photo}
              />
            </FSScrollView>
          </View>
        )}

        {this.props.pendingFileUpload && (
          <View style={styles.photoInnerWrapper}>
            <FSScrollView innerRef={(c) => {this._photoWrapper = c}} style={styles.photoWrapper}>
              {this.renderPreviewPhoto()}
            </FSScrollView>
          </View>
        )}

      </Dropzone>
    )
  }
}

var styles = {
  wrapper: {
    position: 'relative',
  },
  photoInnerWrapper: {
    height: 80,
    overflowY: 'auto',
  },
  photoWrapper: {
  },
  photo: {
    backgroundColor: 'white',
    width: imageWidth,
    height: 'auto',
  },
  editButton: {
    justifyContent: 'center',
    position: 'absolute',
    right: 10,
    bottom: 10,
    backgroundColor: 'rgba(0,0,0,0.5)',
    paddingLeft: 15, paddingRight: 15,
    height: 35,
    borderRadius: 3,
  },
  editButtonText: {
    color: colors.white,
    fontWeight: '700',
  },
  dropzoneActive: {
    border: '1px '+colors.lightGreen+' solid',
  },
}
