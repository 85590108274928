import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'
import ActivityIndicator from './ActivityIndicator'

class LoadingOverlay extends Component {

  componentDidMount() {
  }

  render() {
    if (!this.props.isLoading) {
      return (<span/>);
    }

    return (
      <div style={styles.container}>
        <div style={styles.indicator}>
          <ActivityIndicator/>
        </div>
      </div>
    );
  }
}

export default LoadingOverlay;

var styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    color: colors.white,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'center',
    padding: 20,
  },
  indicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
  },
}
