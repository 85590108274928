'use strict'

import dataStore from '../lib/dataStore'

class UserLevelTag {
}

// Return this as a list since it's not inconceivable that the order
// could matter at some point in the future:
UserLevelTag.all = function() {
  var tags = dataStore.getAll('user_level_tag')
  var results = []
  for(let key in tags) {
    if( tags.hasOwnProperty(key) ) {
      results.push(tags[key])
    }
  }
  return results
}

UserLevelTag.findAll = function(ids) {
  if( ! ids ) return []
  return ids.map(id => dataStore.get('user_level_tag',id) ).filter(x => !!x)
}

window.UserLevelTag = UserLevelTag

export default UserLevelTag
