'use strict'

import React, { Component, PropTypes } from "react"
import View from './View'

export default class FadeInOutView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      removed: false,
    }
  }

  componentDidMount() {
    this.sync(this.props)
  }

  /*waitForDismiss() {
    this.state.fadeAnim.addListener((value) => {
    })
  }*/

  componentWillReceiveProps(props) {
    this.sync(props)
  }

  sync(props) {
    if( this.state.visible && ! props.visible ) {
      this.hide()
      this.setState({visible: false})
    } else if ( ! this.state.visible && props.visible ) {
      this.reveal()
      this.setState({visible: true})
    }
  }

  componentWillUnmount() {
    if( this.timeout ) clearTimeout(this.timeout)
  }

  reveal() {
    this.setState({removed: false})
  }

  hide() {
    setTimeout(() => {
      this.setState({removed: true})
      if( this.props.afterDismiss ) this.props.afterDismiss()
    }, 500)
  }

  render() {
    if (this.state.removed) return <View/>
    return (
      <View {...this.props}
        style={Object.assign({}, this.props.style)}>
        {this.props.children}
      </View>
    )
  }
}
