import React, { Component, PropTypes } from 'react';
import colors from '../lib/colors'

class DebouncedTextInput extends Component {

  debounceTime = 350;

  handleChange(event) {
    var text = event.target.value;
    if (this.props.onChangeText) {
      this.props.onChangeText(text);
    }
    clearTimeout(this.debounceTimout);
    this.debounceTimout = setTimeout(() => {
      if (this.props.onChangeTextDebounced) {
        this.props.onChangeTextDebounced(text);
      }
    }, this.debounceTime)
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (this.props.onEnter) {
        setTimeout(() => {
          this.props.onEnter();
        }, this.debounceTime);
      }
      if (this.props.onSubmitEditing) {
        setTimeout(() => {
          this.props.onSubmitEditing(e.target.value);
        }, this.debounceTime);
      }
    }
  };

  render() {
    return (
      <input
        ref='input'
        type={(this.props.secureTextEntry || this.props.password) ? 'password' : ''}
        onChange={this.handleChange.bind(this)}
        onKeyPress={this.handleKeyPress}
        {...this.props}
      />
    );
  }
}

export default DebouncedTextInput;

var styles = {
}
