'use strict'

import React from 'react';
import colors from '../lib/colors';

import FSText from './FSText';
import FSIcon from './FSIcon';
import FSTouchable from './FSTouchable';

var c = {
  height: 44,
}

export default class ModalHeader extends React.Component {
  renderTitle = () => {
    // This component is only wrapped because it seems to be way easier
    // to center text vertically if wrapped (as compared to FSText taking
    // up the whold space and messing with lineHeight/padding/etc)
    return (
      <div style={styles.titleWrapper}>
        <FSText style={styles.title} weight="bold" numberOfLines={1}>{this.props.title}</FSText>
      </div>
    )
  };

  renderIcon = (name, size = 16, color = colors.gray, style = {}) => {
    return (
      <FSIcon
        name={name}
        color={color}
        style={Object.assign({}, styles.buttonIcon, style)}
        size={size}
      />
    )
  };

  render() {
    return (
      <div style={Object.assign({}, styles.modalHeader, this.props.style)}>
        <FSTouchable onPress={this.props.onPressLeft} style={Object.assign({}, styles.button,styles.leftButtonWrap)}>
          {this.props.leftText ? (
            <FSText style={Object.assign({}, styles.buttonText, this.props.buttonTextStyle)} weight='bold'>{this.props.leftText}</FSText>
          ): (<div/>)}
          {this.props.leftIcon ? (
            this.renderIcon(this.props.leftIcon, this.props.leftIconSize, this.props.leftIconColor, this.props.leftIconStyle)
          ): (<div/>)}
        </FSTouchable>

        {this.renderTitle()}

        <FSTouchable onPress={this.props.onPressRight} style={Object.assign({}, styles.button,styles.rightButtonWrap)}>
          {this.props.rightText ? (
            <FSText style={Object.assign({}, styles.buttonText, this.props.buttonTextStyle)} weight='bold'>{this.props.rightText}</FSText>
          ): (<div/>)}
          {this.props.rightIcon ? (
            this.renderIcon(this.props.rightIcon, this.props.rightIconSize, this.props.rightIconColor, Object.assign({}, styles.rightIconStyle, this.props.rightIconStyle))
          ): (<div/>)}
        </FSTouchable>
      </div>
    )
  }
}

var styles = {
  modalHeader: {
    backgroundColor: colors.lightGray,
    display: "flex", flexDirection: "row",
    height: c.height,
    alignItems: 'stretch',
  },
  titleWrapper: {
    flex: 1,
    justifyContent: 'center',
    lineHeight: c.height+'px',
  },
  title: {
    textAlign: 'center',
    color: colors.gray,
    fontWeight: '300',
  },
  button: {
    padding: 15,
    justifyContent: 'center',
  },
  buttonIcon: {
  },
  rightButtonIcon: {
    color: colors.green,
  },
  leftButtonWrap: {
    alignItems: 'flex-start',
  },
  rightButtonWrap: {
    alignItems: 'flex-end',
  },
  buttonText: {
    color: colors.gray,
  }
}
