'use strict'

import fsConsole from './utils/fs-console'
import dataStore from './dataStore'
import AsyncStorage from './AsyncStorage'
import config from '../config';

const BOOTSTRAP_URL = '/bootstrap'

function headerToNumber(val) {
  if (Array.isArray(val) && val.length > 0) {
    val = val[0]
  }
  if (val) {
    return Number(val)
  }
  return null;
}

export default function bootstrap (currentRevision, force) {
  return new Promise((resolve, reject) => {
    var url = config.env.FS_API_HOST + BOOTSTRAP_URL
    currentRevision = headerToNumber(currentRevision)

    var options = {
      method: 'GET',
      headers: {
        "x-foodstand-app-version": config.env.FS_APP_VERSION,
        "Accepts": 'application/json',
      }
    }

    AsyncStorage.getItem('bootstrap-revision').then((r) => {
      var revision = Number(r)

      if (r === undefined || r === null || r < currentRevision || force) {

        if (config.env.FS_DEBUG) {
          fsConsole.log(`%c Bootstrap revision was #${revision}. Fetching revision #${currentRevision}`, 'background: #8bf')
        }

        $.ajax(url, options).then((json, status, response) => {
          if (config.env.FS_DEBUG) {
            fsConsole.groupCollapsed(`%c Bootstrap revision #${currentRevision} fetched. Pushing payload.`, 'background: #8bf')
            fsConsole.log(json)
            fsConsole.groupEnd()
          }

          AsyncStorage.setItem('bootstrap-data', JSON.stringify(json))

          dataStore.wipeAndPushPayload(json)

          var newRev = 0
          var rev = response.getResponseHeader('x-fs-bootstrap-revision')
          if (rev) {
            newRev = headerToNumber(rev)
          }

          AsyncStorage.setItem('bootstrap-revision', newRev.toString())
          resolve()
        })
      } else {
        resolve()
      }
    })
  });
}
