import { defaultReducerMapping } from '../../lib/redux';

import deepFreeze from 'deep-freeze'

var {
  ONBOARDING_SIGN_IN_CHECK_CREDENTIALS,
  ONBOARDING_SIGN_IN_RECEIVE_RESPONSE,
  ONBOARDING_SIGN_IN_RECEIVE_ERROR,
  ONBOARDING_SIGN_IN_PASSWORD_RESET,
  ONBOARDING_SIGN_IN_PASSWORD_RESET_COMPLETE,
  ONBOARDING_SIGN_IN_PASSWORD_RESET_ERROR,
  ONBOARDING_SIGN_IN_RESET_STATE,
} = require('../actions/signin');

var initialState = deepFreeze({
  signInError: null,
  checkingSignIn: false,
  invalidCredentials: false,
  checkingPasswordReset: false,
  passwordResetSuccess: false,
  passwordResetError: false,
});

//Just return the new/change parts of the state
var map = {};

map[ONBOARDING_SIGN_IN_CHECK_CREDENTIALS] = (state, action) => {
  return {
    checkingSignIn: true,
  }
}

map[ONBOARDING_SIGN_IN_RECEIVE_RESPONSE] = (state, action) => {
  return {
    checkingSignIn: false,
  };
}

map[ONBOARDING_SIGN_IN_RECEIVE_ERROR] = (state, action) => {
  return {
    checkingSignIn: false,
    invalidCredentials: (action.status === 401),
  };
}

map[ONBOARDING_SIGN_IN_PASSWORD_RESET] = (state, action) => {
  return {
    checkingPasswordReset: true,
    passwordResetSuccess: false,
    passwordResetError: false,
    invalidCredentials: false,
  }
}

map[ONBOARDING_SIGN_IN_PASSWORD_RESET_COMPLETE] = (state, action) => {
  return {
    checkingPasswordReset: false,
    passwordResetSuccess: true,
    passwordResetError: false,
  }
}

map[ONBOARDING_SIGN_IN_PASSWORD_RESET_ERROR] = (state, action) => {
  return {
    checkingPasswordReset: false,
    passwordResetSuccess: false,
    passwordResetError: true,
  }
}

map[ONBOARDING_SIGN_IN_RESET_STATE] = (state, action) => {
  return initialState;
}

export default defaultReducerMapping(initialState, map);
