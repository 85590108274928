//var FileOperations = require('NativeModules').FileOperations;
//import { FBSDKLoginManager } from 'react-native-fbsdklogin';
//import { FBSDKAccessToken, FBSDKGraphRequest, FBSDKGraphRequestManager } from 'react-native-fbsdkcore';
//var SafariView = require('NativeModules').SafariViewManager;
//import config from 'app/config';
//import fsConsole from 'app/lib/utils/fs-console'

//import Linking from 'app/components/Linking'

import initializeFacebookSDK from '../../lib/facebook';

export const ONBOARDING_SOCIAL_AUTH_INSTAGRAM = 'ONBOARDING_SOCIAL_AUTH_INSTAGRAM';
export const ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FETCH_USER_INFO = 'ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FETCH_USER_INFO';
export const ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FETCH_USER_INFO_COMPLETE = 'ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FETCH_USER_INFO_COMPLETE';
export const ONBOARDING_SOCIAL_AUTH_INSTAGRAM_GET_TOKEN = 'ONBOARDING_SOCIAL_AUTH_INSTAGRAM_GET_TOKEN';
export const ONBOARDING_SOCIAL_AUTH_INSTAGRAM_COMPLETE = 'ONBOARDING_SOCIAL_AUTH_INSTAGRAM_COMPLETE';
export const ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FAILED = 'ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FAILED';

export const ONBOARDING_SOCIAL_AUTH_FOURSQUARE = 'ONBOARDING_SOCIAL_AUTH_FOURSQUARE';
export const ONBOARDING_SOCIAL_AUTH_FOURSQUARE_FETCH_USER_INFO = 'ONBOARDING_SOCIAL_AUTH_FOURSQUARE_FETCH_USER_INFO';
export const ONBOARDING_SOCIAL_AUTH_FOURSQUARE_FETCH_USER_INFO_COMPLETE = 'ONBOARDING_SOCIAL_AUTH_FOURSQUARE_FETCH_USER_INFO_COMPLETE';
export const ONBOARDING_SOCIAL_AUTH_FOURSQUARE_GET_TOKEN = 'ONBOARDING_SOCIAL_AUTH_FOURSQUARE_GET_TOKEN';
export const ONBOARDING_SOCIAL_AUTH_FOURSQUARE_COMPLETE = 'ONBOARDING_SOCIAL_AUTH_FOURSQUARE_COMPLETE';

export const ONBOARDING_SOCIAL_AUTH_FACEBOOK = 'ONBOARDING_SOCIAL_AUTH_FACEBOOK';
export const ONBOARDING_SOCIAL_AUTH_FACEBOOK_FETCH_USER_INFO = 'ONBOARDING_SOCIAL_AUTH_FACEBOOK_FETCH_USER_INFO';
export const ONBOARDING_SOCIAL_AUTH_FACEBOOK_FETCH_USER_INFO_COMPLETE = 'ONBOARDING_SOCIAL_AUTH_FACEBOOK_FETCH_USER_INFO_COMPLETE';
export const ONBOARDING_SOCIAL_AUTH_FACEBOOK_GET_TOKEN = 'ONBOARDING_SOCIAL_AUTH_FACEBOOK_GET_TOKEN';
export const ONBOARDING_SOCIAL_AUTH_FACEBOOK_COMPLETE = 'ONBOARDING_SOCIAL_AUTH_FACEBOOK_COMPLETE';

export const ONBOARDING_SOCIAL_AUTH_DOWNLOAD_PHOTO = 'ONBOARDING_SOCIAL_AUTH_DOWNLOAD_PHOTO';
export const ONBOARDING_SOCIAL_AUTH_DOWNLOAD_PHOTO_COMPLETE = 'ONBOARDING_SOCIAL_AUTH_DOWNLOAD_PHOTO_COMPLETE';
export const ONBOARDING_SOCIAL_AUTH_CHECK_OAUTH_CREDENTIALS = 'ONBOARDING_SOCIAL_AUTH_CHECK_OAUTH_CREDENTIALS';
export const ONBOARDING_SOCIAL_AUTH_RECEIVE_OAUTH_ERROR = 'ONBOARDING_SOCIAL_AUTH_RECEIVE_OAUTH_ERROR';

export const ONBOARDING_SOCIAL_AUTH_UNLINK_OAUTH_CREDENTIALS = 'ONBOARDING_SOCIAL_AUTH_UNLINK_OAUTH_CREDENTIALS';

import { receiveSignInResponse } from './signin';
import { hideAuth, showAuth } from './base'
import extractQueryStringParam from '../../lib/utils/extractQueryStringParam'
import extendUrlQueryParams from '../../lib/utils/extendUrlQueryParams'

export function downloadPhoto(photoUrl) {
  return dispatch => {
    dispatch({type: ONBOARDING_SOCIAL_AUTH_DOWNLOAD_PHOTO});
    /*

    FileOperations.downloadFile(photoUrl, (fileUrl => {
      dispatch({
        type: ONBOARDING_SOCIAL_AUTH_DOWNLOAD_PHOTO_COMPLETE,
        fileUrl: fileUrl,
      });
    }))
    */
  }
}

export function fetchFacebookUserInfo(token, callback, error) {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SOCIAL_AUTH_FACEBOOK_FETCH_USER_INFO,
      token: token,
    });

    FB.api('me?fields=email,name,first_name,last_name', (infoResult) => {
      FB.api('me/picture?redirect=false&width=640&height=640', (photoResult) => {
        dispatch({
          type: ONBOARDING_SOCIAL_AUTH_FACEBOOK_FETCH_USER_INFO_COMPLETE,
          name: infoResult ? infoResult.name : null,
          email: infoResult ? infoResult.email : null,
          photo: photoResult ? (photoResult.data && !photoResult.data.is_silhouette ? photoResult.data.url : null) : null,
        });

        if (callback) callback(infoResult, photoResult);
      });
    });
  }
}

export function authenticateWithFacebook(supressSignIn = false) {
  return dispatch => {
    initializeFacebookSDK(() => {

      var _handleToken = (token) => {
        dispatch({
          type: ONBOARDING_SOCIAL_AUTH_FACEBOOK_COMPLETE,
          accessToken: token.accessToken,
          userId: token.userID,
        });

        dispatch(fetchFacebookUserInfo(token.accessToken, (infoResult, photoResult) => {
          if (!supressSignIn) {
            //In case they are already linked to a user, try sign them in right away
            dispatch(checkOauthCredentials('facebook', token.accessToken));
          }
        }));
      }

      dispatch({type: ONBOARDING_SOCIAL_AUTH_FACEBOOK});
      dispatch({type: ONBOARDING_SOCIAL_AUTH_FACEBOOK_GET_TOKEN});

      FB.login(function(loginResponse) {
        if (loginResponse.status === 'connected') {
          _handleToken(loginResponse.authResponse);
        } else {
          alert('Sorry, something went wrong while signing in via Facebook.  Please try again later.');
        }
      }, {scope: 'public_profile,email'});

    }); //sdk initialization
  }
}

export function fetchInstagramUserInfo(token, callback, error) {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FETCH_USER_INFO,
      token: token,
    });

    if (error == null) error = _logError;
    if (callback == null) callback = function() {};
    var url = `https://api.instagram.com/v1/users/self/?access_token=${token}`;
    $.ajax(url).then(json => {
      dispatch({
        type: ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FETCH_USER_INFO_COMPLETE,
        user: json.data,
      });

      callback(json.response.user);
    }, error);
  }
}

export function authenticateWithInstagram(code = null) {

  var redirectUrl = generateOauthRedirectUrl('instagram');

  return dispatch => {
    dispatch({type: ONBOARDING_SOCIAL_AUTH_INSTAGRAM});

    var _handleCode = function(code) {
      dispatch({
        type: ONBOARDING_SOCIAL_AUTH_INSTAGRAM_GET_TOKEN,
        API_CALL: {
          url: '/oauth/instagram',
          data: {
            code: code,
            redirect_uri: redirectUrl,
          },
          success: (json) => {
            dispatch({
              type: ONBOARDING_SOCIAL_AUTH_INSTAGRAM_COMPLETE,
              accessToken: json.access_token,
              user: json.user,
            });

            dispatch({
              type: ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FETCH_USER_INFO_COMPLETE,
              user: json.user,
            });

            //In case they are already linked to a user, try sign them in right away
            dispatch(checkOauthCredentials('instagram', json.access_token));
          },
          error: (err) => {
            dispatch({
              type: ONBOARDING_SOCIAL_AUTH_INSTAGRAM_FAILED,
            });
          }
        }
      })
    };

    if (!code) {
      var authURL = [
        'https://api.instagram.com/oauth/authorize',
        `?client_id=${encodeURIComponent(window.FS_ENV.INSTAGRAM_CLIENT_ID)}`,
        '&response_type=code',
        `&redirect_uri=${encodeURIComponent(redirectUrl)}`,
      ].join('');

      location.href = authURL;
    } else {
      _handleCode(code)
    }
  }
}

export function checkForOauthRedirect() {
  return dispatch => {
    var url = location.href;
    if (url.indexOf('fsauthoauthredirect=') != -1) {
      var service = extractQueryStringParam(url, "fsauthoauthredirect");
      var code = extractQueryStringParam(url, "code");
      var error = extractQueryStringParam(url, "error");
      if (service && code && !error) {
        if (service === 'foursquare') {
          dispatch(authenticateWithFoursquare(code));
        }
        if (service === 'instagram') {
          dispatch(authenticateWithInstagram(code));
        }
        dispatch(showAuth());
      }
    }
  }
}

function generateOauthRedirectUrl(service) {
  //instagram requires exact url for redirect_uri, so let's go with the homepage instead of current page
  return extendUrlQueryParams(location.origin, {fsauthoauthredirect: service, code: null});
}

export function fetchFoursquareUserInfo(token, callback, error) {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SOCIAL_AUTH_FOURSQUARE_FETCH_USER_INFO,
      token: token,
    });

    if (error == null) error = _logError;
    if (callback == null) callback = function() {};
    var url = 'https://api.foursquare.com/v2/users/self?oauth_token='+token+'&v=20150708&m=foursquare';
    $.ajax(url).then(json => {
      dispatch({
        type: ONBOARDING_SOCIAL_AUTH_FOURSQUARE_FETCH_USER_INFO_COMPLETE,
        accessToken: json.access_token,
        user: json.response.user,
      });

      callback(json.response.user);
    }, error)
  }
}

export function authenticateWithFoursquare(code = null) {
  return dispatch => {
    dispatch({type: ONBOARDING_SOCIAL_AUTH_FOURSQUARE});

    var redirectUrl = generateOauthRedirectUrl('foursquare');

    var _handleCode = function(code) {
      dispatch({
        type: ONBOARDING_SOCIAL_AUTH_FOURSQUARE_GET_TOKEN,
        API_CALL: {
          url: '/oauth/foursquare',
          data: {
            code: code,
            redirect_uri: redirectUrl,
          },
          success: (json) => {
            dispatch(fetchFoursquareUserInfo(json.access_token, profile => {
              dispatch({
                type: ONBOARDING_SOCIAL_AUTH_FOURSQUARE_COMPLETE,
                accessToken: json.access_token,
                user: profile,
              });

              //In case they are already linked to a user, try sign them in right away
              dispatch(checkOauthCredentials('foursquare', json.access_token));
            }));
          },
          error: () => {
          }
        }
      })
    };

    if (!code) {
      //Linking.addEventListener('url', handleUrl);
      var authURL = [
        'https://foursquare.com/oauth2/authenticate',
        `?client_id=${encodeURIComponent(window.FS_ENV.FOURSQUARE_CLIENT_ID)}`,
        '&response_type=code',
        `&redirect_uri=${encodeURIComponent(redirectUrl)}`,
      ].join('');

      location.href = authURL;
    } else {
      _handleCode(code)
    }
  }
}

export function checkOauthCredentials(service, token, callback = null) {
  return dispatch => {
    var requestData = {};
    requestData['identity'] = service;
    requestData[`identities_${service}`] = {
      access_token: token,
    }

    dispatch({
      type: ONBOARDING_SOCIAL_AUTH_CHECK_OAUTH_CREDENTIALS,
      service: service,
      token: token,
      API_CALL: {
        url: '/sign_in',
        method: "POST",
        data: requestData,
        dataType: 'json',
        success: (json, response) => {
          dispatch(receiveSignInResponse(json));
          if (callback) {
            callback(json);
          }
        },
        error: (responseStatus, json, response) => {
          dispatch({
            type: ONBOARDING_SOCIAL_AUTH_RECEIVE_OAUTH_ERROR,
            status: responseStatus,
          })
          if (callback) {
            callback();
          }
        }
      }
    })
  };
}

function _logError(err) {
  if (typeof(console) !== 'undefined') {
    console.error(err);
  }
}
