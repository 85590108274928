import { combineReducers } from 'redux';

import app from './app';
import userLevelTags from './userLevelTags';
import base from './base';
import tags from './tags';
import ingredients from './ingredients';
import location from './location';
import deviceLocation from './deviceLocation';

export default combineReducers({
  app: app,
  userLevelTags: userLevelTags,
  base: base,
  tags: tags,
  ingredients: ingredients,
  location: location,
  deviceLocation: deviceLocation,
});
