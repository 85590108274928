/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from 'react'
import ReactDOM from 'react-dom'

import FSC_FoodstandAuth from './components/FoodstandAuth'
import FSC_FoodstandNewPost from './components/FoodstandNewPost'
import FSC_FoodstandEditProfile from './components/FoodstandEditProfile'

window.FSC_FoodstandAuth = FSC_FoodstandAuth
window.FSC_FoodstandNewPost = FSC_FoodstandNewPost
window.FSC_FoodstandEditProfile = FSC_FoodstandEditProfile

// At certain points in the rails views we place divs as placeholders
// with specific classnames.  Then here we search for them by classname
// and inject the live react components in those spots
function injectComponent(C, name, searchClass) {
  var divs = $(searchClass)
  if (divs.length > 0) {
    divs.each(function(idx, d) {
      let params = $(d).attr('params')
      params = typeof(params) !== "undefined" ? JSON.parse(params) : {}
      ReactDOM.render(
        <C name={name} {...params} />,
        d,
      )
    })
  }
}

$(function() {
  injectComponent(FSC_FoodstandAuth, "FSC_FoodstandAuth", ".foodstand-js-auth");
  injectComponent(FSC_FoodstandNewPost, "FSC_FoodstandNewPost", ".foodstand-js-new-post");
  injectComponent(FSC_FoodstandEditProfile, "FSC_FoodstandEditProfile", ".foodstand-js-edit-profile");
})
