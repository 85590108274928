'use strict'

import React, { Component, PropTypes } from "react";
import FSText from '../../components/FSText'
import FSTouchable from '../../components/FSTouchable'
import FSIcon from '../../components/FSIcon'
import Loading from '../../components/Loading'
import colors from '../../lib/colors'
import c from '../constants'
import View from '../../components/View'

export default class FormLabelWithIcon extends React.Component {

  get hasText() {
    return this.props.value && this.props.value.length > 0
  }

  activeStyle() {
    if( ! this.props.color ) return {}
    return { backgroundColor: this.props.color }
  }

  renderIcon() {
    if( this.hasText && this.props.replaceIconWith && String(this.props.replaceIconWith).length > 0 ) {
      return (
        <FSText color={colors.white} style={styles.iconReplacement}>{this.props.replaceIconWith}</FSText>
      )
    } else {
      return (
        <FSIcon name={this.props.name} color={colors.white} style={styles.icon}></FSIcon>
      )
    }
  }

  renderLoadingIcon() {
    if( ! this.props.isLoading) return
    return (
      <Loading size={'small'} style={styles.loadingIcon}/>
    )
  }

  renderRemoveIcon() {
    if( ! this.props.onRemove || ! this.hasText ) return
    return (
      <View onClick={(e) => { this.props.onRemove(); e.preventDefault(); e.stopPropagation(); }} style={styles.removeContainer}>
        <FSIcon name="x-circle" style={styles.remove}/>
      </View>
    )
  }

  render() {
    return (
      <FSTouchable className="FormLabelWithIcon" style={Object.assign({}, styles.wrapper,this.props.style)} onPress={this.props.onPress}>
        <View style={Object.assign({}, styles.iconWrapper, this.hasText && this.activeStyle())}>
          {this.renderIcon()}
        </View>
        <FSText style={styles.label} numberOfLines={1}>
          { this.hasText ? this.props.value : this.props.placeholder }
        </FSText>
        { this.renderLoadingIcon() }
        { this.renderRemoveIcon() }
      </FSTouchable>
    )
  }
}

var styles = {
  wrapper: {
    position: 'relative',
    display: "flex", flexDirection: "row",
    paddingTop: c.fieldSpacing / 2, paddingBottom: c.fieldSpacing / 2,
    paddingRight: 35,
    marginBottom: c.gutterWidth,
    backgroundColor: colors.white,
  },
  iconReplacement: {
    color: '#ffffff',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: 16,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    float: 'left',
    lineHeight: c.iconSize + 'px',
    marginLeft: c.iconPadding,
    marginRight: c.iconPadding,
    width: c.iconSize,
    height: c.iconSize,
    backgroundColor: colors.lightMediumGray,
    borderRadius: c.iconSize * 0.5,
    overflow: 'hidden',
  },
  loadingIcon: {
    alignSelf: 'center',
    opacity: 0.5,
  },
  label: {
    alignSelf: 'center',
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray,
    paddingRight: 10,
    textAlign: 'left',
    lineHeight: c.iconSize + 'px',
  },
  remove: {
    color: colors.gray,
    fontSize: 16,
  },
  removeContainer: {
    alignSelf: 'center',
    marginRight: 10,
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 18,
    right: 4,
  },
  icon: {
    fontSize: 14,
    lineHeight: '30px',
  }
}
